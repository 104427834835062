import { Flex } from "@aws-amplify/ui-react";

import {
    KanshiSearch,
    KanshiLog,
    CommonBtnWhite,
} from '../ui-components'

import {
    KanshiSearchWrapper,
    CommonBtnWhiteWrapper,
    KanshiLogListWrapper,
} from '.'

export default function KanshiLogAreaWrapper(props) {

    return (
        <>
        <Flex
            gap="16px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            // {...getOverrideProps(overrides, "Log")}
        >
            <KanshiSearchWrapper
            ></KanshiSearchWrapper>
            <Flex
                gap="8px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                // {...getOverrideProps(overrides, "Btn")}
            >
                <CommonBtnWhiteWrapper
                    overrides={{
                        "CommonBtnWhite" : {
                            justifyContent:"center",
                            alignItems:"center",
                            grow:"1",
                        }
                    }}
                    children="ログ確認"
                ></CommonBtnWhiteWrapper>
                <CommonBtnWhiteWrapper
                    overrides={{
                        "CommonBtnWhite" : {
                            justifyContent:"center",
                            alignItems:"center",
                            grow:"1",
                        },
                    }}
                    children="CSV出力"
                ></CommonBtnWhiteWrapper>
            </Flex>
            <Flex
                gap="0"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                // {...getOverrideProps(overrides, "Frame 863")}
            >
                <KanshiLogListWrapper>
                    
                </KanshiLogListWrapper>
            </Flex>
        </Flex>
        </>
    );
}