import { View, Flex, Text, SelectField } from "@aws-amplify/ui-react";

import { 
    SetteiItem,
    // SetteiInputBox,
    Spacer,
    CommonDivider
} from '../ui-components';

import {
    SetteiInputBoxWrapper,
    SetteiItemWrapper,
    SetteiItemCycleWrapper
} from '../componentsWrapper';

import {
    keisokuSettingJson,
    keisokuCycleSettingJson,
    keisokuKanshiSettingJson,
    SETTING_INPUTBOX_UNIT_NOTHING,
    SETTING_ITEM_TYPE_INPUT,
    SETTING_ITEM_TYPE_SELECT,
} from '../lib/SettingUtil';


export default function KanshiSetteiKeisokuWrapper(props) {



    const handleChange = (event) => {
        // setDisplayValue(event.target.value);
    };
    
    const handleBlur = (event) => {
        // const input = event.target.value;
        // const byteCount = countBytes(input);
        // if (byteCount <= maxBytes) {
        //     setInputValue(input);
        // } else {
        //     // バイト数が最大を超えた場合、適切なバイト数に切り詰めます
        //     let truncated = "";
        //     let currentBytes = 0;
        //     for (let i = 0; i < input.length; i++) {
        //     const char = input.charCodeAt(i);
        //     const charBytes = char <= 0x7F ? 1 : 2;
        //     if (currentBytes + charBytes <= maxBytes) {
        //         currentBytes += charBytes;
        //         truncated += input[i];
        //     } else {
        //         break;
        //     }
        //     }
        //     setInputValue(truncated);
        //     setDisplayValue(truncated);
        // }
    };
    

    return (
        <>
        <Flex
                gap="16px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 40px 0px 40px"
                backgroundColor="rgba(255,255,255,1)"
                // {...getOverrideProps(overrides, "Frame 897")}
            >
                <Flex
                gap="40px"
                direction="column"
                width="unset"
                height='calc(100vh - 340px)'
                // height="200px"
                // height={`calc(100% - 260px)`}
                justifyContent="flex-start"
                alignItems="flex-start"
                overflow="scroll"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                // {...getOverrideProps(overrides, "Denpakansyo")}
                >
                <Flex
                    gap="16px"
                    direction="column"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    // {...getOverrideProps(overrides, "Denpa")}
                >
                    <Text
                    fontFamily="Noto Sans"
                    fontSize="16px"
                    fontWeight="700"
                    color="rgba(53,56,59,1)"
                    lineHeight="16px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    letterSpacing="0.65px"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="電波計測設定"
                    // {...getOverrideProps(overrides, "text505156929")}
                    ></Text>
                    <Flex
                    gap="0"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="center"
                    shrink="0"
                    position="relative"
                    padding="0px 1px 0px 1px"
                    // {...getOverrideProps(overrides, "Frame 882")}
                    >
                    {
                    Object.entries(keisokuSettingJson[props.location]).map((item, index) => (
                        <>
                        {item[1].type && item[1].type === SETTING_ITEM_TYPE_INPUT ?
                        <SetteiInputBoxWrapper
                            key={"keisokusetting" + index}
                            height="90px"
                            attention={item[1].range ? "true" : "false"}
                            attentiontext={item[1].range ? "範囲：" + item[1].range.min + "-" + item[1].range.max : ""}
                            label={item[1].labeltext ? "true" : "false"}
                            labelValue={item[1].labeltext}
                            unit={item[1].unit}
                            inputValue={item[1].inputvalue}
                            onChangeHandler={handleChange}
                            onBlurHandler={handleBlur}
                        />
                        :
                        <View
                            key={"keisokusetting" + index}
                            width="152px"
                            height="87px"
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            shrink="0"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            // {...getOverrideProps(overrides, "Frame529130765")}
                        > 
                            <SelectField
                                key={"keisokusettingSelect" + index}
                                width="152px"
                                height="unset"
                                label={item[1].labeltext}
                                position="absolute"
                                top="0px"
                                left="0px"
                                size="small"
                                fontSize="0.875rem" // SelectFieldのsize=smallが効かない対策
                                gap={"0.25rem"} // SelectFieldのsize=smallが効かない対策
                                options={item[1].options}
                                value={item[1].inputvalue}
                                isDisabled={false}
                                labelHidden={false}
                                variation="default"
                                onChange={handleChange}
                            ></SelectField>
                        </View>
                        }
                        {Object.entries(keisokuSettingJson[props.location]).length - 1 !== index ?
                        <Spacer
                            key={"keisokusettingSpacer"+index}
                            display="flex"
                            gap="10px"
                            direction="column"
                            width="40px"
                            height="unset"
                            justifyContent="center"
                            alignItems="center"
                            overflow="hidden"
                            shrink="0"
                            alignSelf="stretch"
                            position="relative"
                            padding="10px 10px 10px 10px"
                        ></Spacer>
                        : null
                        }
                        </>
                    ))}
                    </Flex>
                    <Flex
                        gap="0"
                        direction="column"
                        width="unset"
                        height="unset"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        shrink="0"
                        alignSelf="stretch"
                        position="relative"
                        padding="0px 0px 0px 0px"
                    >
                        {Object.entries(keisokuCycleSettingJson[props.location]).map((item, index) => (
                            <>
                            <SetteiItemCycleWrapper
                                key={"keisokucyclesetting" + index}
                                labeltext={item[1].labeltext}
                                cyclevalue1={item[1]["1st"] !== null ? "アンテナ" + item[1]["1st"] :"なし"}
                                cyclevalue2={item[1]["2nd"] !== null ? "アンテナ" + item[1]["2nd"] :"なし"}
                                cyclevalue3={item[1]["3rd"] !== null ? "アンテナ" + item[1]["3rd"] :"なし"}
                            >
                            </SetteiItemCycleWrapper>
                            </>
                        ))}
                    </Flex>
                </Flex>
                <Flex
                    gap="16px"
                    direction="column"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    // {...getOverrideProps(overrides, "Kansyoha")}
                >
                    <Text
                    fontFamily="Noto Sans"
                    fontSize="16px"
                    fontWeight="700"
                    color="rgba(53,56,59,1)"
                    lineHeight="16px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    letterSpacing="0.65px"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="干渉波アラーム監視設定"
                    // {...getOverrideProps(overrides, "text505156939")}
                    ></Text>
                    <Flex
                    gap="0"
                    direction="column"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    // {...getOverrideProps(overrides, "Frame 881")}
                    >
                    <Flex
                        gap="48px"
                        direction="row"
                        width="unset"
                        height="unset"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        shrink="0"
                        alignSelf="stretch"
                        position="relative"
                        padding="16px 32px 16px 32px"
                        backgroundColor="rgba(245,245,245,1)"
                        // {...getOverrideProps(overrides, "Title")}
                    >
                        <Text
                        fontFamily="Noto Sans"
                        fontSize="16px"
                        fontWeight="400"
                        color="rgba(53,56,59,1)"
                        lineHeight="16px"
                        textAlign="left"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        letterSpacing="0.65px"
                        width="96px"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children="対象周波数"
                        // {...getOverrideProps(overrides, "text505156942")}
                        ></Text>
                        <Text
                        fontFamily="Noto Sans"
                        fontSize="16px"
                        fontWeight="400"
                        color="rgba(53,56,59,1)"
                        lineHeight="16px"
                        textAlign="left"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        letterSpacing="0.65px"
                        width="160px"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children="監視項目"
                        // {...getOverrideProps(overrides, "text505156943")}
                        ></Text>
                        <Text
                        fontFamily="Noto Sans"
                        fontSize="16px"
                        fontWeight="400"
                        color="rgba(53,56,59,1)"
                        lineHeight="16px"
                        textAlign="left"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        letterSpacing="0.65px"
                        width="120px"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children="有効／無効"
                        // {...getOverrideProps(overrides, "text505156944")}
                        ></Text>
                        <Text
                        fontFamily="Noto Sans"
                        fontSize="16px"
                        fontWeight="400"
                        color="rgba(53,56,59,1)"
                        lineHeight="16px"
                        textAlign="left"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        letterSpacing="0.65px"
                        width="120px"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children="状態種別"
                        // {...getOverrideProps(overrides, "text505156945")}
                        ></Text>
                        <Text
                        fontFamily="Noto Sans"
                        fontSize="16px"
                        fontWeight="400"
                        color="rgba(53,56,59,1)"
                        lineHeight="16px"
                        textAlign="left"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        letterSpacing="0.65px"
                        width="120px"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children="閾値"
                        // {...getOverrideProps(overrides, "text505156946")}
                        ></Text>
                    </Flex>
                    <Flex
                        gap="0"
                        direction="column"
                        width="unset"
                        // height="500px"
                        height="unset"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        overflow="hidden"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        // {...getOverrideProps(overrides, "Frame")}
                    >
                        {Object.entries(keisokuKanshiSettingJson[props.location]).map((item, index) => (
                        <>
                        <Flex
                        key={"FrameA" + index}
                        gap="0"
                        direction="row"
                        width="unset"
                        height="unset"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        // {...getOverrideProps(overrides, "3400-3440")}
                        >
                        
                        <Flex
                            key={"FrameB" + index}
                            gap="0"
                            direction="row"
                            width="unset"
                            height="unset"
                            justifyContent="center"
                            alignItems="flex-start"
                            shrink="0"
                            alignSelf="stretch"
                            position="relative"
                            padding="34px 32px 34px 32px"
                            backgroundColor="rgba(255,255,255,1)"
                            // {...getOverrideProps(overrides, "Taisyo505156949")}
                        >
                            <Text
                                key={"label" + index}
                                fontFamily="Noto Sans"
                                fontSize="14px"
                                fontWeight="700"
                                color="rgba(53,56,59,1)"
                                lineHeight="22.399999618530273px"
                                textAlign="left"
                                display="block"
                                direction="column"
                                justifyContent="unset"
                                letterSpacing="0.55px"
                                width="88px"
                                height="unset"
                                gap="unset"
                                alignItems="unset"
                                shrink="0"
                                position="relative"
                                padding="0px 0px 0px 0px"
                                whiteSpace="nowrap"
                                children={item[0]}
                                // {...getOverrideProps(overrides, "text505156950")}
                            ></Text>
                        </Flex>
                        <Flex
                            key={"FrameC" + index}
                            gap="0"
                            direction="column"
                            width="unset"
                            height="unset"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            shrink="0"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            // {...getOverrideProps(overrides, "Frame 880505156951")}
                        >
                        {Object.entries(item[1]).map((itemDetail, index) => (
                            <>
                            <SetteiItemWrapper
                            key={item[0] + "setting" + index}
                            setteiname={itemDetail[1].labeltext}
                            isvalidoptions={itemDetail[1].isvalidoptions}
                            isvalidvalue={itemDetail[1].isvalidvalue}
                            statustypeoptions={itemDetail[1].statustypeoptions}
                            statustypevalue={itemDetail[1].statustypevalue}
                            display1={itemDetail[1].option1 ? "flex" : "none"}
                            texttop1={"21px"}
                            label1={itemDetail[1].option1 && itemDetail[1].option1.labeltext ? "true" : "false"}
                            labelValue1={itemDetail[1].option1 && itemDetail[1].option1.labeltext ? itemDetail[1].option1.labeltext : ""}
                            attention1={itemDetail[1].option1 && itemDetail[1].option1.range ? "範囲：" + item[1].option1.range.min + "-" + item[1].option1.range.max : ""}
                            attentiontext1={itemDetail[1].option1 && itemDetail[1].option1.range ? "範囲：" + item[1].option1.range.min + "-" + item[1].option1.range.max : ""}
                            unit1={itemDetail[1].option1 && itemDetail[1].option1.unit ? itemDetail[1].option1.unit : SETTING_INPUTBOX_UNIT_NOTHING}
                            inputValue1={itemDetail[1].option1 && itemDetail[1].option1.inputvalue ? itemDetail[1].option1.inputvalue : ""}
                            display2={itemDetail[1].option2 ? "flex" : "none"}
                            texttop2={"21px"}
                            label2={itemDetail[1].option2 && itemDetail[1].option2.labeltext ? "true" : "false"}
                            labelValue2={itemDetail[1].option2 && itemDetail[1].option2.labeltext ? itemDetail[1].option2.labeltext : ""}
                            attention2={itemDetail[1].option2 && itemDetail[1].option2.range ? "範囲：" + item[1].option2.range.min + "-" + item[1].option2.range.max : ""}
                            attentiontext2={itemDetail[1].option2 && itemDetail[1].option2.range ? "範囲：" + item[1].option2.range.min + "-" + item[1].option2.range.max : ""}
                            unit2={itemDetail[1].option2 && itemDetail[1].option2.unit ? itemDetail[1].option2.unit : SETTING_INPUTBOX_UNIT_NOTHING}
                            inputValue2={itemDetail[1].option2 && itemDetail[1].option2.inputvalue ? itemDetail[1].option2.inputvalue : ""}
                            display3={itemDetail[1].option3 ? "flex" : "none"}
                            texttop3={"21px"}
                            label3={itemDetail[1].option3 && itemDetail[1].option3.labeltext ? "true" : "false"}
                            labelValue3={itemDetail[1].option3 && itemDetail[1].option3.labeltext ? itemDetail[1].option3.labeltext : ""}
                            attention3={itemDetail[1].option3 && itemDetail[1].option3.range ? "範囲：" + item[1].option3.range.min + "-" + item[1].option3.range.max : ""}
                            attentiontext3={itemDetail[1].option3 && itemDetail[1].option3.range ? "範囲：" + item[1].option3.range.min + "-" + item[1].option3.range.max : ""}
                            unit3={itemDetail[1].option3 && itemDetail[1].option3.unit ? itemDetail[1].option3.unit : SETTING_INPUTBOX_UNIT_NOTHING}
                            inputValue3={itemDetail[1].option3 && itemDetail[1].option3.inputvalue ? itemDetail[1].option3.inputvalue : ""}
                            display4={itemDetail[1].option4 ? "flex" : "none"}
                            texttop4={"21px"}
                            label4={itemDetail[1].option4 && itemDetail[1].option4.labeltext ? "true" : "false"}
                            labelValue4={itemDetail[1].option4 && itemDetail[1].option4.labeltext ? itemDetail[1].option4.labeltext : ""}
                            attention4={itemDetail[1].option4 && itemDetail[1].option4.range ? "範囲：" + item[1].option4.range.min + "-" + item[1].option4.range.max : ""}
                            attentiontext4={itemDetail[1].option4 && itemDetail[1].option4.range ? "範囲：" + item[1].option4.range.min + "-" + item[1].option4.range.max : ""}
                            unit4={itemDetail[1].option4 && itemDetail[1].option4.unit ? itemDetail[1].option4.unit : SETTING_INPUTBOX_UNIT_NOTHING}
                            inputValue4={itemDetail[1].option4 && itemDetail[1].option4.inputvalue ? itemDetail[1].option4.inputvalue : ""}
                            dispdivider={Object.entries(item[1]).length - 1 !== index ? true : false}
                            // {...getOverrideProps(overrides, "Settei/Item505156952")}
                            ></SetteiItemWrapper>
                            </>
                        ))}
                        </Flex>
                        
                        </Flex>
                        <CommonDivider
                        key={"divider" + index}
                        display="flex"
                        gap="0"
                        direction="row"
                        width="unset"
                        height="1px"
                        justifyContent="center"
                        alignItems="center"
                        overflow="hidden"
                        shrink="0"
                        alignSelf="stretch"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        // {...getOverrideProps(overrides, "Common/Divider505156955")}
                        ></CommonDivider>
                        </>
                        ))}
                    </Flex>
                    </Flex>
                </Flex>
                </Flex>
            </Flex>
        </>
    );
}