/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import KanshiLocationTab from "./KanshiLocationTab";
import { Flex } from "@aws-amplify/ui-react";
export default function KanshiLocation(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        "Kanshi/Location/Tab36163935": {},
        "Kanshi/Location/Tab36163938": {},
        "Kanshi/Location/Tab36163942": {},
        "Kanshi/Location/Tab36163944": {},
        "Kanshi/Location/Tab36163946": {},
        KanshiLocation: {},
      },
      variantValues: { situation: "\u5168\u62E0\u70B9" },
    },
    {
      overrides: {
        "Kanshi/Location/Tab36163935": { situation: "Default" },
        "Kanshi/Location/Tab36163938": { situation: "Select" },
        "Kanshi/Location/Tab36163942": {},
        "Kanshi/Location/Tab36163944": {},
        "Kanshi/Location/Tab36163946": {},
        KanshiLocation: {},
      },
      variantValues: { situation: "YSCC" },
    },
    {
      overrides: {
        "Kanshi/Location/Tab36163935": { situation: "Default" },
        "Kanshi/Location/Tab36163938": {},
        "Kanshi/Location/Tab36163942": { situation: "Select" },
        "Kanshi/Location/Tab36163944": {},
        "Kanshi/Location/Tab36163946": {},
        KanshiLocation: {},
      },
      variantValues: { situation: "SPE" },
    },
    {
      overrides: {
        "Kanshi/Location/Tab36163935": { situation: "Default" },
        "Kanshi/Location/Tab36163938": {},
        "Kanshi/Location/Tab36163942": {},
        "Kanshi/Location/Tab36163944": { situation: "Select" },
        "Kanshi/Location/Tab36163946": {},
        KanshiLocation: {},
      },
      variantValues: { situation: "SPW" },
    },
    {
      overrides: {
        "Kanshi/Location/Tab36163935": { situation: "Default" },
        "Kanshi/Location/Tab36163938": {},
        "Kanshi/Location/Tab36163942": {},
        "Kanshi/Location/Tab36163944": {},
        "Kanshi/Location/Tab36163946": { situation: "Select" },
        KanshiLocation: {},
      },
      variantValues: { situation: "SAYADO" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="8px"
      direction="row"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      {...rest}
      {...getOverrideProps(overrides, "KanshiLocation")}
    >
      <KanshiLocationTab
        display="flex"
        gap="0"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        type="Error"
        situation="Select"
        {...getOverrideProps(overrides, "Kanshi/Location/Tab36163935")}
      ></KanshiLocationTab>
      <KanshiLocationTab
        display="flex"
        gap="0"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        type="Error"
        situation="Default"
        {...getOverrideProps(overrides, "Kanshi/Location/Tab36163938")}
      ></KanshiLocationTab>
      <KanshiLocationTab
        display="flex"
        gap="0"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        type="Usual"
        situation="Default"
        {...getOverrideProps(overrides, "Kanshi/Location/Tab36163942")}
      ></KanshiLocationTab>
      <KanshiLocationTab
        display="flex"
        gap="0"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        type="Usual"
        situation="Default"
        {...getOverrideProps(overrides, "Kanshi/Location/Tab36163944")}
      ></KanshiLocationTab>
      <KanshiLocationTab
        display="flex"
        gap="0"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        type="Warning"
        situation="Default"
        {...getOverrideProps(overrides, "Kanshi/Location/Tab36163946")}
      ></KanshiLocationTab>
    </Flex>
  );
}
