
import { useEffect } from 'react';
import { SetteiItem } from '../ui-components';

import {
    SETTING_INPUTBOX_UNIT_DBM,
    SETTING_INPUTBOX_UNIT_DBM_MHZ
} from '../lib/SettingUtil'

export default function SetteiItemWrapper(props) {

    // useEffect(() => {
    //     console.log("SetteiItemWrapper useEffect", props);
    // }, []);

    const onChangeHandler = (e) => {
        // console.log("SetteiItemWrapper onChangeHandler", e);
        props.onChange(e);
    }

    return (
        <SetteiItem
            overrides={{
                text: {
                    children: props.setteiname,
                },
                "SelectField502826176": {
                    placeholder: "",
                    options: props.isvalidoptions,
                    value: props.isvalidvalue,
                    onChange: () => onChangeHandler(),
                },
                "SelectField502826178": {
                    placeholder: "",
                    options: props.statustypeoptions,
                    value: props.statustypevalue,
                    onChange: () => onChangeHandler(),
                    isDisabled: props.isvalidvalue==="OFF"
                },
                "Settei/InputBox502826180": {
                    // display: props.display1,
                    style: {
                        visibility : props.display1 !== "none" ? "visible" : "hidden",
                    },
                    attention: props.attention1,
                    label: props.label1,
                    unit: props.unit1 === SETTING_INPUTBOX_UNIT_DBM ? SETTING_INPUTBOX_UNIT_DBM_MHZ : props.unit1, // SETTING_INPUTBOX_UNIT_DBM(dBm)に対応していないので、SETTING_INPUTBOX_UNIT_DBM_MHZにして個別対応
                    // value: props.value1,
                    overrides: {
                        TextField: {
                            top: props.texttop1,
                            placeholder: "",
                            label: props.labelValue1,
                            // defaultValue: props.inputValue1,
                            value: props.inputValue1,
                            onChange: () => onChangeHandler(),
                            isDisabled: props.isvalidvalue==="OFF"
                        },
                        Attention: {
                            children: props.attentiontext1,
                        },
                        "dBm/MHz": {
                            children: props.unit1　// SETTING_INPUTBOX_UNIT_DBM(dBm) に対応していないため表示文字をもらった単位に切り替え
                        }
                    },
                },
                "Spacer502826181": {
                    display: props.display1,
                },
                "Settei/InputBox502826182": {
                    // style: {
                    //     visibility : props.display2 !== "none" ? "visible" : "hidden",
                    // },
                    display: props.display2,
                    attention: props.attention2,
                    label: props.label2,
                    unit: props.unit2 === SETTING_INPUTBOX_UNIT_DBM ? SETTING_INPUTBOX_UNIT_DBM_MHZ : props.unit2, // SETTING_INPUTBOX_UNIT_DBM(dBm)に対応していないので、SETTING_INPUTBOX_UNIT_DBM_MHZにして個別対応
                    // value: props.value2,
                    overrides: {
                        TextField: {
                            top: props.texttop2,
                            placeholder: "",
                            label: props.labelValue2,
                            // defaultValue: props.inputValue2,
                            value: props.inputValue2,
                            onChange: () => onChangeHandler(),
                        },
                        Attention: {
                            children: props.attentiontext2,
                        },
                    },
                },
                "Spacer502826183": {
                    display: props.display2,
                },
                "Settei/InputBox502826184": {
                    // style: {
                    //     visibility : props.display3 !== "none" ? "visible" : "hidden",
                    // },
                    display: props.display3,
                    attention: props.attention3,
                    label: props.label3,
                    unit: props.unit3 === SETTING_INPUTBOX_UNIT_DBM ? SETTING_INPUTBOX_UNIT_DBM_MHZ : props.unit3, // SETTING_INPUTBOX_UNIT_DBM(dBm)に対応していないので、SETTING_INPUTBOX_UNIT_DBM_MHZにして個別対応
                    // value: props.value3,
                    overrides: {
                        TextField: {
                            placeholder: "",
                            label: props.labelValue3,
                            // defaultValue: props.inputValue3,
                            value: props.inputValue3,
                            onChange: () => onChangeHandler(),
                        },
                        Attention: {
                            children: props.attentiontext3,
                        },
                    },
                },
                "Spacer502826185": {
                    display: props.display3,
                },
                "Settei/InputBox502826186": {
                    // style: {
                    //     visibility : props.display4 !== "none" ? "visible" : "hidden",
                    // },
                    display: props.display4,
                    attention: props.attention4,
                    label: props.label4,
                    unit: props.unit4 === SETTING_INPUTBOX_UNIT_DBM ? SETTING_INPUTBOX_UNIT_DBM_MHZ : props.unit4, // SETTING_INPUTBOX_UNIT_DBM(dBm)に対応していないので、SETTING_INPUTBOX_UNIT_DBM_MHZにして個別対応
                    // value: props.value4,
                    overrides: {
                        TextField: {
                            placeholder: "",
                            label: props.labelValue4,
                            // defaultValue: props.inputValue4,
                            value: props.inputValue4,
                            onChange: () => onChangeHandler(),
                        },
                        Attention: {
                            children: props.attentiontext4,
                        },
                    }
                },
                "Common/Divider" : {
                    display: props.dispdivider ? "flex" : "none",
                }
                
            }}
        >
        </SetteiItem>
    );
}