import {
  DatakanriSearch
} from '../ui-components'

export default function DatakanriSearchWrapper(props) {
  return (
    <DatakanriSearch  width={"40%"} children={""}
      // onClick={props.onClick}
      // onClick={()=>clickTest("test")}
      overrides={{
          SearchField:{
              hasSearchButton:false,
              hasSearchIcon:true
          }
      }}
    ></DatakanriSearch>
  );
}