/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { View } from "@aws-amplify/ui-react";
export default function KanshiAntennaindicator(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: { "Rectangle 88": {}, KanshiAntennaindicator: {} },
      variantValues: { type: "Default" },
    },
    {
      overrides: {
        "Rectangle 88": { backgroundColor: "rgba(255,76,79,1)" },
        KanshiAntennaindicator: {},
      },
      variantValues: { type: "Error" },
    },
    {
      overrides: {
        "Rectangle 88": { backgroundColor: "rgba(250,180,50,1)" },
        KanshiAntennaindicator: {},
      },
      variantValues: { type: "Warning" },
    },
    {
      overrides: {
        "Rectangle 88": { backgroundColor: "rgba(170,170,170,1)" },
        KanshiAntennaindicator: {},
      },
      variantValues: { type: "None" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <View
      width="80px"
      height="16px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "KanshiAntennaindicator")}
    >
      <View
        width="80px"
        height="16px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="0%"
        bottom="0%"
        left="0%"
        right="0%"
        borderRadius="2px"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(38,193,147,1)"
        {...getOverrideProps(overrides, "Rectangle 88")}
      ></View>
    </View>
  );
}
