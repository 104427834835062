/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import KanshiBtnSearch from "./KanshiBtnSearch";
import { Flex, Radio, SelectField, Text } from "@aws-amplify/ui-react";
import CommonBtnBlue from "./CommonBtnBlue";
export default function KanshiSearch(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        "Kanshi/Btn/Search": { situation: "\u7D5E\u8FBC\u307F" },
        SelectField513421988: {
          width: "unset",
          shrink: "1",
          grow: "1",
          basis: "0",
        },
        text: { height: "24px" },
        SelectField513421990: {
          width: "unset",
          shrink: "1",
          grow: "1",
          basis: "0",
        },
        Input: { alignSelf: "stretch" },
        SelectField513421992: {},
        SelectField513421993: {},
        SelectField513421994: {},
        Select: {},
        label513421997: {},
        Radio513421999: {},
        Radio513422000: {},
        "Frame 762": {},
        "Frame 764": {},
        label513422002: {},
        Radio513422004: {},
        Radio513422005: {},
        "Frame 763": {},
        "Frame 765": {},
        Radio513421995: { gap: "40px" },
        Detail: { display: "flex" },
        "Common/Btn/Blue": { display: "flex" },
        KanshiSearch: {},
      },
      variantValues: { type: "Select" },
    },
    {
      overrides: {
        "Kanshi/Btn/Search": {},
        SelectField513421988: {},
        text: {},
        SelectField513421990: {},
        Input: {},
        SelectField513421992: {},
        SelectField513421993: {},
        SelectField513421994: {},
        Select: {},
        label513421997: {},
        Radio513421999: {},
        Radio513422000: {},
        "Frame 762": {},
        "Frame 764": {},
        label513422002: {},
        Radio513422004: {},
        Radio513422005: {},
        "Frame 763": {},
        "Frame 765": {},
        Radio513421995: {},
        Detail: {},
        "Common/Btn/Blue": {},
        KanshiSearch: {},
      },
      variantValues: { type: "Default" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="40px"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      border="1px SOLID rgba(210,210,210,1)"
      borderRadius="8px"
      padding="31px 23px 31px 23px"
      backgroundColor="rgba(255,255,255,1)"
      display="flex"
      {...rest}
      {...getOverrideProps(overrides, "KanshiSearch")}
    >
      <KanshiBtnSearch
        display="flex"
        gap="16px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        situation="すべて"
        {...getOverrideProps(overrides, "Kanshi/Btn/Search")}
      ></KanshiBtnSearch>
      <Flex
        gap="32px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="none"
        {...getOverrideProps(overrides, "Detail")}
      >
        <Flex
          gap="8px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-end"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Input")}
        >
          <SelectField
            width="240px"
            height="unset"
            label="期間"
            placeholder="2022/10/04 09:30:00"
            shrink="0"
            size="small"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            {...getOverrideProps(overrides, "SelectField513421988")}
          ></SelectField>
          <Text
            fontFamily="Noto Sans"
            fontSize="14px"
            fontWeight="500"
            color="rgba(100,100,100,1)"
            lineHeight="14px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.55px"
            width="14px"
            height="33px"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="〜"
            {...getOverrideProps(overrides, "text")}
          ></Text>
          <SelectField
            width="240px"
            height="unset"
            label="拠点"
            placeholder="2022/11/04 09:30:00"
            shrink="0"
            size="small"
            isDisabled={false}
            labelHidden={true}
            variation="default"
            {...getOverrideProps(overrides, "SelectField513421990")}
          ></SelectField>
        </Flex>
        <Flex
          gap="32px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Select")}
        >
          <SelectField
            width="184px"
            height="unset"
            label="拠点"
            placeholder="小夜戸"
            shrink="0"
            size="small"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            {...getOverrideProps(overrides, "SelectField513421992")}
          ></SelectField>
          <SelectField
            width="184px"
            height="unset"
            label="ログ種別"
            placeholder="電力密度平均値"
            shrink="0"
            size="small"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            {...getOverrideProps(overrides, "SelectField513421993")}
          ></SelectField>
          <SelectField
            width="184px"
            height="unset"
            label="機器名"
            placeholder="キャビネット電源1"
            shrink="0"
            size="small"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            {...getOverrideProps(overrides, "SelectField513421994")}
          ></SelectField>
        </Flex>
        <Flex
          gap="24px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Radio513421995")}
        >
          <Flex
            gap="8px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 764")}
          >
            <Text
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              color="rgba(48,64,80,1)"
              lineHeight="21px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="200px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="復帰状態"
              {...getOverrideProps(overrides, "label513421997")}
            ></Text>
            <Flex
              gap="24px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 762")}
            >
              <Radio
                width="unset"
                height="unset"
                shrink="0"
                size="small"
                defaultChecked={true}
                isDisabled={false}
                labelPosition="end"
                children="復帰済"
                {...getOverrideProps(overrides, "Radio513421999")}
              ></Radio>
              <Radio
                width="unset"
                height="unset"
                shrink="0"
                size="small"
                defaultChecked={false}
                isDisabled={false}
                labelPosition="end"
                children="未復帰"
                {...getOverrideProps(overrides, "Radio513422000")}
              ></Radio>
            </Flex>
          </Flex>
          <Flex
            gap="8px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 765")}
          >
            <Text
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              color="rgba(48,64,80,1)"
              lineHeight="21px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="200px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="確認状態"
              {...getOverrideProps(overrides, "label513422002")}
            ></Text>
            <Flex
              gap="24px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 763")}
            >
              <Radio
                width="unset"
                height="unset"
                shrink="0"
                size="small"
                defaultChecked={true}
                isDisabled={false}
                labelPosition="end"
                children="確認済"
                {...getOverrideProps(overrides, "Radio513422004")}
              ></Radio>
              <Radio
                width="unset"
                height="unset"
                shrink="0"
                size="small"
                defaultChecked={false}
                isDisabled={false}
                labelPosition="end"
                children="未確認"
                {...getOverrideProps(overrides, "Radio513422005")}
              ></Radio>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <CommonBtnBlue
        display="none"
        gap="10px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="flex-end"
        shrink="0"
        position="relative"
        borderRadius="8px"
        padding="10px 24px 10px 24px"
        backgroundColor="rgba(0,153,255,1)"
        situation="default"
        {...getOverrideProps(overrides, "Common/Btn/Blue")}
      ></CommonBtnBlue>
    </Flex>
  );
}
