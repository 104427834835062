// import Amplify, { Storage } from 'aws-amplify';
import { Storage, Auth } from 'aws-amplify';
import { fromBase64 } from '@aws-sdk/util-base64-browser'

import {
  KmsKeyringBrowser,
  KMS,
  getClient,
  buildClient,
  CommitmentPolicy,
} from '@aws-crypto/client-browser'


// function processStorageList(response) {
//   const filesystem = {};

//   const add = (source, target, item) => {
//     const elements = source.split('/');
//     const element = elements.shift();
//     if (!element) return; // blank
//     target[element] = target[element] || { __data: item }; // element;
//     if (elements.length) {
//       target[element] =
//         typeof target[element] === 'object' ? target[element] : {};
//       add(elements.join('/'), target[element], item);
//     }
//   };
//   response.forEach((item) => add(item.key, filesystem, item));
//   return filesystem;
// }

// async function getObjectsRecursively(path) {
//   // await Storage.list(path, { maxKeys: MAX_KEYS, nextToken: nextToken })
//   const result = await Storage.list(path)
//   console.log('result')
//   console.log(result)
//   const filesystem = processStorageList(result)
//   return filesystem

  // let response = Storage.list(path, { maxKeys: MAX_KEYS, nextToken: nextToken })
  // console.log("hasNextToken")
  // console.log(response.hasNextToken)
  // console.log("results")
  // console.log(response.results)
  // const { contents, nextContinuationToken } = result;
  
  // Add current batch of objects to the array
  // objects.push(...contents);

  // // If there are more objects, recursively call this function with the new nextToken
  // if (nextContinuationToken) {
  //   await getObjectsRecursively(path, nextContinuationToken, objects);
  // }
// }

// export function getAllObjects(path) {
//   const result = getObjectsRecursively(path);
//   return result;
// }

// async function kmsEncrypt(inputData) {

//   const { encrypt } = buildClient(
//     CommitmentPolicy.REQUIRE_ENCRYPT_REQUIRE_DECRYPT
//   )

//   const credentials = await Auth.currentCredentials()

//   const clientProvider = getClient(KMS, {
//     credentials: Auth.essentialCredentials(credentials)
//   })

//   const keyIds = ['arn:aws:kms:ap-northeast-1:670634105186:key/e58bb169-0c4d-4049-bbe9-18f35834414d']
//   const generatorKeyId = keyIds[0]
  
//   const keyring = new KmsKeyringBrowser({ 
//     clientProvider,
//     generatorKeyId,
//     keyIds 
//   })

//   const context = {
//     stage: 'demo',
//     purpose: 'simple demonstration app',
//     origin: 'ap-northeast-1',
//   }

//   try {
//     console.log("encryptBefore")
//     console.log(inputData)
//     const { result } = await encrypt(keyring, inputData, {
//       encryptionContext: context,
//     })
//     console.log("encryptAfter")
//     console.log(result)
//     return result
//   } catch (error) {
//     console.log("kmsEncrypterror")
//     console.log(error)
//     return ""
//   }
// }

function convertDisplayDateText(lastModified) {
  var date = lastModified;         // 現在日時を生成
  var year = date.getFullYear(); // 西暦を取得
  var month = ('0' + (date.getMonth() + 1)).slice(-2);  // 月を取得（返り値は実際の月-1なので、+1する）
  var day = ('0' + date.getDate()).slice(-2); // 日を取得
  var hour = ('0' + date.getHours()).slice(-2);
  var minite = ('0' + date.getMinutes()).slice(-2);
  // var second = ('0' + date.getSeconds()).slice(-2);
  // var weekday = date.getDay();   // 曜日を取得（数値）

  var displayDateText = year + "/" + month + "/" + day + " " + hour + ":" + minite;
  
  return displayDateText;
}

function convertDisplaySizeText(size) {
  const kb = 1024
  const mb = Math.pow(kb, 2)
  const gb = Math.pow(kb, 3)
  const tb = Math.pow(kb, 4)

  let target = null
  let unit = 'byte'

  if (size >= tb) {
    target = tb
    unit = 'TB'
  } else if (size >= gb) {
    target = gb
    unit = 'GB'
  } else if (size >= mb) {
    target = mb
    unit = 'MB'
  } else if (size >= kb) {
    target = kb
    unit = 'KB'
  }

  const displaySizeText = target !== null ? Math.floor(size / target) : size

  return displaySizeText + unit
}


export async function getFileList(key) {
  // console.log("getFileList", key)
  // let fileList = []
  try {
    // await Auth.currentCredentials()
    // await Storage.list(key)
    // // .then((result) => processStorageList(result))
    // .then((result) => {
    //   const fileList = getList(key, result)
    //   console.log("getFileList OK" ,fileList)
    //   return fileList
    // })
    // .catch((err) => {
    //   console.log(err)
    //   console.log("getFileList err" ,fileList)
    //   throw err
    // });
    if (key === "") { 
      var fileList = [
        {
          isFile: false,
          name: "公開データ",
          lastModified: "",
          fileType: "",
          filesize: "",
          searchKey: "公開データ/",
          key: "公開データ/"
        },
        {
          isFile: false,
          name: "内部データ",
          lastModified: "",
          fileType: "",
          filesize: "",
          searchKey: "内部データ/",
          key: "内部データ/"
        }
      ];

      return fileList;
    } else {
      // const options = { delimiter: '/' };
      // const result = await Storage.list(key, options);
      const result = await Storage.list(key);
      // console.log("getFileList OK" ,result)
      // console.log("getFileList OK count:" ,result.length)
      const fileList = getList(key, result);
      // console.log("getFileList OK" ,fileList);
      return fileList;
    }
  } catch (error) {
    // console.log("getFileList catch Error" ,error)
    throw error
  }
}

function convertS3Data(isFile, name, searchKey, result) {
  var data = {
    isFile: isFile,
    name: name,
    lastModified: "",
    fileType: "",
    filesize: "",
    searchKey: searchKey,
    key: result.key
  }

  if (data.isFile) {
    data.lastModified = convertDisplayDateText(result.lastModified)
    var dataNameArray = name.split(".");
    data.fileType = dataNameArray[dataNameArray.length - 1]
    data.filesize = convertDisplaySizeText(result.size)
  }
  // console.log(data)
  // console.log("↑return data")
  return data;
} 

function getList(key, result) {
  // console.log("key:" + key)
  // console.log(result);
  var dataList = [];
  var newarray = [];
  // var newoc_array = [];
  var keyPathArray = key.split("/");
  for (var i = 0; i < result.length; i++) {
    var resultPassArr = result[i]['key'].split("/");
    // console.log("arr.length:" + arr.length)   
    // console.log(arr)   
    // console.log("reference.length:" + reference.length)
    // console.log(reference)
    // console.log(result[i])
    // console.log("↑result data" + i)
    if (resultPassArr.length > keyPathArray.length ) {
      if (!newarray.includes(resultPassArr[keyPathArray.length - 1])) {
        // console.log("isFolder")
        newarray.push(resultPassArr[keyPathArray.length-1]);
        resultPassArr.length = keyPathArray.length;
        dataList.push(convertS3Data(false, resultPassArr[keyPathArray.length-1], resultPassArr.join("/") + "/", result[i]));
      }
    } else if (resultPassArr.length === keyPathArray.length && result[i].key !== "" && resultPassArr[resultPassArr.length - 1] !== "") {
      // console.log("resultPassArr")
      // console.log(resultPassArr)
      // console.log("keyPathArray")
      // console.log(keyPathArray)
      // console.log(result[i].key)
      // console.log("isFile")
      dataList.push(convertS3Data(true, resultPassArr[resultPassArr.length - 1], "", result[i]));
      // new_doc_array.push(result[i])
    }

    // console.log(dataList);
    // console.log("↑result dataList" + i)
  }

  // console.log(dataList);
  // console.log("↑result data finale")
  return dataList;
  // console.log("folders")
  // console.log(newarray);
  // console.log("files")
  // console.log(new_doc_array);
}

async function kmsDecrypt(encryptText, fileType){
  const credentials = await Auth.currentCredentials()

  const { decrypt } = buildClient(
    CommitmentPolicy.REQUIRE_ENCRYPT_REQUIRE_DECRYPT
  )
  
  const clientProvider = getClient(KMS, {
        credentials: Auth.essentialCredentials(credentials)
      })
  

  const keyIds = ['arn:aws:kms:ap-northeast-1:670634105186:key/e58bb169-0c4d-4049-bbe9-18f35834414d']
  
  const keyring = new KmsKeyringBrowser({ 
    clientProvider : clientProvider,
    keyIds : keyIds
  })
  
  try {
    // console.log("decryptBefore")
    // console.log(encryptText)
    const { plaintext } = await decrypt(keyring, encryptText)
    // console.log('decryptAfter')
    // console.log(plaintext)
    
    if (fileType === 'csv') {
      const decoder = new TextDecoder();
      const text = decoder.decode(plaintext)
      const blob = new Blob([text], { type: 'text/csv' })
      return blob
    } else if(fileType === 'png') {
      // pngファイルはBase64エンコードされているのでデコードする
      const decoder = new TextDecoder()
      const base64String = decoder.decode(plaintext)
      const utf8Array = fromBase64(base64String)
      // console.log('base64decode')
      // console.log(utf8Array)
      
      const blob = new Blob([utf8Array.buffer], { type: 'image/png' })
      // console.log(blob)
      return blob
    }
  } catch (error) {
    // console.log("decrypterror")
    // console.log(error)
    return null
  }
}


function downloadBlob(blob, filename) {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename || 'download';
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener('click', clickHandler);
    }, 150);
  };
  a.addEventListener('click', clickHandler, false);
  a.click();
  return a;
}

export const downloadFile = async (key,fileName,fileType) => {
  // console.log("key: "+ key)
  
  const result = await Storage.get(key, { level: "public", download: true });
  // console.log(result)
  const arrayBuffer = await result.Body.arrayBuffer()
  const uint8Array = new Uint8Array(arrayBuffer);

  // console.log('originaldata')
  // console.log(uint8Array)
  
  const decryptBlob = kmsDecrypt(uint8Array, fileType)

  decryptBlob.then(blobData => {
    if(blobData) {
      downloadBlob(blobData , fileName);
    } else {
      // console.log("no download")
    }
  })    
};

