/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Flex, Text, View } from "@aws-amplify/ui-react";
export default function KanshiLocationTab(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        "Rectangle 87": { backgroundColor: "rgba(38,193,147,1)" },
        text438115330: { color: "rgba(53,56,59,1)" },
        text438115332: {},
        NumberBadge: {},
        "Frame 686": { backgroundColor: "rgba(245,245,245,1)" },
        KanshiLocationTab: {},
      },
      variantValues: { type: "Usual", situation: "Select" },
    },
    {
      overrides: {
        "Rectangle 87": { backgroundColor: "rgba(255,76,79,1)" },
        text438115330: { color: "rgba(255,76,79,1)" },
        text438115332: {},
        NumberBadge: { display: "flex" },
        "Frame 686": { backgroundColor: "rgba(245,245,245,1)" },
        KanshiLocationTab: {},
      },
      variantValues: { type: "Error", situation: "Select" },
    },
    {
      overrides: {
        "Rectangle 87": { backgroundColor: "rgba(250,180,50,1)" },
        text438115330: { color: "rgba(250,180,50,1)" },
        text438115332: { color: "rgba(250,180,50,1)" },
        NumberBadge: {
          border: "1px SOLID rgba(250,180,50,1)",
          display: "flex",
        },
        "Frame 686": { backgroundColor: "rgba(245,245,245,1)" },
        KanshiLocationTab: {},
      },
      variantValues: { type: "Warning", situation: "Select" },
    },
    {
      overrides: {
        "Rectangle 87": {},
        text438115330: { color: "rgba(255,76,79,1)" },
        text438115332: {},
        NumberBadge: { display: "flex" },
        "Frame 686": {},
        KanshiLocationTab: {},
      },
      variantValues: { type: "Error", situation: "Default" },
    },
    {
      overrides: {
        "Rectangle 87": {},
        text438115330: {},
        text438115332: {},
        NumberBadge: {},
        "Frame 686": {},
        KanshiLocationTab: {},
      },
      variantValues: { type: "Usual", situation: "Default" },
    },
    {
      overrides: {
        "Rectangle 87": {},
        text438115330: { color: "rgba(250,180,50,1)" },
        text438115332: { color: "rgba(250,180,50,1)" },
        NumberBadge: {
          border: "1px SOLID rgba(250,180,50,1)",
          display: "flex",
        },
        "Frame 686": {},
        KanshiLocationTab: {},
      },
      variantValues: { type: "Warning", situation: "Default" },
    },
    {
      overrides: {
        "Rectangle 87": { backgroundColor: "rgba(255,241,244,1)" },
        text438115330: { color: "rgba(255,76,79,1)" },
        text438115332: {},
        NumberBadge: { display: "flex" },
        "Frame 686": { backgroundColor: "rgba(255,241,244,1)" },
        KanshiLocationTab: {},
      },
      variantValues: { type: "Error", situation: "Hover" },
    },
    {
      overrides: {
        "Rectangle 87": { backgroundColor: "rgba(220,220,220,1)" },
        text438115330: {},
        text438115332: {},
        NumberBadge: {},
        "Frame 686": { backgroundColor: "rgba(220,220,220,1)" },
        KanshiLocationTab: {},
      },
      variantValues: { type: "Usual", situation: "Hover" },
    },
    {
      overrides: {
        "Rectangle 87": { backgroundColor: "rgba(255,250,231,1)" },
        text438115330: { color: "rgba(250,180,50,1)" },
        text438115332: { color: "rgba(250,180,50,1)" },
        NumberBadge: {
          border: "1px SOLID rgba(250,180,50,1)",
          display: "flex",
        },
        "Frame 686": { backgroundColor: "rgba(255,250,231,1)" },
        KanshiLocationTab: {},
      },
      variantValues: { type: "Warning", situation: "Hover" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="0"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      {...rest}
      {...getOverrideProps(overrides, "KanshiLocationTab")}
    >
      <View
        width="unset"
        height="2px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        borderRadius="2px 2px 0px 0px"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "Rectangle 87")}
      ></View>
      <Flex
        gap="10px"
        direction="row"
        width="170px"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="11px 24px 11px 24px"
        backgroundColor="rgba(255,255,255,1)"
        display="flex"
        {...getOverrideProps(overrides, "Frame 686")}
      >
        <Text
          fontFamily="Noto Sans"
          fontSize="16px"
          fontWeight="600"
          color="rgba(100,100,100,1)"
          lineHeight="16px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.65px"
          width="88px"
          height="24px"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="全拠点"
          {...getOverrideProps(overrides, "text438115330")}
        ></Text>
        <Flex
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          border="1px SOLID rgba(255,76,79,1)"
          borderRadius="12px"
          padding="3px 3px 3px 3px"
          display="none"
          {...getOverrideProps(overrides, "NumberBadge")}
        >
          <Text
            fontFamily="Noto Sans"
            fontSize="10px"
            fontWeight="600"
            color="rgba(255,76,79,1)"
            lineHeight="10px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.35px"
            width="16px"
            height="16px"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="01"
            {...getOverrideProps(overrides, "text438115332")}
          ></Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
