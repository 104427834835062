import {
    SetteiItem2
} from "../ui-components";


export default function SetteiItemCycleWrapper(props) {

    const onChangeHandler = (e) => {
        // props.onChangeHandler1(e.target.value);
    }

    return (
        <SetteiItem2
            overrides={{
                text: {
                    children: props.labeltext,
                },
                "SelectField528926961" : {
                    options : ["アンテナ1", "アンテナ2", "アンテナ3"],
                    placeholder : "",
                    fontSize: "0.875rem", // SelectFieldのsize=smallが効かない対策
                    gap: "0.25rem",　// SelectFieldのsize=smallが効かない対策
                    value : props.cyclevalue1,
                    onChange : onChangeHandler,
                },
                "SelectField528926952" : {
                    options : ["アンテナ1", "アンテナ2", "アンテナ3", "なし"],
                    placeholder : "",
                    label: "2nd", // UIコンポーネンツ側不備対応
                    fontSize: "0.875rem", // SelectFieldのsize=smallが効かない対策
                    gap: "0.25rem",　// SelectFieldのsize=smallが効かない対策
                    value : props.cyclevalue2,
                    onChange : onChangeHandler,
                },
                "SelectField528926970" : {
                    options : ["アンテナ1", "アンテナ2", "アンテナ3", "なし"],
                    placeholder : "",
                    fontSize: "0.875rem", // SelectFieldのsize=smallが効かない対策
                    gap: "0.25rem",　// SelectFieldのsize=smallが効かない対策
                    value : props.cyclevalue3,
                    onChange : onChangeHandler,
                },
            }}
        >

        </SetteiItem2>
    );
}