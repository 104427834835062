// UserContext.js
import React, { createContext, useState, useEffect } from 'react';
import { userManager } from './auth';

import axios from 'axios';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  // const [isAdminUser, setIsAdminUser] = useState(false);
  // const [isAdminOrg, setIsAdminOrg] = useState(false);
  // const [userInfo, setUserInfo] = useState({
  //   isAdminUser: false,
  //   isAdminOrg: false,
  //   name : "",
  //   email : "",
  // });
  const [userInfo, setUserInfo] = useState(null)

  // ログアウト時に状態を初期化とlocalstorageのクリア
  const logoutUserContext = () => {
    // setIsAdminUser(false);
    // setIsAdminOrg(false);
    // setUserInfo({
    //   name : "",
    //   email : "",
    // });
    // localStorage.removeItem('isAdminUser');
    // localStorage.removeItem('isAdminOrg');
    // localStorage.removeItem('userInfo');
    setUserInfo(null)
  }

  function casthiveRoleCheck(resData) {
    // console.log("casthiveRoleCheck")
    // console.log("casthiveresData",resData)
    const jsonResData = JSON.parse(JSON.stringify(resData));

    // このアプリのタイプIDの一覧を取得
    let aryThisAppTypeID = [];
    let aryCanAccessOrgID = [];
    if (jsonResData.hasOwnProperty("userRoles")) {
      jsonResData.userRoles.forEach((userRoles) => {
        if (userRoles.hasOwnProperty("applications")) {
          userRoles.applications.forEach((applications) => {
            if (applications.id === process.env.REACT_APP_OIDC_CLIENT_ID) {
              aryThisAppTypeID.push(userRoles.userTypeId);
              aryCanAccessOrgID.push(userRoles.organizationId);
            }
          });
        }
      });
    }
    // console.log("aryThisAppTypeID",aryThisAppTypeID)
    // console.log("aryCanAccessOrgID",aryCanAccessOrgID)

    // 同じOrganizationsが複数返って来るので重複削除
    let tmp = [];
    let uniqueOrganizations = [];
    if (jsonResData.hasOwnProperty('organizations')) {
      jsonResData.organizations.forEach((organization) => {
        if (!tmp.includes(organization.id)) {
          tmp.push(organization.id);
          uniqueOrganizations.push(organization);
        }
      });
    }
    jsonResData.organizations = uniqueOrganizations;
    // console.log("jsonResData.organizations",jsonResData.organizations)

    // 削除して空いた所を詰める
    jsonResData.organizations = jsonResData.organizations.filter(Boolean);
    // console.log("jsonResData.organizations",jsonResData.organizations)

    // organizationsからアクセス可能な物だけにフィルタリングして削除していく
    let iOrgNum = jsonResData.organizations.length;
    for (let iCheckIdx = 0; iCheckIdx < iOrgNum; iCheckIdx++) {
      let isDelete = true;
      for (let j = 0; j < aryCanAccessOrgID.length; j++) {
        if (aryCanAccessOrgID[j] === jsonResData.organizations[iCheckIdx].id) {
          isDelete = false;
          break;
        }
      }
      if (isDelete) {
        delete jsonResData.organizations[iCheckIdx];
      }
    }

    // 削除して空いた所を詰める
    jsonResData.organizations = jsonResData.organizations.filter(Boolean);
    // console.log("jsonResData.organizations",jsonResData.organizations)

    // 組織を特定する
    let SelOrgIndex = -1;

    // アクセス可能な組織が1つだけ
    if (aryCanAccessOrgID.length === 1) {
      for (let iOrg = 0; iOrg < jsonResData.organizations.length; iOrg++) {
        if (jsonResData.organizations[iOrg].id === aryCanAccessOrgID[0]) {
          SelOrgIndex = iOrg;
          break;
        }
      }
    } else if (jsonResData.organizations.length > 1) {
      // アクセス可能な組織が複数あり、Cookieに組織IDが存在する場合　複数組織パターンは現状ないので保留
      // if (document.cookie.includes("organization_id")) {
      //   const cookieOrgID = document.cookie
      //     .split("; ")
      //     .find((row) => row.startsWith("organization_id="))
      //     .split("=")[1];

      //   // アクセス可能な組織に入っているか確認
      //   if (aryCanAccessOrgID.includes(cookieOrgID)) {
      //     // Cookieに含まれる組織IDの位置を探す
      //     for (let iOrg = 0; iOrg < jsonResData.organizations.length; iOrg++) {
      //       if (jsonResData.organizations[iOrg].id === cookieOrgID) {
      //         SelOrgIndex = iOrg;
      //         break;
      //       }
      //     }
      //   }
      // }
      // Cookieに組織IDが存在しない場合、選択画面に移動する
    }
    // console.log("SelOrgIndex",SelOrgIndex)

    // 組織IDがちゃんと取れている場合、管理組織のユーザー＆管理者権限ユーザーかどうかを確認する
    if (SelOrgIndex >= 0) {
      let isAdminOrg = false;
      const strAdminOrgIds = process.env.REACT_APP_ADMIN_ORG_IDS
      const adminOrgIds = strAdminOrgIds.split(','); // カンマ区切りで配列に変換
      // adminOrgIdsに含まれる組織IDかどうかを確認する
      if (adminOrgIds.includes(String(jsonResData.organizations[SelOrgIndex].id))) {
        isAdminOrg = true;
      }

      let isAdminUser = false;
      
      for (let i = 0; i < jsonResData.userTypes.length; i++) {
        for (let j = 0; j < aryThisAppTypeID.length; j++) {
          if (
            jsonResData.userTypes[i].organizationId === jsonResData.organizations[SelOrgIndex].id &&
            jsonResData.userTypes[i].name === "OrganizationAppManager" &&
            jsonResData.userTypes[i].id === aryThisAppTypeID[j]
          ) {
            isAdminUser = true;
            break;
          }
        }
        if (isAdminUser) {
          break;
        }
      }
      // console.log("isAdminUser",isAdminUser)
      // console.log("isAdminOrg",isAdminOrg)
      // setIsAdminUser(isAdminUser);
      // setIsAdminOrg(isAdminOrg);
      const jsonUserInfo = {
        "isAdminUser": isAdminUser,
        "isAdminOrg": isAdminOrg,
        "name": jsonResData.lastName + " " + jsonResData.firstName,
        "email": jsonResData.email,
      }
      setUserInfo(jsonUserInfo);
    } 
  }

  const getUserInfo = async () => {
    const user = await userManager.getUser();

    if (user) {
      if (process.env.REACT_APP_OIDC_API_URL) {
        const accessToken = user.access_token;
        const apiUrl = process.env.REACT_APP_OIDC_API_URL + "/api/users/" + user.profile.sub;

        const apiConfig = {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            '$eager' : '[userRoles.[applications], organizations, userTypes, ninja]',
          },
        }
        // console.log("userAPI config", apiConfig)
        axios.get(apiUrl, apiConfig).then((res) => {
          // console.log("userAPI Call")
          // console.log(res.data);
          casthiveRoleCheck(res.data);
          // setIsAdminUser(true);
          // setIsAdminOrg(true);
        }).catch((err) => {
          // console.log('userAPI err', err)
        });
      } else {
        // console.log("no userAPI Call")
        // user.isAdmin = true;
        // setIsAdminUser(true);
        // user.organizationId = 2;
        // setIsAdminOrg(true);
        // userManager.storeUser(user);
        const jsonUserInfo = {
          "isAdminUser": false,
          "isAdminOrg": false,
          name: user.profile.username,
          email: user.profile.email,
        }
        setUserInfo(jsonUserInfo);
      }
    }
    // console.log("After:userInfo", userInfo)
  }

  // useEffect(() => {
  //   console.log("useEffect:userInfo",userInfo)
  // }, [userInfo]);


  useEffect(() => {
    // ローカルストレージから状態を復元する TODO ローカルストレージやめてCastHiveのAPIから取得し直す
    // const storedAdminUser = localStorage.getItem('isAdminUser');
    // const storedAdminOrg = localStorage.getItem('isAdminOrg');
    // const storedUserInfo = localStorage.getItem('userInfo');

    // if (storedAdminUser) {
    //   setIsAdminUser(JSON.parse(storedAdminUser));
    // }

    // if (storedAdminOrg) {
    //   setIsAdminOrg(JSON.parse(storedAdminOrg));
    // }

    // if (storedUserInfo) {
    //   setUserInfo(JSON.parse(storedUserInfo));
    // }
    // console.log("before:userInfo", userInfo)
    if (userInfo === null) {
      getUserInfo();
    }

    
  }, []);

  // useEffect(() => {
  //   // 状態の変更時にローカルストレージに保存する
  //   localStorage.setItem('isAdminUser', JSON.stringify(isAdminUser));
  //   localStorage.setItem('isAdminOrg', JSON.stringify(isAdminOrg));
  //   localStorage.setItem('userInfo', JSON.stringify(userInfo));
  // }, [isAdminUser, isAdminOrg, userInfo]);

  return (userInfo  === null ? 
    <></>
    : 
    <UserContext.Provider
      value={{
        // isAdminUser,
        // setIsAdminUser,
        // isAdminOrg,
        // setIsAdminOrg,
        userInfo,
        setUserInfo,
        logoutUserContext
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
