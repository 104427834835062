/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { CheckboxField, Flex, Text, View } from "@aws-amplify/ui-react";
import Spacer from "./Spacer";
import Myicons from "./Myicons";
import CommonBadgeIcon from "./CommonBadgeIcon";
import CommonDivider from "./CommonDivider";
export default function DatakanriListFile(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        CheckboxField: {},
        Checkbox: {},
        Spacer480814277: {},
        Myicons: { display: "block" },
        text480814280: {
          fontWeight: "500",
          children: "yscc_trace_20240401_0000.csv",
        },
        FileName: {},
        Spacer480814281: {},
        text480814283: { fontWeight: "500", children: "2022/09/16 13:30" },
        Date: {},
        Spacer480814284: {},
        text480814286: { fontWeight: "500", children: "csv" },
        FileType: {},
        Spacer480814287: {},
        text480814289: { fontWeight: "500", children: "100 KB" },
        Spacer480814290: {
          width: "40px",
          height: "40px",
          top: "-13.46%",
          bottom: "-40.38%",
          left: "58.44%",
          right: "16.56%",
        },
        FileSize: {},
        Spacer480814291: {},
        "Common/Badge/Icon480814293": { display: "flex" },
        "Rectangle 25": { display: "block" },
        "Common/Badge/Icon480814295": { display: "flex" },
        icons: {},
        Contents: {},
        "Common/Divider": {},
        DatakanriListFile: {},
      },
      variantValues: { type: "File", check: "off" },
    },
    {
      overrides: {
        CheckboxField: {},
        Checkbox: {},
        Spacer480814277: {},
        Myicons: { display: "block" },
        text480814280: {
          fontWeight: "500",
          children: "yscc_trace_20240401_0000.csv",
        },
        FileName: {},
        Spacer480814281: {},
        text480814283: { fontWeight: "500", children: "2022/09/16 13:30" },
        Date: {},
        Spacer480814284: {},
        text480814286: { fontWeight: "500", children: "csv" },
        FileType: {},
        Spacer480814287: {},
        text480814289: { fontWeight: "500", children: "100 KB" },
        Spacer480814290: {
          width: "40px",
          height: "40px",
          top: "-13.46%",
          bottom: "-40.38%",
          left: "58.44%",
          right: "16.56%",
        },
        FileSize: {},
        Spacer480814291: {},
        "Common/Badge/Icon480814293": { display: "flex", type: "gray" },
        "Rectangle 25": { display: "block" },
        "Common/Badge/Icon480814295": { display: "flex", type: "gray" },
        icons: {},
        Contents: { backgroundColor: "rgba(245,245,245,1)" },
        "Common/Divider": {},
        DatakanriListFile: {},
      },
      variantValues: { type: "FileHover", check: "off" },
    },
    {
      overrides: {
        CheckboxField: { defaultChecked: "true" },
        Checkbox: {},
        Spacer480814277: {},
        Myicons: { display: "block" },
        text480814280: {
          fontWeight: "500",
          children: "yscc_trace_20240401_0000.csv",
        },
        FileName: {},
        Spacer480814281: {},
        text480814283: { fontWeight: "500", children: "2022/09/16 13:30" },
        Date: {},
        Spacer480814284: {},
        text480814286: { fontWeight: "500", children: "csv" },
        FileType: {},
        Spacer480814287: {},
        text480814289: { fontWeight: "500", children: "100 KB" },
        Spacer480814290: {},
        FileSize: {},
        Spacer480814291: {},
        "Common/Badge/Icon480814293": { display: "flex", type: "blue" },
        "Rectangle 25": { display: "block" },
        "Common/Badge/Icon480814295": { display: "flex", type: "blue" },
        icons: {},
        Contents: { backgroundColor: "rgba(242,250,255,1)" },
        "Common/Divider": {},
        DatakanriListFile: {},
      },
      variantValues: { type: "File", check: "on" },
    },
    {
      overrides: {
        CheckboxField: {},
        Checkbox: {},
        Spacer480814277: {},
        Myicons: {},
        text480814280: {},
        FileName: {},
        Spacer480814281: {},
        text480814283: {},
        Date: {},
        Spacer480814284: {},
        text480814286: {},
        FileType: {},
        Spacer480814287: {},
        text480814289: {},
        Spacer480814290: {},
        FileSize: {},
        Spacer480814291: {},
        "Common/Badge/Icon480814293": {},
        "Rectangle 25": {},
        "Common/Badge/Icon480814295": {},
        icons: {},
        Contents: {},
        "Common/Divider": {},
        DatakanriListFile: {},
      },
      variantValues: { type: "Title", check: "off" },
    },
    {
      overrides: {
        CheckboxField: { defaultChecked: "true" },
        Checkbox: {},
        Spacer480814277: {},
        Myicons: {},
        text480814280: {},
        FileName: {},
        Spacer480814281: {},
        text480814283: {},
        Date: {},
        Spacer480814284: {},
        text480814286: {},
        FileType: {},
        Spacer480814287: {},
        text480814289: {},
        Spacer480814290: {},
        FileSize: {},
        Spacer480814291: {},
        "Common/Badge/Icon480814293": {},
        "Rectangle 25": {},
        "Common/Badge/Icon480814295": {},
        icons: {},
        Contents: {},
        "Common/Divider": {},
        DatakanriListFile: {},
      },
      variantValues: { type: "Title", check: "on" },
    },
    {
      overrides: {
        CheckboxField: { display: "none" },
        Checkbox: {},
        Spacer480814277: {},
        Myicons: {},
        text480814280: { fontWeight: "500", children: "YSCC" },
        FileName: {},
        Spacer480814281: {},
        text480814283: { fontWeight: "500", children: "\u30FC" },
        Date: {},
        Spacer480814284: {},
        text480814286: {
          fontWeight: "500",
          top: "1.92%",
          bottom: "-1.92%",
          children: "\u30FC",
        },
        FileType: {},
        Spacer480814287: {},
        text480814289: { fontWeight: "500", children: "\u30FC" },
        Spacer480814290: {},
        FileSize: {},
        Spacer480814291: {},
        "Common/Badge/Icon480814293": {},
        "Rectangle 25": {},
        "Common/Badge/Icon480814295": {},
        icons: {},
        Contents: {},
        "Common/Divider": {},
        DatakanriListFile: {},
      },
      variantValues: { type: "Folder", check: "on" },
    },
    {
      overrides: {
        CheckboxField: { display: "none" },
        Checkbox: { overflow: "hidden" },
        Spacer480814277: {},
        Myicons: {},
        text480814280: { fontWeight: "500", children: "YSCC" },
        FileName: {},
        Spacer480814281: {},
        text480814283: { fontWeight: "500", children: "\u30FC" },
        Date: {},
        Spacer480814284: {},
        text480814286: {
          fontWeight: "500",
          top: "1.92%",
          bottom: "-1.92%",
          children: "\u30FC",
        },
        FileType: {},
        Spacer480814287: {},
        text480814289: { fontWeight: "500", children: "\u30FC" },
        Spacer480814290: {},
        FileSize: {},
        Spacer480814291: {},
        "Common/Badge/Icon480814293": {},
        "Rectangle 25": {},
        "Common/Badge/Icon480814295": {},
        icons: {},
        Contents: { backgroundColor: "rgba(245,245,245,1)" },
        "Common/Divider": {},
        DatakanriListFile: {},
      },
      variantValues: { type: "Folder", check: "off" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="0"
      direction="column"
      width="1231px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      {...rest}
      {...getOverrideProps(overrides, "DatakanriListFile")}
    >
      <Flex
        gap="0"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="8px 16px 8px 16px"
        backgroundColor="rgba(255,255,255,1)"
        display="flex"
        {...getOverrideProps(overrides, "Contents")}
      >
        <View
          width="14px"
          height="14px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Checkbox")}
        >
          <CheckboxField
            width="unset"
            height="unset"
            position="absolute"
            top="0px"
            left="0px"
            size="small"
            defaultChecked={false}
            isDisabled={false}
            labelPosition="end"
            {...getOverrideProps(overrides, "CheckboxField")}
          ></CheckboxField>
        </View>
        <Spacer
          display="flex"
          gap="10px"
          direction="column"
          width="40px"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="10px 10px 10px 10px"
          {...getOverrideProps(overrides, "Spacer480814277")}
        ></Spacer>
        <Flex
          gap="16px"
          direction="row"
          width="352px"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "FileName")}
        >
          <Myicons
            display="none"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            overflow="hidden"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            type="file"
            {...getOverrideProps(overrides, "Myicons")}
          ></Myicons>
          <Text
            fontFamily="Noto Sans"
            fontSize="14px"
            fontWeight="700"
            color="rgba(53,56,59,1)"
            lineHeight="26px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.55px"
            width="320px"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="ファイル名"
            {...getOverrideProps(overrides, "text480814280")}
          ></Text>
        </Flex>
        <Spacer
          display="flex"
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="10px 10px 10px 10px"
          {...getOverrideProps(overrides, "Spacer480814281")}
        ></Spacer>
        <View
          width="200px"
          height="26px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Date")}
        >
          <Text
            fontFamily="Noto Sans"
            fontSize="14px"
            fontWeight="700"
            color="rgba(53,56,59,1)"
            lineHeight="26px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.55px"
            width="200px"
            height="26px"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="0%"
            bottom="0%"
            left="0%"
            right="0%"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="最終更新日"
            {...getOverrideProps(overrides, "text480814283")}
          ></Text>
        </View>
        <Spacer
          display="flex"
          gap="10px"
          direction="column"
          width="40px"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="10px 10px 10px 10px"
          {...getOverrideProps(overrides, "Spacer480814284")}
        ></Spacer>
        <View
          width="160px"
          height="26px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "FileType")}
        >
          <Text
            fontFamily="Noto Sans"
            fontSize="14px"
            fontWeight="700"
            color="rgba(53,56,59,1)"
            lineHeight="26px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.55px"
            width="160px"
            height="26px"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="0%"
            bottom="0%"
            left="0%"
            right="0%"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="ファイルのタイプ"
            {...getOverrideProps(overrides, "text480814286")}
          ></Text>
        </View>
        <Spacer
          display="flex"
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="10px 10px 10px 10px"
          {...getOverrideProps(overrides, "Spacer480814287")}
        ></Spacer>
        <View
          width="160px"
          height="26px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "FileSize")}
        >
          <Text
            fontFamily="Noto Sans"
            fontSize="14px"
            fontWeight="700"
            color="rgba(53,56,59,1)"
            lineHeight="26px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.55px"
            width="160px"
            height="26px"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="0%"
            bottom="0%"
            left="0%"
            right="0%"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="サイズ"
            {...getOverrideProps(overrides, "text480814289")}
          ></Text>
          <Spacer
            display="flex"
            gap="10px"
            direction="column"
            width="33px"
            height="33px"
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
            position="absolute"
            top="0%"
            bottom="-26.92%"
            left="60.62%"
            right="18.75%"
            padding="10px 10px 10px 10px"
            {...getOverrideProps(overrides, "Spacer480814290")}
          ></Spacer>
        </View>
        <Spacer
          display="flex"
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="10px 10px 10px 10px"
          {...getOverrideProps(overrides, "Spacer480814291")}
        ></Spacer>
        <Flex
          gap="24px"
          direction="row"
          width="113px"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "icons")}
        >
          <CommonBadgeIcon
            display="none"
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            borderRadius="16px"
            padding="8px 8px 8px 8px"
            backgroundColor="rgba(255,255,255,1)"
            situation="default"
            type="white"
            {...getOverrideProps(overrides, "Common/Badge/Icon480814293")}
          ></CommonBadgeIcon>
          <View
            width="1px"
            height="24px"
            display="none"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(170,170,170,1)"
            {...getOverrideProps(overrides, "Rectangle 25")}
          ></View>
          <CommonBadgeIcon
            display="none"
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            borderRadius="16px"
            padding="8px 8px 8px 8px"
            backgroundColor="rgba(255,255,255,1)"
            situation="default"
            type="white"
            {...getOverrideProps(overrides, "Common/Badge/Icon480814295")}
          ></CommonBadgeIcon>
        </Flex>
      </Flex>
      <CommonDivider
        display="flex"
        gap="0"
        direction="row"
        width="unset"
        height="1px"
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Common/Divider")}
      ></CommonDivider>
    </Flex>
  );
}
