import { useState } from "react";
import { Flex } from "@aws-amplify/ui-react";
import {
    KanshiLocation,
    KanshiAlertCard,
} from '../ui-components'
import { 
    KanshiAllStateWrapper,
    KanshiKyotenStateWrapper,
} from '../componentsWrapper'
  
export default function KanshiLocationWrapper(props) {

    const STATE_TAB_ERROR = "Error";
    const STATE_TAB_WARNING = "Warning";
    const STATE_TAB_USUAL = "Usual";

    const STATE_CARD_ERROR = "error";
    const STATE_CARD_WARNING = "warning";
    const STATE_CARD_USUAL = "default";

    const [tabAllSituation, setTabAllSituation] = useState("Select");
    const [tabYSCCSituation, setTabYSCCSituation] = useState("Default");
    const [tabSPESituation, setTabSPESituation] = useState("Default");
    const [tabSPWSituation, setTabSPWSituation] = useState("Default");
    const [tabSAYADOSituation, setTabSAYADOSituation] = useState("Default");    

    function onMouseEventEnter(tabName){
        switch (tabName) {
            case "全拠点":
                if (tabAllSituation === "Default") {
                    setTabAllSituation("Hover");
                }
                break;
            case "YSCC":
                if (tabYSCCSituation === "Default") {
                    setTabYSCCSituation("Hover");
                }
                break;
            case "SPE":
                if (tabSPESituation === "Default") {
                    setTabSPESituation("Hover");
                }
                break;
            case "SPW":
                if (tabSPWSituation === "Default") {
                    setTabSPWSituation("Hover");
                }
                break;
            case "SAYADO":
                if (tabSAYADOSituation === "Default") {
                    setTabSAYADOSituation("Hover");
                }
                break;
            default:
                break;
        }
    }

    function onMouseEventLeave(tabName){
        switch (tabName) {
            case "全拠点":
                if (tabAllSituation === "Hover") {
                    setTabAllSituation("Default");
                }
                break;
            case "YSCC":
                if (tabYSCCSituation === "Hover") {
                    setTabYSCCSituation("Default");
                }
                break;
            case "SPE":
                if (tabSPESituation === "Hover") {
                    setTabSPESituation("Default");
                }
                break;
            case "SPW":
                if (tabSPWSituation === "Hover") {
                    setTabSPWSituation("Default");
                }
                break;
            case "SAYADO":
                if (tabSAYADOSituation === "Hover") {
                    setTabSAYADOSituation("Default");
                }
                break;
            default:
                break;
        }
    }

    function onClickTab(tabName) {
        tabName === "全拠点" ? setTabAllSituation("Select") : setTabAllSituation("Default");
        tabName === "YSCC" ? setTabYSCCSituation("Select") : setTabYSCCSituation("Default");
        tabName === "SPE" ? setTabSPESituation("Select") : setTabSPESituation("Default");
        tabName === "SPW" ? setTabSPWSituation("Select") : setTabSPWSituation("Default");
        tabName === "SAYADO" ? setTabSAYADOSituation("Select") : setTabSAYADOSituation("Default");
    }

    // const [kanshiLocationTab, setKanshiLocationTab] = useState("全拠点");
    const [locationState, setLocationState] = useState({
        "全拠点": {
            state:STATE_TAB_USUAL,
            count:0
        }, 
        "YSCC":{
            state:STATE_TAB_USUAL,
            count:0,
            waveState:STATE_CARD_USUAL,
            kikiState:STATE_CARD_USUAL,
        }, 
        "SPE":{
            state:STATE_TAB_USUAL,
            count:0,
            waveState:STATE_CARD_USUAL,
            kikiState:STATE_CARD_USUAL,
        }, 
        "SPW": {
            state:STATE_TAB_USUAL,
            count:0,
            waveState:STATE_CARD_USUAL,
            kikiState:STATE_CARD_USUAL,
        }, 
        "SAYADO":{
            state:STATE_TAB_USUAL,
            count:0,
            waveState:STATE_CARD_USUAL,
            kikiState:STATE_CARD_USUAL,
        }
    });

    return (
        <>
        <KanshiLocation  width={"40%"} children={""}
            overrides= {{
            "Kanshi/Location/Tab36163935": {
                onClick: () => onClickTab("全拠点"),
                onMouseEnter:() => onMouseEventEnter("全拠点"),
                onMouseLeave:() => onMouseEventLeave("全拠点"),
                type:locationState.全拠点.state,
                situation:tabAllSituation,
                overrides: {
                    text438115330: {
                        children: "全拠点",
                    },
                    text438115332: {
                        children: locationState.全拠点.count,
                    },
                }
            },
            "Kanshi/Location/Tab36163938": {
                onClick: () => onClickTab("YSCC"),
                onMouseEnter:() => onMouseEventEnter("YSCC"),
                onMouseLeave:() => onMouseEventLeave("YSCC"),
                type:locationState.YSCC.state,
                situation:tabYSCCSituation,
                overrides: {
                    text438115330: {
                        children: "YSCC",
                    },
                    text438115332: {
                        children: locationState.YSCC.count,
                    },
                }
            },
            "Kanshi/Location/Tab36163942": {
                onClick: () => onClickTab("SPE"),
                onMouseEnter:() => onMouseEventEnter("SPE"),
                onMouseLeave:() => onMouseEventLeave("SPE"),
                type:locationState.SPE.state,
                situation:tabSPESituation,
                overrides: {
                    text438115330: {
                        children: "SPE",
                    },
                    text438115332: {
                        children: locationState.SPE.count,
                    },
                }
            },
            "Kanshi/Location/Tab36163944": {
                onClick: () => onClickTab("SPW"),
                onMouseEnter:() => onMouseEventEnter("SPW"),
                onMouseLeave:() => onMouseEventLeave("SPW"),
                type:locationState.SPW.state,
                situation:tabSPWSituation,
                overrides: {
                    text438115330: {
                        children: "SPW",
                    },
                    text438115332: {
                        children: locationState.SPW.count,
                    },
                }
            },
            "Kanshi/Location/Tab36163946": {
                onClick: () => onClickTab("SAYADO"),
                onMouseEnter:() => onMouseEventEnter("SAYADO"),
                onMouseLeave:() => onMouseEventLeave("SAYADO"),
                type:locationState.SAYADO.state,
                situation:tabSAYADOSituation,
                overrides: {
                    text438115330: {
                        children: "小夜戸",
                    },
                    text438115332: {
                        children: locationState.SAYADO.count,
                    },
                }
            },
            KanshiLocation: {},
            }}
            // situation={kanshiLocationTab}
        ></KanshiLocation>
        { tabAllSituation === "Select" ?
            <KanshiAllStateWrapper
            state={locationState}
            >
            </KanshiAllStateWrapper>
        : 
            <KanshiKyotenStateWrapper>
            </KanshiKyotenStateWrapper>
        }
        </>
    );
}