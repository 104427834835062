
export const SETTING_ITEM_TYPE_INPUT = "input";
export const SETTING_ITEM_TYPE_SELECT = "select";
export const SETTING_ITEM_TYPE_SETTEI_ITEM_2 = "item2";

export const SETTING_INPUTBOX_UNIT_DBM_MHZ = "dBm/MHz";
export const SETTING_INPUTBOX_UNIT_KAI = "Kai";
export const SETTING_INPUTBOX_UNIT_SECOND = "Byo";
export const SETTING_INPUTBOX_UNIT_MINUTE = "Hun";
export const SETTING_INPUTBOX_UNIT_HOUR = "Jikan";
export const SETTING_INPUTBOX_UNIT_TEMPERTURE = "Do";
export const SETTING_INPUTBOX_UNIT_DENRYU = "A";
export const SETTING_INPUTBOX_UNIT_NOTHING = "Off";

// SetteiInputBoxのコンポーネントでは対応していない。SetteiItemWrapperで個別対応で表示可能にしている
export const SETTING_INPUTBOX_UNIT_DBM = "dBm"; 



export const keisokuSettingJson = {
    "YSCC" : {
        // "Measurement Interval" : {
        //     type:"input",
        //     unit:SETTING_INPUTBOX_UNIT_SECOND,
        //     labeltext:"データ計測周期",
        //     range : {
        //         min:1,
        //         max:60,
        //     },
        //     inputvalue:10,
        // },
        "RBW_VBW" : {
            labeltext:"RBW",
            type:SETTING_ITEM_TYPE_SELECT,
            options:[
                        "10kHz",
                        "30kHz",
                        "100kHz",
                        "300kHz",
                        "1MHz"
                    ],
            inputvalue:"300kHz",
        },
        "Data Output Interval" : {
            labeltext:"データ統合間隔",
            type:SETTING_ITEM_TYPE_INPUT,
            unit:SETTING_INPUTBOX_UNIT_MINUTE,
            range : {
                min:1,
                max:60,
            },
            inputvalue:10,
        },
        "Calibration Interval" : {
            labeltext:"校正処理間隔",
            type:SETTING_ITEM_TYPE_INPUT,
            unit:SETTING_INPUTBOX_UNIT_HOUR,
            range : {
                min:1,
                max:24,
            },
            inputvalue:1,
        },
        // "RBW_VBW" : {
        //     labeltext:"RBW/VBW",
        //     type:"SETTING_ITEM_TYPE_SELECT",
        //     Options:[
        //         "10kHz",
        //         "30kHz",
        //         "100kHz",
        //         "300kHz",
        //         "1MHz"
        //     ],
        //     // unit:SETTING_INPUTBOX_UNIT_NOTHING,
        //     // range : {
        //     //     min:1,
        //     //     max:24,
        //     // },
        //     inputvalue:"300kHz",
        // },
        // "Spectrum_Analyzer_1" : {
        //     "1st" : 1,
        //     "2nd" : 2,
        //     "3rd" : 3
        // },
    
        // "Spectrum_Analyzer_2" : {
        //     "1st" : 2,
        //     "2nd" : 3,
        //     "3rd" : 1
        // },
    },
    "SPE" : {
        // "Measurement Interval" : {
        //     type:"input",
        //     unit:SETTING_INPUTBOX_UNIT_SECOND,
        //     labeltext:"データ計測周期",
        //     range : {
        //         min:1,
        //         max:60,
        //     },
        //     inputvalue:10,
        // },
        "RBW_VBW" : {
            labeltext:"RBW",
            type:SETTING_ITEM_TYPE_SELECT,
            options:[
                        "10kHz",
                        "30kHz",
                        "100kHz",
                        "300kHz",
                        "1MHz"
                    ],
            inputvalue:"300kHz",
        },
        "Data Output Interval" : {
            labeltext:"データ統合間隔",
            type:SETTING_ITEM_TYPE_INPUT,
            unit:SETTING_INPUTBOX_UNIT_MINUTE,
            range : {
                min:1,
                max:60,
            },
            inputvalue:10,
        },
        "Calibration Interval" : {
            labeltext:"校正処理間隔",
            type:SETTING_ITEM_TYPE_INPUT,
            unit:SETTING_INPUTBOX_UNIT_HOUR,
            range : {
                min:1,
                max:24,
            },
            inputvalue:2,
        },
        // "RBW_VBW" : {
        //     labeltext:"RBW/VBW",
        //     type:"SETTING_ITEM_TYPE_SELECT",
        //     Options:[
        //         "10kHz",
        //         "30kHz",
        //         "100kHz",
        //         "300kHz",
        //         "1MHz"
        //     ],
        //     // unit:SETTING_INPUTBOX_UNIT_NOTHING,
        //     // range : {
        //     //     min:1,
        //     //     max:24,
        //     // },
        //     inputvalue:"300kHz",
        // },
        // "Spectrum_Analyzer_1" : {
        //     "1st" : 1,
        //     "2nd" : 2,
        //     "3rd" : 3
        // },
    
        // "Spectrum_Analyzer_2" : {
        //     "1st" : 2,
        //     "2nd" : 3,
        //     "3rd" : 1
        // },
    },
    "SPW" : {
        // "Measurement Interval" : {
        //     type:"input",
        //     unit:SETTING_INPUTBOX_UNIT_SECOND,
        //     labeltext:"データ計測周期",
        //     range : {
        //         min:1,
        //         max:60,
        //     },
        //     inputvalue:10,
        // },
        "RBW_VBW" : {
            labeltext:"RBW",
            type:SETTING_ITEM_TYPE_SELECT,
            options:[
                        "10kHz",
                        "30kHz",
                        "100kHz",
                        "300kHz",
                        "1MHz"
                    ],
            inputvalue:"300kHz",
        },
        "Data Output Interval" : {
            labeltext:"データ統合間隔",
            type:SETTING_ITEM_TYPE_INPUT,
            unit:SETTING_INPUTBOX_UNIT_MINUTE,
            range : {
                min:1,
                max:60,
            },
            inputvalue:10,
        },
        "Calibration Interval" : {
            labeltext:"校正処理間隔",
            type:SETTING_ITEM_TYPE_INPUT,
            unit:SETTING_INPUTBOX_UNIT_HOUR,
            range : {
                min:1,
                max:24,
            },
            inputvalue:3,
        },
        // "RBW_VBW" : {
        //     labeltext:"RBW/VBW",
        //     type:"SETTING_ITEM_TYPE_SELECT",
        //     Options:[
        //         "10kHz",
        //         "30kHz",
        //         "100kHz",
        //         "300kHz",
        //         "1MHz"
        //     ],
        //     // unit:SETTING_INPUTBOX_UNIT_NOTHING,
        //     // range : {
        //     //     min:1,
        //     //     max:24,
        //     // },
        //     inputvalue:"300kHz",
        // },
        // "Spectrum_Analyzer_1" : {
        //     "1st" : 1,
        //     "2nd" : 2,
        //     "3rd" : 3
        // },
    
        // "Spectrum_Analyzer_2" : {
        //     "1st" : 2,
        //     "2nd" : 3,
        //     "3rd" : 1
        // },
    },
    "GUNMA" : {
        // "Measurement Interval" : {
        //     type:"input",
        //     unit:SETTING_INPUTBOX_UNIT_SECOND,
        //     labeltext:"データ計測周期",
        //     range : {
        //         min:1,
        //         max:60,
        //     },
        //     inputvalue:10,
        // },
        "RBW_VBW" : {
            labeltext:"RBW",
            type:SETTING_ITEM_TYPE_SELECT,
            options:[
                        "10kHz",
                        "30kHz",
                        "100kHz",
                        "300kHz",
                        "1MHz"
                    ],
            inputvalue:"300kHz",
        },  
        "Data Output Interval" : {
            labeltext:"データ統合間隔",
            type:SETTING_ITEM_TYPE_INPUT,
            unit:SETTING_INPUTBOX_UNIT_MINUTE,
            range : {
                min:1,
                max:60,
            },
            inputvalue:10,
        },
        "Calibration Interval" : {
            labeltext:"校正処理間隔",
            type:SETTING_ITEM_TYPE_INPUT,
            unit:SETTING_INPUTBOX_UNIT_HOUR,
            range : {
                min:1,
                max:24,
            },
            inputvalue:4,
        },
        // "RBW_VBW" : {
        //     labeltext:"RBW/VBW",
        //     type:"SETTING_ITEM_TYPE_SELECT",
        //     Options:[
        //         "10kHz",
        //         "30kHz",
        //         "100kHz",
        //         "300kHz",
        //         "1MHz"
        //     ],
        //     // unit:SETTING_INPUTBOX_UNIT_NOTHING,
        //     // range : {
        //     //     min:1,
        //     //     max:24,
        //     // },
        //     inputvalue:"300kHz",
        // },
        // "Spectrum_Analyzer_1" : {
        //     "1st" : 1,
        //     "2nd" : 2,
        //     "3rd" : 3
        // },
    
        // "Spectrum_Analyzer_2" : {
        //     "1st" : 2,
        //     "2nd" : 3,
        //     "3rd" : 1
        // },
    },
}

export const keisokuCycleSettingJson = {
    "YSCC" : {
        "Spectrum_Analyzer_1" : {
            labeltext:"スペアナ1計測サイクル",
            "1st" : 1,
            "2nd" : 2,
            "3rd" : 3
        },
        "Spectrum_Analyzer_2" : {
            labeltext:"スペアナ2計測サイクル",
            "1st" : 2,
            "2nd" : 3,
            "3rd" : 1
        },
    },
    "SPE" : {
        "Spectrum_Analyzer_1" : {
            labeltext:"スペアナ1計測サイクル",
            "1st" : 1,
            "2nd" : 2,
            "3rd" : 3
        },
        "Spectrum_Analyzer_2" : {
            labeltext:"スペアナ2計測サイクル",
            "1st" : 2,
            "2nd" : 3,
            "3rd" : 1
        },
    },
    "SPW" : {
        "Spectrum_Analyzer_1" : {
            labeltext:"スペアナ1計測サイクル",
            "1st" : 1,
            "2nd" : 2,
            "3rd" : 3
        },
        "Spectrum_Analyzer_2" : {
            labeltext:"スペアナ2計測サイクル",
            "1st" : 2,
            "2nd" : 3,
            "3rd" : 1
        },
    },
    "GUNMA" : {
        "Spectrum_Analyzer_1" : {
            labeltext:"スペアナ1計測サイクル",
            "1st" : 1,
            "2nd" : 2,
            "3rd" : 3
        },
        "Spectrum_Analyzer_2" : {
            labeltext:"スペアナ2計測サイクル",
            "1st" : 2,
            "2nd" : 3,
            "3rd" : 1
        },
    },
}


export const keisokuKanshiSettingJson = {
    "YSCC" : {
        "3400-3440" : {
            "Threshold A" : {
                labeltext:"A：時間率判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: "",
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold B" :  {
                labeltext:"B：最大値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: "",
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold C" : {
                labeltext:"C：合計値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM,
                    range : null,
                    inputvalue: -21.990,
                },
                // option2:{},
                // option3:{},
                // option4:{},
            },
        },
    
        "3440-3480" : {
            "Threshold A" : {
                labeltext:"A：時間率判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: "",
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold B" :  {
                labeltext:"B：最大値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: "",
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold C" : {
                labeltext:"C：合計値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM,
                    range : null,
                    inputvalue: -21.990,
                },
                // option2:{},
                // option3:{},
                // option4:{},
            },
        },
    
        "3480-3520" : {
            "Threshold A" : {
                labeltext:"A：時間率判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: "",
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold B" :  {
                labeltext:"B：最大値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: "",
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold C" : {
                labeltext:"C：合計値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM,
                    range : null,
                    inputvalue: -21.990,
                },
                // option2:{},
                // option3:{},
                // option4:{},
            },
        },
    
        "3520-3560" : {
            "Threshold A" : {
                labeltext:"A：時間率判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: "",
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold B" :  {
                labeltext:"B：最大値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: "",
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold C" : {
                labeltext:"C：合計値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM,
                    range : null,
                    inputvalue: -21.990,
                },
                // option2:{},
                // option3:{},
                // option4:{},
            },
        },
    
        "3560-3600" : {
            "Threshold A" : {
                labeltext:"A：時間率判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: "",
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold B" :  {
                labeltext:"B：最大値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: "",
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold C" : {
                labeltext:"C：合計値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM,
                    range : null,
                    inputvalue: -21.990,
                },
                // option2:{},
                // option3:{},
                // option4:{},
            },
        },
    
        "3600-3700" : {
            "Threshold A" : {
                labeltext:"A：時間率判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -118.649,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold B" :  {
                labeltext:"B：最大値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -106.949,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold C" : {
                labeltext:"C：合計値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM,
                    range : null,
                    inputvalue: "",
                },
                // option2:{},
                // option3:{},
                // option4:{},
            },
        },
    
        "3700-3800" : {
            "Threshold A" : {
                labeltext:"A：時間率判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -118.649,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold B" :  {
                labeltext:"B：最大値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -106.949,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold C" : {
                labeltext:"C：合計値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM,
                    range : null,
                    inputvalue: "",
                },
                // option2:{},
                // option3:{},
                // option4:{},
            },
        },
    
        "3800-3900" : {
            "Threshold A" : {
                labeltext:"A：時間率判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: "",
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold B" :  {
                labeltext:"B：最大値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: "",
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold C" : {
                labeltext:"C：合計値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM,
                    range : null,
                    inputvalue: -21.990,
                },
                // option2:{},
                // option3:{},
                // option4:{},
            },
        },
    
        "3900-4000" : {
            "Threshold A" : {
                labeltext:"A：時間率判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -118.649,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold B" :  {
                labeltext:"B：最大値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -106.949,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold C" : {
                labeltext:"C：合計値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM,
                    range : null,
                    inputvalue: "",
                },
                // option2:{},
                // option3:{},
                // option4:{},
            },
        },
    
        "4000-4100" : {
            "Threshold A" : {
                labeltext:"A：時間率判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -118.649,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold B" :  {
                labeltext:"B：最大値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -106.949,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold C" : {
                labeltext:"C：合計値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM,
                    range : null,
                    inputvalue: "",
                },
                // option2:{},
                // option3:{},
                // option4:{},
            },
        },

        "合計": {
            "Threshold C" : {
                labeltext:"C：合計値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM,
                    range : null,
                    inputvalue: -80.000,
                },
                // option2:{},
                // option3:{},
                // option4:{},
            },
        }

        // "total" : {
        //     "3400-3440" : true,
        //     "3440-3480" : true,
        //     "3480-3520" : true,
        //     "3520-3560" : true,
        //     "3560-3600" : true,
        //     "3600-3700" : true,
        //     "3700-3800" : true,
        //     "3800-3900" : true,
        //     "3900-4000" : true,
        //     "4000-4100" : true
        // },
        // "Threshold D" : -80,
    },
    "SPE" : {
        "3400-3440" : {
            "Threshold A" : {
                labeltext:"A：時間率判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -128.649,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold B" :  {
                labeltext:"B：最大値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -116.949,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold C" : {
                labeltext:"C：合計値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM,
                    range : null,
                    inputvalue: "",
                },
                // option2:{},
                // option3:{},
                // option4:{},
            },
        },
    
        "3440-3480" : {
            "Threshold A" : {
                labeltext:"A：時間率判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"以上",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -128.649,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold B" :  {
                labeltext:"B：最大値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -116.949,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold C" : {
                labeltext:"C：合計値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM,
                    range : null,
                    inputvalue: "",
                },
                // option2:{},
                // option3:{},
                // option4:{},
            },
        },
    
        "3480-3520" : {
            "Threshold A" : {
                labeltext:"A：時間率判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -128.649,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold B" :  {
                labeltext:"B：最大値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -116.949,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold C" : {
                labeltext:"C：合計値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM,
                    range : null,
                    inputvalue: "",
                },
                // option2:{},
                // option3:{},
                // option4:{},
            },
        },
    
        "3520-3560" : {
            "Threshold A" : {
                labeltext:"A：時間率判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -128.649,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold B" :  {
                labeltext:"B：最大値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -116.949,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold C" : {
                labeltext:"C：合計値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM,
                    range : null,
                    inputvalue: "",
                },
                // option2:{},
                // option3:{},
                // option4:{},
            },
        },
    
        "3560-3600" : {
            "Threshold A" : {
                labeltext:"A：時間率判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -128.649,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold B" :  {
                labeltext:"B：最大値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -116.949,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold C" : {
                labeltext:"C：合計値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM,
                    range : null,
                    inputvalue: "",
                },
                // option2:{},
                // option3:{},
                // option4:{},
            },
        },
    
        "3600-3700" : {
            "Threshold A" : {
                labeltext:"A：時間率判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -128.649,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold B" :  {
                labeltext:"B：最大値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -116.949,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold C" : {
                labeltext:"C：合計値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM,
                    range : null,
                    inputvalue: "",
                },
                // option2:{},
                // option3:{},
                // option4:{},
            },
        },
    
        "3700-3800" : {
            "Threshold A" : {
                labeltext:"A：時間率判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -128.649,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold B" :  {
                labeltext:"B：最大値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -116.949,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold C" : {
                labeltext:"C：合計値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM,
                    range : null,
                    inputvalue: "",
                },
                // option2:{},
                // option3:{},
                // option4:{},
            },
        },
    
        "3800-3900" : {
            "Threshold A" : {
                labeltext:"A：時間率判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -128.649,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold B" :  {
                labeltext:"B：最大値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -116.949,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold C" : {
                labeltext:"C：合計値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM,
                    range : null,
                    inputvalue: "",
                },
                // option2:{},
                // option3:{},
                // option4:{},
            },
        },
    
        "3900-4000" : {
            "Threshold A" : {
                labeltext:"A：時間率判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -128.649,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold B" :  {
                labeltext:"B：最大値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -116.949,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold C" : {
                labeltext:"C：合計値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM,
                    range : null,
                    inputvalue: "",
                },
                // option2:{},
                // option3:{},
                // option4:{},
            },
        },
    
        "4000-4100" : {
            "Threshold A" : {
                labeltext:"A：時間率判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -128.649,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold B" :  {
                labeltext:"B：最大値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -116.949,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold C" : {
                labeltext:"C：合計値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM,
                    range : null,
                    inputvalue: "",
                },
                // option2:{},
                // option3:{},
                // option4:{},
            },
        },

        "合計": {
            "Threshold C" : {
                labeltext:"C：合計値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM,
                    range : null,
                    inputvalue: -80.000,
                },
                // option2:{},
                // option3:{},
                // option4:{},
            },
        }

        // "total" : {
        //     "3400-3440" : true,
        //     "3440-3480" : true,
        //     "3480-3520" : true,
        //     "3520-3560" : true,
        //     "3560-3600" : true,
        //     "3600-3700" : true,
        //     "3700-3800" : true,
        //     "3800-3900" : true,
        //     "3900-4000" : true,
        //     "4000-4100" : true
        // },
        // "Threshold D" : -80,
    },
    "SPW" : {
        "3400-3440" : {
            "Threshold A" : {
                labeltext:"A：時間率判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -128.649,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold B" :  {
                labeltext:"B：最大値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -116.949,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold C" : {
                labeltext:"C：合計値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM,
                    range : null,
                    inputvalue: "",
                },
                // option2:{},
                // option3:{},
                // option4:{},
            },
        },
    
        "3440-3480" : {
            "Threshold A" : {
                labeltext:"A：時間率判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -128.649,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold B" :  {
                labeltext:"B：最大値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -116.949,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold C" : {
                labeltext:"C：合計値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM,
                    range : null,
                    inputvalue: "",
                },
                // option2:{},
                // option3:{},
                // option4:{},
            },
        },
    
        "3480-3520" : {
            "Threshold A" : {
                labeltext:"A：時間率判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -128.649,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold B" :  {
                labeltext:"B：最大値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -116.949,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold C" : {
                labeltext:"C：合計値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM,
                    range : null,
                    inputvalue: "",
                },
                // option2:{},
                // option3:{},
                // option4:{},
            },
        },
    
        "3520-3560" : {
            "Threshold A" : {
                labeltext:"A：時間率判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -128.649,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold B" :  {
                labeltext:"B：最大値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -116.949,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold C" : {
                labeltext:"C：合計値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM,
                    range : null,
                    inputvalue: "",
                },
                // option2:{},
                // option3:{},
                // option4:{},
            },
        },
    
        "3560-3600" : {
            "Threshold A" : {
                labeltext:"A：時間率判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -128.649,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold B" :  {
                labeltext:"B：最大値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -116.949,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold C" : {
                labeltext:"C：合計値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM,
                    range : null,
                    inputvalue: "",
                },
                // option2:{},
                // option3:{},
                // option4:{},
            },
        },
    
        "3600-3700" : {
            "Threshold A" : {
                labeltext:"A：時間率判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -128.649,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold B" :  {
                labeltext:"B：最大値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -116.949,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold C" : {
                labeltext:"C：合計値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM,
                    range : null,
                    inputvalue: "",
                },
                // option2:{},
                // option3:{},
                // option4:{},
            },
        },
    
        "3700-3800" : {
            "Threshold A" : {
                labeltext:"A：時間率判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -128.649,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold B" :  {
                labeltext:"B：最大値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -116.949,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold C" : {
                labeltext:"C：合計値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM,
                    range : null,
                    inputvalue: "",
                },
                // option2:{},
                // option3:{},
                // option4:{},
            },
        },
    
        "3800-3900" : {
            "Threshold A" : {
                labeltext:"A：時間率判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -128.649,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold B" :  {
                labeltext:"B：最大値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -116.949,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold C" : {
                labeltext:"C：合計値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM,
                    range : null,
                    inputvalue: "",
                },
                // option2:{},
                // option3:{},
                // option4:{},
            },
        },
    
        "3900-4000" : {
            "Threshold A" : {
                labeltext:"A：時間率判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -128.649,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold B" :  {
                labeltext:"B：最大値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -116.949,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold C" : {
                labeltext:"C：合計値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM,
                    range : null,
                    inputvalue: "",
                },
                // option2:{},
                // option3:{},
                // option4:{},
            },
        },
    
        "4000-4100" : {
            "Threshold A" : {
                labeltext:"A：時間率判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -128.649,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold B" :  {
                labeltext:"B：最大値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -116.949,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold C" : {
                labeltext:"C：合計値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM,
                    range : null,
                    inputvalue: "",
                },
                // option2:{},
                // option3:{},
                // option4:{},
            },
        },

        "合計": {
            "Threshold C" : {
                labeltext:"C：合計値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM,
                    range : null,
                    inputvalue: -80.000,
                },
                // option2:{},
                // option3:{},
                // option4:{},
            },
        }
        // "total" : {
        //     "3400-3440" : true,
        //     "3440-3480" : true,
        //     "3480-3520" : true,
        //     "3520-3560" : true,
        //     "3560-3600" : true,
        //     "3600-3700" : true,
        //     "3700-3800" : true,
        //     "3800-3900" : true,
        //     "3900-4000" : true,
        //     "4000-4100" : true
        // },
        // "Threshold D" : -80,
    },
    "GUNMA" : {
        "3400-3440" : {
            "Threshold A" : {
                labeltext:"A：時間率判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: "",
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold B" :  {
                labeltext:"B：最大値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: "",
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold C" : {
                labeltext:"C：合計値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM,
                    range : null,
                    inputvalue: -26.990,
                },
                // option2:{},
                // option3:{},
                // option4:{},
            },
        },
    
        "3440-3480" : {
            "Threshold A" : {
                labeltext:"A：時間率判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: "",
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold B" :  {
                labeltext:"B：最大値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: "",
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold C" : {
                labeltext:"C：合計値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM,
                    range : null,
                    inputvalue: -26.990,
                },
                // option2:{},
                // option3:{},
                // option4:{},
            },
        },
    
        "3480-3520" : {
            "Threshold A" : {
                labeltext:"A：時間率判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: "",
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold B" :  {
                labeltext:"B：最大値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: "",
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold C" : {
                labeltext:"C：合計値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM,
                    range : null,
                    inputvalue: -26.990,
                },
                // option2:{},
                // option3:{},
                // option4:{},
            },
        },
    
        "3520-3560" : {
            "Threshold A" : {
                labeltext:"A：時間率判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: "",
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold B" :  {
                labeltext:"B：最大値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: "",
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold C" : {
                labeltext:"C：合計値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM,
                    range : null,
                    inputvalue: -26.990,
                },
                // option2:{},
                // option3:{},
                // option4:{},
            },
        },
    
        "3560-3600" : {
            "Threshold A" : {
                labeltext:"A：時間率判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: "",
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold B" :  {
                labeltext:"B：最大値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: "",
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold C" : {
                labeltext:"C：合計値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM,
                    range : null,
                    inputvalue: -26.990,
                },
                // option2:{},
                // option3:{},
                // option4:{},
            },
        },
    
        "3600-3700" : {
            "Threshold A" : {
                labeltext:"A：時間率判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -127.909,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold B" :  {
                labeltext:"B：最大値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -116.209,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold C" : {
                labeltext:"C：合計値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM,
                    range : null,
                    inputvalue: "",
                },
                // option2:{},
                // option3:{},
                // option4:{},
            },
        },
    
        "3700-3800" : {
            "Threshold A" : {
                labeltext:"A：時間率判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -127.909,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold B" :  {
                labeltext:"B：最大値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -116.209,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold C" : {
                labeltext:"C：合計値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM,
                    range : null,
                    inputvalue: "",
                },
                // option2:{},
                // option3:{},
                // option4:{},
            },
        },
    
        "3800-3900" : {
            "Threshold A" : {
                labeltext:"A：時間率判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -127.909,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold B" :  {
                labeltext:"B：最大値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -116.209,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold C" : {
                labeltext:"C：合計値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM,
                    range : null,
                    inputvalue: "",
                },
                // option2:{},
                // option3:{},
                // option4:{},
            },
        },
    
        "3900-4000" : {
            "Threshold A" : {
                labeltext:"A：時間率判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -127.909,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold B" :  {
                labeltext:"B：最大値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -116.209,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold C" : {
                labeltext:"C：合計値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM,
                    range : null,
                    inputvalue: "",
                },
                // option2:{},
                // option3:{},
                // option4:{},
            },
        },
    
        "4000-4100" : {
            "Threshold A" : {
                labeltext:"A：時間率判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -127.909,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold B" :  {
                labeltext:"B：最大値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM_MHZ,
                    range : null,
                    inputvalue: -116.209,
                },
                // option2:null,
                // option3:null,
                // option4:null,
            },
            "Threshold C" : {
                labeltext:"C：合計値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"OFF",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM,
                    range : null,
                    inputvalue: "",
                },
                // option2:{},
                // option3:{},
                // option4:{},
            },
        },

        "合計": {
            "Threshold C" : {
                labeltext:"C：合計値判定",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DBM,
                    range : null,
                    inputvalue: -80.000,
                },
                // option2:{},
                // option3:{},
                // option4:{},
            },
        }

        // "total" : {
        //     "3400-3440" : true,
        //     "3440-3480" : true,
        //     "3480-3520" : true,
        //     "3520-3560" : true,
        //     "3560-3600" : true,
        //     "3600-3700" : true,
        //     "3700-3800" : true,
        //     "3800-3900" : true,
        //     "3900-4000" : true,
        //     "4000-4100" : true
        // },
        // "Threshold D" : -80,
    },
}

export const machineKanshiSettingJson = {
    "YSCC" : {
        "スペアナ1" : {
            "PING監視" : {
                labeltext:"PING監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:300,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"無応答判定回数",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_KAI,
                    range : {
                        min:3,
                        max:10,
                    },
                    inputvalue: 5,
                },
                // option3:null,
                // option4:null,
            },
        },
        "スペアナ2" : {
            "PING監視" : {
                labeltext:"PING監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:300,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"無応答判定回数",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_KAI,
                    range : {
                        min:3,
                        max:10,
                    },
                    inputvalue: 5,
                },
                // option3:null,
                // option4:null,
            },
        },
        "GPS1" : {
            "死活監視" : {
                labeltext:"死活監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:300,
                    },
                    inputvalue: 180,
                }
            },
        },
        "GPS2" : {
            "死活監視" : {
                labeltext:"死活監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:300,
                    },
                    inputvalue: 180,
                }
            },
        },
        "DC電源1" : {
            "PING監視" : {
                labeltext:"PING監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:300,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"無応答判定回数",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_KAI,
                    range : {
                        min:3,
                        max:10,
                    },
                    inputvalue: 5,
                },
                // option3:null,
                // option4:null,
            },
            "消費電力監視" : {
                labeltext:"消費電力監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"警告",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:180,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"消費電流上限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 5.0,
                },
                option3: {
                    labeltext:"消費電流下限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 1.0,
                },
            },
        },
        "DC電源2" : {
            "PING監視" : {
                labeltext:"PING監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:300,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"無応答判定回数",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_KAI,
                    range : {
                        min:3,
                        max:10,
                    },
                    inputvalue: 5,
                },
                // option3:null,
                // option4:null,
            },
            "消費電力監視" : {
                labeltext:"消費電力監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"警告",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:180,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"消費電流上限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 5.0,
                },
                option3: {
                    labeltext:"消費電流下限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 1.0,
                },
            },
        },
        "DC電源3" : {
            "PING監視" : {
                labeltext:"PING監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:300,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"無応答判定回数",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_KAI,
                    range : {
                        min:3,
                        max:10,
                    },
                    inputvalue: 5,
                },
                // option3:null,
                // option4:null,
            },
            "消費電力監視" : {
                labeltext:"消費電力監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"警告",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:180,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"消費電流上限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 5.0,
                },
                option3: {
                    labeltext:"消費電流下限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 1.0,
                },
            },
        },
        "DC電源4" : {
            "PING監視" : {
                labeltext:"PING監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:300,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"無応答判定回数",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_KAI,
                    range : {
                        min:3,
                        max:10,
                    },
                    inputvalue: 5,
                },
                // option3:null,
                // option4:null,
            },
            "消費電力監視" : {
                labeltext:"消費電力監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"警告",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:180,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"消費電流上限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 5.0,
                },
                option3: {
                    labeltext:"消費電流下限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 1.0,
                },
            },
        },
        "DC電源5" : {
            "PING監視" : {
                labeltext:"PING監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:300,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"無応答判定回数",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_KAI,
                    range : {
                        min:3,
                        max:10,
                    },
                    inputvalue: 5,
                },
                // option3:null,
                // option4:null,
            },
            "消費電力監視" : {
                labeltext:"消費電力監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"警告",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:180,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"消費電流上限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 5.0,
                },
                option3: {
                    labeltext:"消費電流下限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 1.0,
                },
            },
        },
        "NWSW1" : {
            "SNMPTrap" : {
                labeltext:"SNMPTrap",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"情報",
            },
        },
        "NWSW2" : {
            "SNMPTrap" : {
                labeltext:"SNMPTrap",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"情報",
            },
        },
        "NWSW3" : {
            "SNMPTrap" : {
                labeltext:"SNMPTrap",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"情報",
            },
        },
        "NWSW4" : {
            "SNMPTrap" : {
                labeltext:"SNMPTrap",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"情報",
            },
        },
        "温度センサー" : {
            "PING監視" : {
                labeltext:"PING監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:300,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"無応答判定回数",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_KAI,
                    range : {
                        min:3,
                        max:10,
                    },
                    inputvalue: 5,
                },
                // option3:null,
                // option4:null,
            },
        },
        "ノイズソース" : {
            "温度監視" : {
                labeltext:"温度監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"警告",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:3600,
                    },
                    inputvalue: 60,
                },
                option2: {
                    labeltext:"温度上限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_TEMPERTURE,
                    range : {
                        min:0,
                        max:100,
                    },
                    inputvalue: 80,
                },
                option3: {
                    labeltext:"温度下限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_TEMPERTURE,
                    range : {
                        min:0,
                        max:100,
                    },
                    inputvalue: 0,
                },
            },
        },
        "キャビネット内温度" : {
            "温度監視" : {
                labeltext:"温度監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"警告",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:1,
                        max:300,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"温度上限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_TEMPERTURE,
                    range : {
                        min:0,
                        max:100,
                    },
                    inputvalue: 50,
                },
                option3: {
                    labeltext:"温度下限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_TEMPERTURE,
                    range : {
                        min:0,
                        max:100,
                    },
                    inputvalue: 0,
                },
            },
        },
        "キャビネット電源1" : {
            "消費電力監視" : {
                labeltext:"消費電力監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"警告",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:180,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"消費電流上限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 5.0,
                },
                option3: {
                    labeltext:"消費電流下限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 1.0,
                },
            },
        },
        "計測PC本番機" : {
            "死活監視" : {
                labeltext:"死活監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:60,
                        max:300,
                    },
                    inputvalue: 60,
                }
            },
        },
    },
    "SPE" : {
        "スペアナ1" : {
            "PING監視" : {
                labeltext:"PING監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:300,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"無応答判定回数",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_KAI,
                    range : {
                        min:3,
                        max:10,
                    },
                    inputvalue: 5,
                },
                // option3:null,
                // option4:null,
            },
        },
        "スペアナ2" : {
            "PING監視" : {
                labeltext:"PING監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:300,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"無応答判定回数",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_KAI,
                    range : {
                        min:3,
                        max:10,
                    },
                    inputvalue: 5,
                },
                // option3:null,
                // option4:null,
            },
        },
        "GPS1" : {
            "死活監視" : {
                labeltext:"死活監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:300,
                    },
                    inputvalue: 180,
                }
            },
        },
        "GPS2" : {
            "死活監視" : {
                labeltext:"死活監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:300,
                    },
                    inputvalue: 180,
                }
            },
        },
        "DC電源1" : {
            "PING監視" : {
                labeltext:"PING監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:300,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"無応答判定回数",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_KAI,
                    range : {
                        min:3,
                        max:10,
                    },
                    inputvalue: 5,
                },
                // option3:null,
                // option4:null,
            },
            "消費電力監視" : {
                labeltext:"消費電力監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"警告",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:180,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"消費電流上限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 5.0,
                },
                option3: {
                    labeltext:"消費電流下限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 1.0,
                },
            },
        },
        "DC電源2" : {
            "PING監視" : {
                labeltext:"PING監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:300,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"無応答判定回数",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_KAI,
                    range : {
                        min:3,
                        max:10,
                    },
                    inputvalue: 5,
                },
                // option3:null,
                // option4:null,
            },
            "消費電力監視" : {
                labeltext:"消費電力監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"警告",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:180,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"消費電流上限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 5.0,
                },
                option3: {
                    labeltext:"消費電流下限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 1.0,
                },
            },
        },
        "DC電源3" : {
            "PING監視" : {
                labeltext:"PING監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:300,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"無応答判定回数",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_KAI,
                    range : {
                        min:3,
                        max:10,
                    },
                    inputvalue: 5,
                },
                // option3:null,
                // option4:null,
            },
            "消費電力監視" : {
                labeltext:"消費電力監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"警告",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:180,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"消費電流上限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 5.0,
                },
                option3: {
                    labeltext:"消費電流下限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 1.0,
                },
            },
        },
        "DC電源4" : {
            "PING監視" : {
                labeltext:"PING監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:300,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"無応答判定回数",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_KAI,
                    range : {
                        min:3,
                        max:10,
                    },
                    inputvalue: 5,
                },
                // option3:null,
                // option4:null,
            },
            "消費電力監視" : {
                labeltext:"消費電力監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"警告",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:180,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"消費電流上限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 5.0,
                },
                option3: {
                    labeltext:"消費電流下限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 1.0,
                },
            },
        },
        "DC電源5" : {
            "PING監視" : {
                labeltext:"PING監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:300,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"無応答判定回数",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_KAI,
                    range : {
                        min:3,
                        max:10,
                    },
                    inputvalue: 5,
                },
                // option3:null,
                // option4:null,
            },
            "消費電力監視" : {
                labeltext:"消費電力監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"警告",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:180,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"消費電流上限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 5.0,
                },
                option3: {
                    labeltext:"消費電流下限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 1.0,
                },
            },
        },
        "NWSW1" : {
            "SNMPTrap" : {
                labeltext:"SNMPTrap",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"情報",
            },
        },
        "NWSW2" : {
            "SNMPTrap" : {
                labeltext:"SNMPTrap",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"情報",
            },
        },
        "NWSW3" : {
            "SNMPTrap" : {
                labeltext:"SNMPTrap",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"情報",
            },
        },
        "NWSW4" : {
            "SNMPTrap" : {
                labeltext:"SNMPTrap",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"情報",
            },
        },
        "温度センサー" : {
            "PING監視" : {
                labeltext:"PING監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:300,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"無応答判定回数",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_KAI,
                    range : {
                        min:3,
                        max:10,
                    },
                    inputvalue: 5,
                },
                // option3:null,
                // option4:null,
            },
        },
        "ノイズソース" : {
            "温度監視" : {
                labeltext:"温度監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"警告",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:3600,
                    },
                    inputvalue: 60,
                },
                option2: {
                    labeltext:"温度上限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_TEMPERTURE,
                    range : {
                        min:0,
                        max:100,
                    },
                    inputvalue: 80,
                },
                option3: {
                    labeltext:"温度下限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_TEMPERTURE,
                    range : {
                        min:0,
                        max:100,
                    },
                    inputvalue: 0,
                },
            },
        },
        "キャビネット内温度" : {
            "温度監視" : {
                labeltext:"温度監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"警告",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:1,
                        max:300,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"温度上限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_TEMPERTURE,
                    range : {
                        min:0,
                        max:100,
                    },
                    inputvalue: 50,
                },
                option3: {
                    labeltext:"温度下限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_TEMPERTURE,
                    range : {
                        min:0,
                        max:100,
                    },
                    inputvalue: 0,
                },
            },
        },
        "キャビネット電源1" : {
            "消費電力監視" : {
                labeltext:"消費電力監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"警告",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:180,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"消費電流上限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 5.0,
                },
                option3: {
                    labeltext:"消費電流下限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 1.0,
                },
            },
        },
        "計測PC本番機" : {
            "死活監視" : {
                labeltext:"死活監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:60,
                        max:300,
                    },
                    inputvalue: 60,
                }
            },
        },
    },
    "SPW" : {
        "スペアナ1" : {
            "PING監視" : {
                labeltext:"PING監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:300,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"無応答判定回数",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_KAI,
                    range : {
                        min:3,
                        max:10,
                    },
                    inputvalue: 5,
                },
                // option3:null,
                // option4:null,
            },
        },
        "スペアナ2" : {
            "PING監視" : {
                labeltext:"PING監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:300,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"無応答判定回数",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_KAI,
                    range : {
                        min:3,
                        max:10,
                    },
                    inputvalue: 5,
                },
                // option3:null,
                // option4:null,
            },
        },
        "GPS1" : {
            "死活監視" : {
                labeltext:"死活監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:300,
                    },
                    inputvalue: 180,
                }
            },
        },
        "GPS2" : {
            "死活監視" : {
                labeltext:"死活監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:300,
                    },
                    inputvalue: 180,
                }
            },
        },
        "DC電源1" : {
            "PING監視" : {
                labeltext:"PING監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:300,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"無応答判定回数",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_KAI,
                    range : {
                        min:3,
                        max:10,
                    },
                    inputvalue: 5,
                },
                // option3:null,
                // option4:null,
            },
            "消費電力監視" : {
                labeltext:"消費電力監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"警告",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:180,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"消費電流上限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 5.0,
                },
                option3: {
                    labeltext:"消費電流下限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 1.0,
                },
            },
        },
        "DC電源2" : {
            "PING監視" : {
                labeltext:"PING監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:300,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"無応答判定回数",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_KAI,
                    range : {
                        min:3,
                        max:10,
                    },
                    inputvalue: 5,
                },
                // option3:null,
                // option4:null,
            },
            "消費電力監視" : {
                labeltext:"消費電力監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"警告",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:180,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"消費電流上限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 5.0,
                },
                option3: {
                    labeltext:"消費電流下限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 1.0,
                },
            },
        },
        "DC電源3" : {
            "PING監視" : {
                labeltext:"PING監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:300,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"無応答判定回数",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_KAI,
                    range : {
                        min:3,
                        max:10,
                    },
                    inputvalue: 5,
                },
                // option3:null,
                // option4:null,
            },
            "消費電力監視" : {
                labeltext:"消費電力監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"警告",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:180,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"消費電流上限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 5.0,
                },
                option3: {
                    labeltext:"消費電流下限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 1.0,
                },
            },
        },
        "DC電源4" : {
            "PING監視" : {
                labeltext:"PING監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:300,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"無応答判定回数",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_KAI,
                    range : {
                        min:3,
                        max:10,
                    },
                    inputvalue: 5,
                },
                // option3:null,
                // option4:null,
            },
            "消費電力監視" : {
                labeltext:"消費電力監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"警告",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:180,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"消費電流上限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 5.0,
                },
                option3: {
                    labeltext:"消費電流下限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 1.0,
                },
            },
        },
        "DC電源5" : {
            "PING監視" : {
                labeltext:"PING監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:300,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"無応答判定回数",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_KAI,
                    range : {
                        min:3,
                        max:10,
                    },
                    inputvalue: 5,
                },
                // option3:null,
                // option4:null,
            },
            "消費電力監視" : {
                labeltext:"消費電力監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"警告",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:180,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"消費電流上限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 5.0,
                },
                option3: {
                    labeltext:"消費電流下限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 1.0,
                },
            },
        },
        "NWSW1" : {
            "SNMPTrap" : {
                labeltext:"SNMPTrap",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"情報",
            },
        },
        "NWSW2" : {
            "SNMPTrap" : {
                labeltext:"SNMPTrap",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"情報",
            },
        },
        "NWSW3" : {
            "SNMPTrap" : {
                labeltext:"SNMPTrap",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"情報",
            },
        },
        "NWSW4" : {
            "SNMPTrap" : {
                labeltext:"SNMPTrap",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"情報",
            },
        },
        "温度センサー" : {
            "PING監視" : {
                labeltext:"PING監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:300,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"無応答判定回数",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_KAI,
                    range : {
                        min:3,
                        max:10,
                    },
                    inputvalue: 5,
                },
                // option3:null,
                // option4:null,
            },
        },
        "ノイズソース" : {
            "温度監視" : {
                labeltext:"温度監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"警告",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:3600,
                    },
                    inputvalue: 60,
                },
                option2: {
                    labeltext:"温度上限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_TEMPERTURE,
                    range : {
                        min:0,
                        max:100,
                    },
                    inputvalue: 80,
                },
                option3: {
                    labeltext:"温度下限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_TEMPERTURE,
                    range : {
                        min:0,
                        max:100,
                    },
                    inputvalue: 0,
                },
            },
        },
        "キャビネット内温度" : {
            "温度監視" : {
                labeltext:"温度監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"警告",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:1,
                        max:300,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"温度上限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_TEMPERTURE,
                    range : {
                        min:0,
                        max:100,
                    },
                    inputvalue: 50,
                },
                option3: {
                    labeltext:"温度下限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_TEMPERTURE,
                    range : {
                        min:0,
                        max:100,
                    },
                    inputvalue: 0,
                },
            },
        },
        "キャビネット電源1" : {
            "消費電力監視" : {
                labeltext:"消費電力監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"警告",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:180,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"消費電流上限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 5.0,
                },
                option3: {
                    labeltext:"消費電流下限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 1.0,
                },
            },
        },
        "計測PC本番機" : {
            "死活監視" : {
                labeltext:"死活監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:60,
                        max:300,
                    },
                    inputvalue: 60,
                }
            },
        },
    },
    "GUNMA" : {
        "スペアナ1" : {
            "PING監視" : {
                labeltext:"PING監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:300,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"無応答判定回数",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_KAI,
                    range : {
                        min:3,
                        max:10,
                    },
                    inputvalue: 5,
                },
                // option3:null,
                // option4:null,
            },
        },
        "スペアナ2" : {
            "PING監視" : {
                labeltext:"PING監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:300,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"無応答判定回数",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_KAI,
                    range : {
                        min:3,
                        max:10,
                    },
                    inputvalue: 5,
                },
                // option3:null,
                // option4:null,
            },
        },
        "GPS1" : {
            "死活監視" : {
                labeltext:"死活監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:300,
                    },
                    inputvalue: 180,
                }
            },
        },
        "GPS2" : {
            "死活監視" : {
                labeltext:"死活監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:300,
                    },
                    inputvalue: 180,
                }
            },
        },
        "DC電源1" : {
            "PING監視" : {
                labeltext:"PING監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:300,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"無応答判定回数",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_KAI,
                    range : {
                        min:3,
                        max:10,
                    },
                    inputvalue: 5,
                },
                // option3:null,
                // option4:null,
            },
            "消費電力監視" : {
                labeltext:"消費電力監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"警告",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:180,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"消費電流上限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 5.0,
                },
                option3: {
                    labeltext:"消費電流下限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 1.0,
                },
            },
        },
        "DC電源2" : {
            "PING監視" : {
                labeltext:"PING監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:300,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"無応答判定回数",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_KAI,
                    range : {
                        min:3,
                        max:10,
                    },
                    inputvalue: 5,
                },
                // option3:null,
                // option4:null,
            },
            "消費電力監視" : {
                labeltext:"消費電力監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"警告",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:180,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"消費電流上限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 5.0,
                },
                option3: {
                    labeltext:"消費電流下限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 1.0,
                },
            },
        },
        "DC電源3" : {
            "PING監視" : {
                labeltext:"PING監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:300,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"無応答判定回数",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_KAI,
                    range : {
                        min:3,
                        max:10,
                    },
                    inputvalue: 5,
                },
                // option3:null,
                // option4:null,
            },
            "消費電力監視" : {
                labeltext:"消費電力監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"警告",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:180,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"消費電流上限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 5.0,
                },
                option3: {
                    labeltext:"消費電流下限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 1.0,
                },
            },
        },
        "DC電源4" : {
            "PING監視" : {
                labeltext:"PING監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:300,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"無応答判定回数",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_KAI,
                    range : {
                        min:3,
                        max:10,
                    },
                    inputvalue: 5,
                },
                // option3:null,
                // option4:null,
            },
            "消費電力監視" : {
                labeltext:"消費電力監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"警告",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:180,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"消費電流上限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 5.0,
                },
                option3: {
                    labeltext:"消費電流下限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 1.0,
                },
            },
        },
        "DC電源5" : {
            "PING監視" : {
                labeltext:"PING監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:300,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"無応答判定回数",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_KAI,
                    range : {
                        min:3,
                        max:10,
                    },
                    inputvalue: 5,
                },
                // option3:null,
                // option4:null,
            },
            "消費電力監視" : {
                labeltext:"消費電力監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"警告",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:180,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"消費電流上限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 5.0,
                },
                option3: {
                    labeltext:"消費電流下限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 1.0,
                },
            },
        },
        "NWSW1" : {
            "SNMPTrap" : {
                labeltext:"SNMPTrap",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"情報",
            },
        },
        "NWSW2" : {
            "SNMPTrap" : {
                labeltext:"SNMPTrap",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"情報",
            },
        },
        "NWSW3" : {
            "SNMPTrap" : {
                labeltext:"SNMPTrap",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"情報",
            },
        },
        "NWSW4" : {
            "SNMPTrap" : {
                labeltext:"SNMPTrap",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"情報",
            },
        },
        "温度センサー" : {
            "PING監視" : {
                labeltext:"PING監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:300,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"無応答判定回数",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_KAI,
                    range : {
                        min:3,
                        max:10,
                    },
                    inputvalue: 5,
                },
                // option3:null,
                // option4:null,
            },
        },
        "ノイズソース" : {
            "温度監視" : {
                labeltext:"温度監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"警告",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:3600,
                    },
                    inputvalue: 60,
                },
                option2: {
                    labeltext:"温度上限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_TEMPERTURE,
                    range : {
                        min:0,
                        max:100,
                    },
                    inputvalue: 80,
                },
                option3: {
                    labeltext:"温度下限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_TEMPERTURE,
                    range : {
                        min:0,
                        max:100,
                    },
                    inputvalue: 0,
                },
            },
        },
        "キャビネット内温度" : {
            "温度監視" : {
                labeltext:"温度監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"警告",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:1,
                        max:300,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"温度上限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_TEMPERTURE,
                    range : {
                        min:0,
                        max:100,
                    },
                    inputvalue: 50,
                },
                option3: {
                    labeltext:"温度下限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_TEMPERTURE,
                    range : {
                        min:0,
                        max:100,
                    },
                    inputvalue: 0,
                },
            },
        },
        "キャビネット電源1" : {
            "消費電力監視" : {
                labeltext:"消費電力監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"警告",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:10,
                        max:180,
                    },
                    inputvalue: 180,
                },
                option2: {
                    labeltext:"消費電流上限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 5.0,
                },
                option3: {
                    labeltext:"消費電流下限値",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_DENRYU,
                    range : {
                        min:0.0,
                        max:6.0,
                    },
                    inputvalue: 1.0,
                },
            },
        },
        "計測PC本番機" : {
            "死活監視" : {
                labeltext:"死活監視",
                isvalidoptions:["ON", "OFF"],
                isvalidvalue:"ON",
                statustypeoptions:["異常", "警告", "情報"],
                statustypevalue:"異常",
                option1:{
                    labeltext:"監視周期",
                    type:SETTING_ITEM_TYPE_INPUT,
                    unit:SETTING_INPUTBOX_UNIT_SECOND,
                    range : {
                        min:60,
                        max:300,
                    },
                    inputvalue: 60,
                }
            },
        },
    },
};