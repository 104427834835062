
import { Flex } from "@aws-amplify/ui-react";

import { 
    KanshiLogWrapper 
} from "../componentsWrapper";

export default function KanshiLogListWrapper(props) {
    return (
        <>
        <KanshiLogWrapper
            display="flex"
            gap="0"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            type="Title"
            logtype="Title"
            icontype="CheckOff"
            // {...getOverrideProps(overrides, "Kanshi/Log500220749")}
        ></KanshiLogWrapper>
        <Flex
            gap="0"
            direction="column"
            width="760px"
            height="578px"
            justifyContent="flex-start"
            alignItems="flex-start"
            overflow="scroll"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            // {...getOverrideProps(overrides, "Frame 862")}
        >
            <KanshiLogWrapper
                date="2023/05/31 20:00:00"
                kyotenName="YSCC"
                hasseiMoto="DC電源 #1"
                logTypeText="消費電力下限"
                type="Default"
                logtype="Contetnts"
                icontype="Circle"
                kakuninStatus="true"
                // {...getOverrideProps(overrides, "Kanshi/Log500220751")}
            ></KanshiLogWrapper>
            <KanshiLogWrapper
                date="2023/05/31 19:55:00"
                kyotenName="YSCC"
                hasseiMoto="DC電源 #1"
                logTypeText="消費電力下限"
                type="Warning"
                logtype="Contetnts"
                icontype="Circle"
                kakuninStatus="true"
                // {...getOverrideProps(overrides, "Kanshi/Log500220752")}
            ></KanshiLogWrapper>
            <KanshiLogWrapper
                date="2023/05/31 19:50:00"
                kyotenName="SPE"
                hasseiMoto="D-3"
                logTypeText="A:時間率判定"
                type="Default"
                logtype="Contetnts"
                icontype="Circle"
                kakuninStatus="true"
                // {...getOverrideProps(overrides, "Kanshi/Log500220753")}
            ></KanshiLogWrapper>
            <KanshiLogWrapper
                date="2023/05/31 19:45:00"
                kyotenName="SPE"
                hasseiMoto="D-3"
                logTypeText="A:時間率判定"
                type="Error"
                logtype="Contetnts"
                icontype="Circle"
                kakuninStatus="true"
                // {...getOverrideProps(overrides, "Kanshi/Log500220754")}
            ></KanshiLogWrapper>
            <KanshiLogWrapper
                date="2023/05/31 19:40:00"
                kyotenName="SPW"
                hasseiMoto="K-2"
                logTypeText="B:最大値判定"
                type="Default"
                logtype="Contetnts"
                icontype="Circle"
                kakuninStatus="true"
                // {...getOverrideProps(overrides, "Kanshi/Log500220755")}
            ></KanshiLogWrapper>
            <KanshiLogWrapper
                date="2023/05/31 19:35:00"
                kyotenName="SPW"
                hasseiMoto="K-2"
                logTypeText="B:最大値判定"
                type="Error"
                logtype="Contetnts"
                icontype="Circle"
                kakuninStatus="true"
                // {...getOverrideProps(overrides, "Kanshi/Log500220756")}
            ></KanshiLogWrapper>
            <KanshiLogWrapper
                date="2023/05/31 19:30:00"
                kyotenName="小夜戸"
                hasseiMoto="DC電源 #2"
                logTypeText="消費電力下限"
                type="Default"
                logtype="Contetnts"
                icontype="Circle"
                kakuninStatus="true"
                // {...getOverrideProps(overrides, "Kanshi/Log500220757")}
            ></KanshiLogWrapper>
            <KanshiLogWrapper
                date="2023/05/31 19:25:00"
                kyotenName="小夜戸"
                hasseiMoto="DC電源 #2"
                logTypeText="消費電力下限"
                type="Warning"
                logtype="Contetnts"
                icontype="Circle"
                kakuninStatus="true"
                // {...getOverrideProps(overrides, "Kanshi/Log500220758")}
            ></KanshiLogWrapper>
            <KanshiLogWrapper
                date="2023/05/31 19:20:00"
                kyotenName="YSCC"
                hasseiMoto="R-1"
                logTypeText="C:合計値判定"
                type="Default"
                logtype="Contetnts"
                icontype="Circle"
                kakuninStatus="true"
                // {...getOverrideProps(overrides, "Kanshi/Log500220759")}
            ></KanshiLogWrapper>
            <KanshiLogWrapper
                date="2023/05/31 19:15:00"
                kyotenName="YSCC"
                hasseiMoto="R-1"
                logTypeText="C:合計値判定"
                type="Error"
                logtype="Contetnts"
                icontype="Circle"
                kakuninStatus="true"
                // {...getOverrideProps(overrides, "Kanshi/Log500220760")}
            ></KanshiLogWrapper>
            <KanshiLogWrapper
                date="2023/05/31 19:10:00"
                kyotenName="SPE"
                hasseiMoto="DC電源 #3"
                logTypeText="消費電力下限"
                type="Default"
                logtype="Contetnts"
                icontype="Circle"
                kakuninStatus="true"
                // {...getOverrideProps(overrides, "Kanshi/Log500220761")}
            ></KanshiLogWrapper>
            <KanshiLogWrapper
                date="2023/05/31 19:05:00"
                kyotenName="SPE"
                hasseiMoto="DC電源 #3"
                logTypeText="消費電力下限"
                type="Warning"
                logtype="Contetnts"
                icontype="Circle"
                kakuninStatus="true"
                // {...getOverrideProps(overrides, "Kanshi/Log500220762")}
            ></KanshiLogWrapper>
            <KanshiLogWrapper
                date="2023/05/31 19:00:00"
                kyotenName="SPW"
                hasseiMoto="合計"
                logTypeText="C:合計値判定"
                type="Default"
                logtype="Contetnts"
                icontype="Circle"
                kakuninStatus="true"
                // {...getOverrideProps(overrides, "Kanshi/Log500220763")}
            ></KanshiLogWrapper>
            <KanshiLogWrapper
                date="2023/05/31 18:55:00"
                kyotenName="SPW"
                hasseiMoto="合計"
                logTypeText="C:合計値判定"
                type="Error"
                logtype="Contetnts"
                icontype="Circle"
                kakuninStatus="true"
                // {...getOverrideProps(overrides, "Kanshi/Log500220764")}
            ></KanshiLogWrapper>
            <KanshiLogWrapper
                date="2023/05/31 18:50:00"
                kyotenName="小夜戸"
                hasseiMoto="DC電源 #4"
                logTypeText="消費電力下限"
                type="Default"
                logtype="Contetnts"
                icontype="Circle"
                kakuninStatus="true"
                // {...getOverrideProps(overrides, "Kanshi/Log500220765")}
            ></KanshiLogWrapper>
            <KanshiLogWrapper
                date="2023/05/31 18:45:00"
                kyotenName="小夜戸"
                hasseiMoto="DC電源 #4"
                logTypeText="消費電力下限"
                type="Warning"
                logtype="Contetnts"
                icontype="Circle"
                kakuninStatus="true"
                // {...getOverrideProps(overrides, "Kanshi/Log500220766")}
            ></KanshiLogWrapper>
        </Flex>
        </>
    );
}