/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Flex, Text, TextField, View } from "@aws-amplify/ui-react";
export default function SetteiInputBox(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        TextField: { top: "25px" },
        PositioningFrame: {},
        "dBm/MHz": {},
        Kai: {},
        A: {},
        Byo: {},
        Hun: {},
        Jikan: {},
        Do: {},
        "Frame 877": {},
        Attention: {},
        SetteiInputBox: {},
      },
      variantValues: { attention: "true", label: "false", unit: "dBm/MHz" },
    },
    {
      overrides: {
        TextField: { top: "25px" },
        PositioningFrame: {},
        "dBm/MHz": {},
        Kai: {},
        A: {},
        Byo: {},
        Hun: {},
        Jikan: {},
        Do: {},
        "Frame 877": {},
        Attention: { display: "none" },
        SetteiInputBox: {},
      },
      variantValues: { attention: "false", label: "false", unit: "dBm/MHz" },
    },
    {
      overrides: {
        TextField: {},
        PositioningFrame: {},
        "dBm/MHz": {},
        Kai: {},
        A: {},
        Byo: {},
        Hun: {},
        Jikan: {},
        Do: {},
        "Frame 877": {},
        Attention: {},
        SetteiInputBox: {},
      },
      variantValues: { attention: "true", label: "true", unit: "dBm/MHz" },
    },
    {
      overrides: {
        TextField: {},
        PositioningFrame: {},
        "dBm/MHz": {},
        Kai: {},
        A: {},
        Byo: {},
        Hun: {},
        Jikan: {},
        Do: {},
        "Frame 877": {},
        Attention: { display: "none" },
        SetteiInputBox: {},
      },
      variantValues: { attention: "false", label: "true", unit: "dBm/MHz" },
    },
    {
      overrides: {
        TextField: { top: "25px" },
        PositioningFrame: {},
        "dBm/MHz": { display: "none" },
        Kai: { display: "block" },
        A: {},
        Byo: {},
        Hun: {},
        Jikan: {},
        Do: {},
        "Frame 877": {},
        Attention: { display: "none" },
        SetteiInputBox: {},
      },
      variantValues: { attention: "false", label: "false", unit: "Kai" },
    },
    {
      overrides: {
        TextField: {},
        PositioningFrame: {},
        "dBm/MHz": { display: "none" },
        Kai: { display: "block" },
        A: {},
        Byo: {},
        Hun: {},
        Jikan: {},
        Do: {},
        "Frame 877": {},
        Attention: { display: "none" },
        SetteiInputBox: {},
      },
      variantValues: { attention: "false", label: "true", unit: "Kai" },
    },
    {
      overrides: {
        TextField: {},
        PositioningFrame: {},
        "dBm/MHz": { display: "none" },
        Kai: { display: "block" },
        A: {},
        Byo: {},
        Hun: {},
        Jikan: {},
        Do: {},
        "Frame 877": {},
        Attention: {},
        SetteiInputBox: {},
      },
      variantValues: { attention: "true", label: "true", unit: "Kai" },
    },
    {
      overrides: {
        TextField: { top: "25px" },
        PositioningFrame: {},
        "dBm/MHz": { display: "none" },
        Kai: { display: "block" },
        A: {},
        Byo: {},
        Hun: {},
        Jikan: {},
        Do: {},
        "Frame 877": {},
        Attention: {},
        SetteiInputBox: {},
      },
      variantValues: { attention: "true", label: "false", unit: "Kai" },
    },
    {
      overrides: {
        TextField: { top: "25px" },
        PositioningFrame: {},
        "dBm/MHz": { display: "none" },
        Kai: {},
        A: {},
        Byo: { display: "block" },
        Hun: {},
        Jikan: {},
        Do: {},
        "Frame 877": {},
        Attention: { display: "none" },
        SetteiInputBox: {},
      },
      variantValues: { attention: "false", label: "false", unit: "Byo" },
    },
    {
      overrides: {
        TextField: {},
        PositioningFrame: {},
        "dBm/MHz": { display: "none" },
        Kai: {},
        A: {},
        Byo: { display: "block" },
        Hun: {},
        Jikan: {},
        Do: {},
        "Frame 877": {},
        Attention: { display: "none" },
        SetteiInputBox: {},
      },
      variantValues: { attention: "false", label: "true", unit: "Byo" },
    },
    {
      overrides: {
        TextField: { top: "25px" },
        PositioningFrame: {},
        "dBm/MHz": { display: "none" },
        Kai: {},
        A: {},
        Byo: { display: "block" },
        Hun: {},
        Jikan: {},
        Do: {},
        "Frame 877": {},
        Attention: {},
        SetteiInputBox: {},
      },
      variantValues: { attention: "true", label: "false", unit: "Byo" },
    },
    {
      overrides: {
        TextField: {},
        PositioningFrame: {},
        "dBm/MHz": { display: "none" },
        Kai: {},
        A: {},
        Byo: { display: "block" },
        Hun: {},
        Jikan: {},
        Do: {},
        "Frame 877": {},
        Attention: {},
        SetteiInputBox: {},
      },
      variantValues: { attention: "true", label: "true", unit: "Byo" },
    },
    {
      overrides: {
        TextField: { top: "25px" },
        PositioningFrame: {},
        "dBm/MHz": { display: "none" },
        Kai: {},
        A: {},
        Byo: {},
        Hun: { display: "block" },
        Jikan: {},
        Do: {},
        "Frame 877": {},
        Attention: { display: "none" },
        SetteiInputBox: {},
      },
      variantValues: { attention: "false", label: "false", unit: "Hun" },
    },
    {
      overrides: {
        TextField: {},
        PositioningFrame: {},
        "dBm/MHz": { display: "none" },
        Kai: {},
        A: {},
        Byo: {},
        Hun: { display: "block" },
        Jikan: {},
        Do: {},
        "Frame 877": {},
        Attention: { display: "none" },
        SetteiInputBox: {},
      },
      variantValues: { attention: "false", label: "true", unit: "Hun" },
    },
    {
      overrides: {
        TextField: { top: "25px" },
        PositioningFrame: {},
        "dBm/MHz": { display: "none" },
        Kai: {},
        A: {},
        Byo: {},
        Hun: { display: "block" },
        Jikan: {},
        Do: {},
        "Frame 877": {},
        Attention: {},
        SetteiInputBox: {},
      },
      variantValues: { attention: "true", label: "false", unit: "Hun" },
    },
    {
      overrides: {
        TextField: {},
        PositioningFrame: {},
        "dBm/MHz": { display: "none" },
        Kai: {},
        A: {},
        Byo: {},
        Hun: { display: "block" },
        Jikan: {},
        Do: {},
        "Frame 877": {},
        Attention: {},
        SetteiInputBox: {},
      },
      variantValues: { attention: "true", label: "true", unit: "Hun" },
    },
    {
      overrides: {
        TextField: { top: "25px" },
        PositioningFrame: {},
        "dBm/MHz": { display: "none" },
        Kai: {},
        A: {},
        Byo: {},
        Hun: {},
        Jikan: { display: "block" },
        Do: {},
        "Frame 877": {},
        Attention: { display: "none" },
        SetteiInputBox: {},
      },
      variantValues: { attention: "false", label: "false", unit: "Jikan" },
    },
    {
      overrides: {
        TextField: {},
        PositioningFrame: {},
        "dBm/MHz": { display: "none" },
        Kai: {},
        A: {},
        Byo: {},
        Hun: {},
        Jikan: { display: "block" },
        Do: {},
        "Frame 877": {},
        Attention: { display: "none" },
        SetteiInputBox: {},
      },
      variantValues: { attention: "false", label: "true", unit: "Jikan" },
    },
    {
      overrides: {
        TextField: { top: "25px" },
        PositioningFrame: {},
        "dBm/MHz": { display: "none" },
        Kai: {},
        A: {},
        Byo: {},
        Hun: {},
        Jikan: { display: "block" },
        Do: {},
        "Frame 877": {},
        Attention: {},
        SetteiInputBox: {},
      },
      variantValues: { attention: "true", label: "false", unit: "Jikan" },
    },
    {
      overrides: {
        TextField: {},
        PositioningFrame: {},
        "dBm/MHz": { display: "none" },
        Kai: {},
        A: {},
        Byo: {},
        Hun: {},
        Jikan: { display: "block" },
        Do: {},
        "Frame 877": {},
        Attention: {},
        SetteiInputBox: {},
      },
      variantValues: { attention: "true", label: "true", unit: "Jikan" },
    },
    {
      overrides: {
        TextField: { top: "25px" },
        PositioningFrame: {},
        "dBm/MHz": { display: "none" },
        Kai: {},
        A: {},
        Byo: {},
        Hun: {},
        Jikan: {},
        Do: { display: "block" },
        "Frame 877": {},
        Attention: { display: "none" },
        SetteiInputBox: {},
      },
      variantValues: { attention: "false", label: "false", unit: "Do" },
    },
    {
      overrides: {
        TextField: {},
        PositioningFrame: {},
        "dBm/MHz": { display: "none" },
        Kai: {},
        A: {},
        Byo: {},
        Hun: {},
        Jikan: {},
        Do: { display: "block" },
        "Frame 877": {},
        Attention: { display: "none" },
        SetteiInputBox: {},
      },
      variantValues: { attention: "false", label: "true", unit: "Do" },
    },
    {
      overrides: {
        TextField: { top: "25px" },
        PositioningFrame: {},
        "dBm/MHz": { display: "none" },
        Kai: {},
        A: {},
        Byo: {},
        Hun: {},
        Jikan: {},
        Do: { display: "block" },
        "Frame 877": {},
        Attention: {},
        SetteiInputBox: {},
      },
      variantValues: { attention: "true", label: "false", unit: "Do" },
    },
    {
      overrides: {
        TextField: {},
        PositioningFrame: {},
        "dBm/MHz": { display: "none" },
        Kai: {},
        A: {},
        Byo: {},
        Hun: {},
        Jikan: {},
        Do: { display: "block" },
        "Frame 877": {},
        Attention: {},
        SetteiInputBox: {},
      },
      variantValues: { attention: "true", label: "true", unit: "Do" },
    },
    {
      overrides: {
        TextField: { top: "25px" },
        PositioningFrame: {},
        "dBm/MHz": { display: "none" },
        Kai: {},
        A: { display: "block" },
        Byo: {},
        Hun: {},
        Jikan: {},
        Do: {},
        "Frame 877": {},
        Attention: {},
        SetteiInputBox: {},
      },
      variantValues: { attention: "true", label: "false", unit: "A" },
    },
    {
      overrides: {
        TextField: { top: "25px" },
        PositioningFrame: {},
        "dBm/MHz": { display: "none" },
        Kai: {},
        A: { display: "block" },
        Byo: {},
        Hun: {},
        Jikan: {},
        Do: {},
        "Frame 877": {},
        Attention: { display: "none" },
        SetteiInputBox: {},
      },
      variantValues: { attention: "false", label: "false", unit: "A" },
    },
    {
      overrides: {
        TextField: {},
        PositioningFrame: {},
        "dBm/MHz": { display: "none" },
        Kai: {},
        A: { display: "block" },
        Byo: {},
        Hun: {},
        Jikan: {},
        Do: {},
        "Frame 877": {},
        Attention: {},
        SetteiInputBox: {},
      },
      variantValues: { attention: "true", label: "true", unit: "A" },
    },
    {
      overrides: {
        TextField: {},
        PositioningFrame: {},
        "dBm/MHz": { display: "none" },
        Kai: {},
        A: { display: "block" },
        Byo: {},
        Hun: {},
        Jikan: {},
        Do: {},
        "Frame 877": {},
        Attention: { display: "none" },
        SetteiInputBox: {},
      },
      variantValues: { attention: "false", label: "true", unit: "A" },
    },
    {
      overrides: {
        TextField: {},
        PositioningFrame: {},
        "dBm/MHz": { display: "none" },
        Kai: {},
        A: {},
        Byo: {},
        Hun: {},
        Jikan: {},
        Do: {},
        "Frame 877": {},
        Attention: { display: "none" },
        SetteiInputBox: {},
      },
      variantValues: { attention: "false", label: "true", unit: "Off" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="8px"
      direction="column"
      width="unset"
      height="87px"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      {...rest}
      {...getOverrideProps(overrides, "SetteiInputBox")}
    >
      <Flex
        gap="8px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-end"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 877")}
      >
        <View
          width="120px"
          height="58px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "PositioningFrame")}
        >
          <TextField
            width="120px"
            height="unset"
            position="absolute"
            top="0px"
            left="0px"
            label="Label"
            placeholder="Placeholder"
            size="small"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            {...getOverrideProps(overrides, "TextField")}
          ></TextField>
        </View>
        <Text
          fontFamily="Noto Sans"
          fontSize="12px"
          fontWeight="400"
          color="rgba(100,100,100,1)"
          lineHeight="12px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.45px"
          width="64px"
          height="24px"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="dBm/MHz"
          {...getOverrideProps(overrides, "dBm/MHz")}
        ></Text>
        <Text
          fontFamily="Noto Sans"
          fontSize="12px"
          fontWeight="400"
          color="rgba(100,100,100,1)"
          lineHeight="12px"
          textAlign="left"
          display="none"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.45px"
          width="24px"
          height="24px"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="回"
          {...getOverrideProps(overrides, "Kai")}
        ></Text>
        <Text
          fontFamily="Noto Sans"
          fontSize="12px"
          fontWeight="400"
          color="rgba(100,100,100,1)"
          lineHeight="12px"
          textAlign="left"
          display="none"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.45px"
          width="24px"
          height="24px"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="A"
          {...getOverrideProps(overrides, "A")}
        ></Text>
        <Text
          fontFamily="Noto Sans"
          fontSize="12px"
          fontWeight="400"
          color="rgba(100,100,100,1)"
          lineHeight="12px"
          textAlign="left"
          display="none"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.45px"
          width="24px"
          height="24px"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="秒"
          {...getOverrideProps(overrides, "Byo")}
        ></Text>
        <Text
          fontFamily="Noto Sans"
          fontSize="12px"
          fontWeight="400"
          color="rgba(100,100,100,1)"
          lineHeight="12px"
          textAlign="left"
          display="none"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.45px"
          width="24px"
          height="24px"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="分"
          {...getOverrideProps(overrides, "Hun")}
        ></Text>
        <Text
          fontFamily="Noto Sans"
          fontSize="12px"
          fontWeight="400"
          color="rgba(100,100,100,1)"
          lineHeight="12px"
          textAlign="left"
          display="none"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.45px"
          width="40px"
          height="24px"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="時間"
          {...getOverrideProps(overrides, "Jikan")}
        ></Text>
        <Text
          fontFamily="Noto Sans"
          fontSize="12px"
          fontWeight="400"
          color="rgba(100,100,100,1)"
          lineHeight="12px"
          textAlign="left"
          display="none"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.45px"
          width="24px"
          height="24px"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="℃"
          {...getOverrideProps(overrides, "Do")}
        ></Text>
      </Flex>
      <Text
        fontFamily="Noto Sans"
        fontSize="12px"
        fontWeight="400"
        color="rgba(100,100,100,1)"
        lineHeight="12px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        letterSpacing="0.45px"
        width="unset"
        height="21px"
        gap="unset"
        alignItems="unset"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="範囲：10-3600"
        {...getOverrideProps(overrides, "Attention")}
      ></Text>
    </Flex>
  );
}
