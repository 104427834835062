import { Flex } from "@aws-amplify/ui-react";
import {
    KanshiAlertCard,
} from '../ui-components'
  
export default function KanshiAllStateWrapper(props) {


    return (
        <>
        <Flex
            gap="40px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="80px 40px 80px 40px"
            backgroundColor="rgba(245,245,245,1)"
            // // {...getOverrideProps(overrides, "Frame 836")}
        >
            <Flex
            gap="40px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            >
                <KanshiAlertCard
                    display="flex"
                    gap="40px"
                    direction="column"
                    width="476px"
                    height="unset"
                    justifyContent="center"
                    alignItems="center"
                    shrink="0"
                    position="relative"
                    borderRadius="8px"
                    padding="32px 24px 32px 24px"
                    backgroundColor="rgba(255,255,255,1)"
                    overrides={{
                        text: { children: "YSCC" },
                        "Kanshi/Alert35293049": { type: props.state.YSCC.waveState },
                        "Kanshi/Alert35293050": { type: props.state.YSCC.kikiState },
                    }}
                ></KanshiAlertCard>
                <KanshiAlertCard
                    display="flex"
                    gap="40px"
                    direction="column"
                    width="476px"
                    height="unset"
                    justifyContent="center"
                    alignItems="center"
                    shrink="0"
                    position="relative"
                    borderRadius="8px"
                    padding="32px 24px 32px 24px"
                    backgroundColor="rgba(255,255,255,1)"
                    overrides={{
                        text: { children: "SPE" },
                        "Kanshi/Alert35293049": { type: props.state.SPE.waveState },
                        "Kanshi/Alert35293050": { type: props.state.SPE.kikiState },
                    }}
                ></KanshiAlertCard>
            </Flex>
            <Flex
                gap="40px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                >
                <KanshiAlertCard
                    display="flex"
                    gap="40px"
                    direction="column"
                    width="476px"
                    height="unset"
                    justifyContent="center"
                    alignItems="center"
                    shrink="0"
                    position="relative"
                    borderRadius="8px"
                    padding="32px 24px 32px 24px"
                    backgroundColor="rgba(255,255,255,1)"
                    overrides={{
                        text: { children: "SPW" },
                        "Kanshi/Alert35293049": { type: props.state.SPW.waveState },
                        "Kanshi/Alert35293050": { type: props.state.SPW.kikiState },
                    }}
                ></KanshiAlertCard>
                <KanshiAlertCard
                    display="flex"
                    gap="40px"
                    direction="column"
                    width="476px"
                    height="unset"
                    justifyContent="center"
                    alignItems="center"
                    shrink="0"
                    position="relative"
                    borderRadius="8px"
                    padding="32px 24px 32px 24px"
                    backgroundColor="rgba(255,255,255,1)"
                    overrides={{
                        text: { children: "小夜戸" },
                        "Kanshi/Alert35293049": { type: props.state.SAYADO.waveState },
                        "Kanshi/Alert35293050": { type: props.state.SAYADO.kikiState },
                    }}
                ></KanshiAlertCard>
            </Flex>
        </Flex>
        </>
    )
}