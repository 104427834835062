import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { userManager,redirectToLogin,updateUserAwsCredentials,checkTokenExpiration} from './auth';
import Callback from './callback';

const AuthProvider = ({ children }) => {
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const checkUser = async () => {
      
      const user = await userManager.getUser();
      if (!user) {
        // console.log('checkUser: NG')
        redirectToLogin();
      } else {
        // console.log('checkUser: OK')
        // console.log("process.env.REACT_APP_TEST: ",process.env.REACT_APP_TEST)
        checkTokenExpiration();
        updateUserAwsCredentials(user);
        setIsLoggedIn(true);
      }
    };
    // console.log('AuthProvider location.pathname', location.pathname);
    if (location.pathname !== '/callback') {
        checkUser();
    } 
  }, [location.pathname]);

  if(location.pathname === '/callback') {
    return <Callback />;
  } else {
    return isLoggedIn ? children : <></>;
  }
};

export default AuthProvider;
