import { useContext } from "react";
import { UserContext } from '../UserContext';

import {
  DatakanriListFile
} from '../ui-components'

function clickTest(message) {
  alert(message)
}

export default function DatakanriListFileWrapper(props) {
  const { userInfo } = useContext(UserContext);


  return (
    <DatakanriListFile 
      width={"100%"}
      minWidth={"1355px"}
      // onClick={props.onClick}
      // onClick={()=>clickTest("test")}
      overrides={{ 
        CheckboxField: {},
        // Spacer480814277: {},
        // Myicons: { display: "block" },
        text480814280: {
          // fontWeight: "500",
          children: props.item.name,
        },
        // FileName: {},
        // Spacer480814281: {},
        text480814283: { 
          // fontWeight: "500",
          children: props.item.lastModified,
        },
        // Date: {},
        // Spacer480814284: {},
        text480814286: {
          // fontWeight: "500",
          children: props.item.fileType,
        },
        // FileType: {},
        // Spacer480814287: {},
        text480814289: {
          // fontWeight: "500",
          children: props.item.filesize,
        },
        // Spacer480814290: {
        //   width: "40px",
        //   height: "40px",
        //   top: "-13.46%",
        //   bottom: "-40.38%",
        //   left: "58.44%",
        //   right: "16.56%",
        // },
        // FileSize: {},
        // Spacer480814291: {},
        "Common/Badge/Icon480814293": {
          // display: isAdminUser ? "flex" : "none",
          style: {
            visibility : userInfo.isAdminOrg && userInfo.isAdminUser ? "visible" : "hidden",
          },
          // onClick: () => clickTest("Test trash"),
          overrides: {
            Myicons: {
              type: "trash"
            }
          }
        },
        "Rectangle 25": { 
          // display: "block" 
          style: {
            visibility : userInfo.isAdminOrg && userInfo.isAdminUser ? "visible" : "hidden",
          },
        },
        "Common/Badge/Icon480814295": { 
          // display: "flex", 
          onClick: props.onDownloadClick,
          overrides: {
            Myicons: {type: "download"}
          }
        },
        // icons: {},
        // Contents: {},
        // "Common/Divider": {},
        // DatakanriListFile: {},
      }}
      type="File"
      check='off'
      // // overrides={{
      // //   Myicons36793624: {},
      // //   Myicons36793627: {},
      // //   text36793628: {children:props.item.name},
      // //   "Frame 41": {},
      // //   text36793629: {children:props.item.lastModified},
      // //   text36793630: {children:props.item.fileType},
      // //   text36793631: {children:props.item.filesize},
      // //   "Common/Badge/Icon36793633": {
      // //     onClick: props.onDownloadClick,
      // //     overrides: {
      // //       Myicons: {type: "download"}
      // //     }
      // //   },
      // //   "Rectangle 25": {},
      // //   "Common/Badge/Icon36793635": {
      // //     onClick: () => clickTest("Test trash"),
      // //     overrides: {
      // //       Myicons: {
      // //         type: "trash"
      // //       }
      // //     }
      // //   },
      // //   "Frame 42": {},
      // //   "Frame 43": {},
      // //   "Frame 322": {},
      // //   "Common/Divider-02": {},
      // //   DatakanriListFile: {},
      // }}
    ></DatakanriListFile>
  );
}