import { useState } from 'react';

import { SetteiLocation } from '../ui-components';

export default function KanshiSetteiLocationWrapper(props) {
    
    return (
        <SetteiLocation
            display="flex"
            gap="8px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            situation={props.situation}
            overrides={{
                "Settei/Tab35993728": { 
                    onClick: () => props.onClickLocation("YSCC"),
                    overrides: {
                        text: {
                            children: "YSCC",
                        }
                    }              
                },
                "Settei/Tab35993730": {
                    onClick: () => props.onClickLocation("SPE"),
                    overrides: {
                        text: {
                            children: "SPE",
                        }
                    }
                },
                "Settei/Tab35993732": {
                    onClick: () => props.onClickLocation("SPW"),
                    overrides: {
                        text: {
                            children: "SPW",
                        }
                    }
                },
                "Settei/Tab35993734": {
                    onClick: () => props.onClickLocation("GUNMA"),
                    overrides: {
                        text: {
                            children: "小夜戸",
                        }
                    }
                },
            }}        
        ></SetteiLocation>
    );
}