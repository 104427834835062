import { useState } from 'react';

import {
    getOverrideProps,
    getOverridesFromVariants,
    mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";

import { CommonBtnBlue } from '../ui-components';

export default function CommonBtnBlueWrapper(props) {
    const { overrides: overridesProp, ...rest } = props;
    const variants = [];
    const overrides = mergeVariantsAndOverrides(
        getOverridesFromVariants(variants, props),
        overridesProp || {}
    );
    const [situation, setSituation] = useState("default");
    
    function onMouseEventEnter(){
        setSituation("hover")
    }

    function onMouseEventLeave(){
        setSituation("default")
    }

    return (
        <CommonBtnBlue
            onClick={props.onClick}
            onMouseEnter={onMouseEventEnter}
            onMouseLeave={onMouseEventLeave}
            situation={situation}
            overrides={{
                text: {
                    children: props.children,
                }
            }}
            {...getOverrideProps(overrides, "CommonBtnBlue")}
        >
        </CommonBtnBlue>
    );
}