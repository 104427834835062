/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_user_files_s3_bucket": "data-670634105186103238-demo",
    "aws_user_files_s3_bucket_region": "ap-northeast-1",
    "aws_content_delivery_bucket": "5gcloudweb-20230330144133-hostingbucket-demo",
    "aws_content_delivery_bucket_region": "ap-northeast-1",
    "aws_content_delivery_url": "https://djif5exyppxsm.cloudfront.net"
};


export default awsmobile;
