
import {
    KanshiLog
} from "../ui-components";

export default function KanshiLogWrapper(props) {
    return (
        <KanshiLog
            type={props.type}
            logType={props.logtype}
            iconType={props.icontype}
            overrides={ {
                CheckboxField491717853: { defaultChecked: false},
                Spacer491717854: {
                    // children: "AAA", タイトル行　日付
                },
                text491717857: {
                    // children: "BBB", タイトル行　拠点名
                },
                text491717859: {
                    children: "発生元",  // タイトル行　発生元
                },
                text491717861: {
                    // children: "DDD", タイトル行　ログ種別
                },
                text491717865: {
                    // children: "EEE", タイトル行　確認状態
                },
                CheckboxField491717868: {defaultChecked: false},
                text491717870: {
                    children: props.date,
                },
                text491717872: {
                    children: props.kyotenName,
                },
                text491717874: {
                    children: props.hasseiMoto,
                },
                text491717876: {
                    children: props.logTypeText,
                },
                "Kanshi/Btn/kakunin": {
                    // status:props.kakuninStatus,
                    // status:"SelectW",
                    check:props.kakuninStatus,
                    type:props.type,
                },
                "Common/Divider": {},
            }}
        >
        </KanshiLog>
    );
}