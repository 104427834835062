import { useState } from "react";
import { Flex, View, Text} from "@aws-amplify/ui-react";

import {
    CommonTitle,
    CommonBtnText,
    CommonDivider,
    Spacer,
} from "../ui-components";

// wrapper作成予定
import {
    SetteiInputBox,
    SetteiItem,
} from "../ui-components";

import {
    CommonBtnBlueWrapper,
    CommonBtnRoundWrapper,
    CommonBtnTextWrapper,
    KanshiSetteiLocationWrapper,
    KanshiSetteiKeisokuWrapper,
    KanshiSetteiMachineWrapper,
 } from "../componentsWrapper";

export default function KanshiSetteiKyotenWrapper(props) {

    const [tabKeisokuSituation, setTabKeisokuSituation] = useState("Select");
    const [tabMachineSituation, setTabMachineSituation] = useState("Default");
    const [locationSituation, setLocationSituation] = useState("YSCC");

    const onClickTab = (tabName) => {
        tabName === "keisoku" ? setTabKeisokuSituation("Select") : setTabKeisokuSituation("Default");
        tabName === "machine" ? setTabMachineSituation("Select") : setTabMachineSituation("Default");
    }

    function onTabMouseEventEnter(tabName){
        switch (tabName) {
            case "keisoku":
                if (tabKeisokuSituation === "Default") {
                    setTabKeisokuSituation("Hover");
                }
                break;
            case "machine":
                if (tabMachineSituation === "Default") {
                    setTabMachineSituation("Hover");
                }
                break;
            default:
                break;
        }
    }

    function onTabMouseEventLeave(tabName){
        switch (tabName) {
            case "keisoku":
                if (tabKeisokuSituation === "Hover") {
                    setTabKeisokuSituation("Default");
                }
                break;
            case "machine":
                if (tabMachineSituation === "Hover") {
                    setTabMachineSituation("Default");
                }
                break;
            default:
                break;
        }
    }

    const onClickLocation = (locationName) => {
        setLocationSituation(locationName);
    }

    const onClickSaveBtn = () => {
        // console.log("onClickSaveBtn");
    }

    return (
        <>
        <Flex
            gap="24px"
            direction="column"
            marginLeft={"24px"}
            marginRight={"24px"}
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            position="relative"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(245,245,245,1)"
            // {...rest}
            // {...getOverrideProps(overrides, "LayoutSetteiDenpakeisoku")}
            >
                <CommonTitle
                width="240px"
                height="18px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                size="large"
                overrides={{
                    text: {
                      children: "監視設定",
                    }
                }}
                // {...getOverrideProps(overrides, "Common/Title")}
                ></CommonTitle>
                <Flex
                gap="0"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                // {...getOverrideProps(overrides, "Settei")}
                >
                    <Flex
                        gap="0"
                        direction="row"
                        width="1864px"
                        height="unset"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        // {...getOverrideProps(overrides, "Frame 895")}
                    >
                        <KanshiSetteiLocationWrapper
                        situation={locationSituation}
                        onClickLocation={onClickLocation}
                        // {...getOverrideProps(overrides, "Settei/Location")}
                        ></KanshiSetteiLocationWrapper>
                        <CommonBtnRoundWrapper
                        onClick={() => props.onClickLabelBtn()}
                        color="White"
                        children="表示ラベル設定"
                        // {...getOverrideProps(overrides, "Common/Btn/Round505155607")}
                        ></CommonBtnRoundWrapper>
                    </Flex>
                    <Flex
                        gap="578px"
                        direction="row"
                        width="1864px"
                        height="unset"
                        justifyContent="space-between"
                        alignItems="center"
                        shrink="0"
                        position="relative"
                        padding="40px 40px 40px 40px"
                        backgroundColor="rgba(255,255,255,1)"
                        // {...getOverrideProps(overrides, "Frame 284")}
                    >
                        <Flex
                        gap="46px"
                        direction="row"
                        width="unset"
                        height="unset"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        // {...getOverrideProps(overrides, "Frame 377")}
                        >
                            <CommonBtnTextWrapper
                                // onClick=onClickTab("keisoku")}
                                // onMouseEventEnter={onTabMouseEventEnter("keisoku")}
                                // onMouseEventLeave={onTabMouseEventLeave("keisoku")}
                                onClick={() => onClickTab("keisoku")}
                                onMouseEnter={() => onTabMouseEventEnter("keisoku")}
                                onMouseLeave={() => onTabMouseEventLeave("keisoku")}
                                type={tabKeisokuSituation}
                                size="Large"
                                children="電波干渉監視設定"
                                // {...getOverrideProps(overrides, "Common/Btn/Text505155610")}
                            ></CommonBtnTextWrapper>
                            <CommonBtnTextWrapper
                                // onClickTab={onClickTab("machine")}
                                // onMouseEventEnter={onTabMouseEventEnter("machine")}
                                // onMouseEventLeave={onTabMouseEventLeave("machine")}
                                onClick={() => onClickTab("machine")}
                                onMouseEnter={() => onTabMouseEventEnter("machine")}
                                onMouseLeave={() => onTabMouseEventLeave("machine")}
                                type={tabMachineSituation}
                                size="Large"
                                children="機器監視設定"
                                // {...getOverrideProps(overrides, "Common/Btn/Text505155611")}
                            ></CommonBtnTextWrapper>
                        </Flex>
                        <Flex
                        gap="24px"
                        direction="row"
                        width="unset"
                        height="unset"
                        justifyContent="flex-start"
                        alignItems="center"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        // {...getOverrideProps(overrides, "Button")}
                        >
                            <Flex
                                gap="16px"
                                direction="row"
                                width="unset"
                                height="unset"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                shrink="0"
                                position="relative"
                                padding="0px 0px 0px 0px"
                                // {...getOverrideProps(overrides, "Frame 884")}
                            >
                                <CommonBtnRoundWrapper
                                color="Gray"
                                children="設定ファイルDL"
                                // {...getOverrideProps(overrides, "Common/Btn/Round505155614")}
                                ></CommonBtnRoundWrapper>
                                <CommonBtnRoundWrapper
                                color="Gray"
                                children="設定ファイル読込"
                                // {...getOverrideProps(overrides, "Common/Btn/Round505155615")}
                                ></CommonBtnRoundWrapper>
                            </Flex>
                            <View
                                width="1px"
                                height="unset"
                                display="block"
                                gap="unset"
                                alignItems="unset"
                                justifyContent="unset"
                                shrink="0"
                                alignSelf="stretch"
                                position="relative"
                                padding="0px 0px 0px 0px"
                                backgroundColor="rgba(170,170,170,1)"
                                // {...getOverrideProps(overrides, "Rectangle")}
                            ></View>
                            <CommonBtnBlueWrapper
                                onClick={() => onClickSaveBtn()}
                                children="設定を変更"
                                // {...getOverrideProps(overrides, "Common/Btn/Blue")}
                            ></CommonBtnBlueWrapper>
                        </Flex>
                    </Flex>
                {tabKeisokuSituation === "Select" && 
                    <KanshiSetteiKeisokuWrapper
                    location={locationSituation}
                    ></KanshiSetteiKeisokuWrapper>
                }
                {tabMachineSituation === "Select" &&
                    <KanshiSetteiMachineWrapper
                    location={locationSituation}
                    ></KanshiSetteiMachineWrapper>
                }
            </Flex>
        </Flex>
        </>
    );
}