/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import CommonTitle from "./CommonTitle";
import SetteiLocation from "./SetteiLocation";
import CommonBtnRound from "./CommonBtnRound";
import { Flex, SelectField, Text, View } from "@aws-amplify/ui-react";
import CommonBtnText from "./CommonBtnText";
import CommonBtnBlue from "./CommonBtnBlue";
import Spacer from "./Spacer";
import SetteiInputBox from "./SetteiInputBox";
import SetteiItem2 from "./SetteiItem2";
import SetteiItem from "./SetteiItem";
import CommonDivider from "./CommonDivider";
export default function LayoutSetteiDenpakeisoku(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="24px"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "LayoutSetteiDenpakeisoku")}
    >
      <CommonTitle
        width="240px"
        height="18px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        size="large"
        {...getOverrideProps(overrides, "Common/Title")}
      ></CommonTitle>
      <Flex
        gap="0"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        overflow="hidden"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Settei")}
      >
        <Flex
          gap="0"
          direction="row"
          width="1864px"
          height="unset"
          justifyContent="space-between"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 895")}
        >
          <SetteiLocation
            display="flex"
            gap="8px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            situation="YSCC"
            {...getOverrideProps(overrides, "Settei/Location")}
          ></SetteiLocation>
          <CommonBtnRound
            display="flex"
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            borderRadius="15px"
            padding="8px 16px 8px 16px"
            color="White"
            {...getOverrideProps(overrides, "Common/Btn/Round505155607")}
          ></CommonBtnRound>
        </Flex>
        <Flex
          gap="578px"
          direction="row"
          width="1864px"
          height="unset"
          justifyContent="space-between"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="40px 40px 40px 40px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "Frame 284")}
        >
          <Flex
            gap="46px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 377")}
          >
            <CommonBtnText
              display="flex"
              gap="0"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              type="Select"
              size="Large"
              {...getOverrideProps(overrides, "Common/Btn/Text505155610")}
            ></CommonBtnText>
            <CommonBtnText
              display="flex"
              gap="0"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              type="Default"
              size="Large"
              {...getOverrideProps(overrides, "Common/Btn/Text505155611")}
            ></CommonBtnText>
          </Flex>
          <Flex
            gap="24px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Button")}
          >
            <Flex
              gap="16px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 884")}
            >
              <CommonBtnRound
                display="flex"
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                position="relative"
                borderRadius="15px"
                padding="8px 16px 8px 16px"
                backgroundColor="rgba(245,245,245,1)"
                color="Gray"
                {...getOverrideProps(overrides, "Common/Btn/Round505155614")}
              ></CommonBtnRound>
              <CommonBtnRound
                display="flex"
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                position="relative"
                borderRadius="15px"
                padding="8px 16px 8px 16px"
                backgroundColor="rgba(245,245,245,1)"
                color="Gray"
                {...getOverrideProps(overrides, "Common/Btn/Round505155615")}
              ></CommonBtnRound>
            </Flex>
            <View
              width="1px"
              height="unset"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              backgroundColor="rgba(170,170,170,1)"
              {...getOverrideProps(overrides, "Rectangle")}
            ></View>
            <CommonBtnBlue
              display="flex"
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              borderRadius="8px"
              padding="10px 24px 10px 24px"
              backgroundColor="rgba(0,153,255,1)"
              situation="default"
              {...getOverrideProps(overrides, "Common/Btn/Blue")}
            ></CommonBtnBlue>
          </Flex>
        </Flex>
        <Flex
          gap="16px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 40px 0px 40px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "Contents")}
        >
          <Flex
            gap="40px"
            direction="column"
            width="unset"
            height="624px"
            justifyContent="flex-start"
            alignItems="flex-start"
            overflow="hidden"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Denpakansyo")}
          >
            <Flex
              gap="16px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Denpa")}
            >
              <Text
                fontFamily="Noto Sans"
                fontSize="16px"
                fontWeight="700"
                color="rgba(53,56,59,1)"
                lineHeight="16px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.65px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="電波計測設定"
                {...getOverrideProps(overrides, "text529130763")}
              ></Text>
              <Flex
                gap="0"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 1px 0px 1px"
                {...getOverrideProps(overrides, "Frame 882")}
              >
                <View
                  width="152px"
                  height="87px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Frame529130765")}
                >
                  <SelectField
                    width="152px"
                    height="unset"
                    label="RBW"
                    placeholder="10kHz"
                    // position="absolute"
                    // top="0px"
                    // left="0px"
                    size="small"
                    isDisabled={false}
                    labelHidden={false}
                    variation="default"
                    fontSize="0.875rem"
                    gap={"0.25rem"}
                    overrides={{
                      "label": {
                        size: "small",
                      }
                    }}
                    {...getOverrideProps(overrides, "SelectField")}
                  ></SelectField>
                </View>
                <Spacer
                  display="flex"
                  gap="10px"
                  direction="column"
                  width="48px"
                  height="48px"
                  justifyContent="center"
                  alignItems="center"
                  overflow="hidden"
                  shrink="0"
                  position="relative"
                  padding="10px 10px 10px 10px"
                  {...getOverrideProps(overrides, "Spacer529130767")}
                ></Spacer>
                <SetteiInputBox
                  display="flex"
                  gap="8px"
                  direction="column"
                  width="unset"
                  height="86px"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  attention={true}
                  label={true}
                  unit="Hun"
                  {...getOverrideProps(overrides, "Settei/InputBox529130768")}
                ></SetteiInputBox>
                <Spacer
                  display="flex"
                  gap="10px"
                  direction="column"
                  width="48px"
                  height="48px"
                  justifyContent="center"
                  alignItems="center"
                  overflow="hidden"
                  shrink="0"
                  position="relative"
                  padding="10px 10px 10px 10px"
                  {...getOverrideProps(overrides, "Spacer529130769")}
                ></Spacer>
                <SetteiInputBox
                  display="flex"
                  gap="8px"
                  direction="column"
                  width="unset"
                  height="86px"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  attention={true}
                  label={true}
                  unit="Jikan"
                  {...getOverrideProps(overrides, "Settei/InputBox529130770")}
                ></SetteiInputBox>
              </Flex>
              <Flex
                gap="0"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame529130771")}
              >
                <SetteiItem2
                  display="flex"
                  gap="0"
                  direction="column"
                  width="984px"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Settei/Item2529130772")}
                ></SetteiItem2>
                <SetteiItem2
                  display="flex"
                  gap="0"
                  direction="column"
                  width="984px"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Settei/Item2529130773")}
                ></SetteiItem2>
              </Flex>
            </Flex>
            <Flex
              gap="16px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Kansyoha")}
            >
              <Text
                fontFamily="Noto Sans"
                fontSize="16px"
                fontWeight="700"
                color="rgba(53,56,59,1)"
                lineHeight="16px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.65px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="干渉波アラーム監視設定"
                {...getOverrideProps(overrides, "text529130775")}
              ></Text>
              <Flex
                gap="0"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 881")}
              >
                <Flex
                  gap="48px"
                  direction="row"
                  width="unset"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="16px 32px 16px 32px"
                  backgroundColor="rgba(245,245,245,1)"
                  {...getOverrideProps(overrides, "Title")}
                >
                  <Text
                    fontFamily="Noto Sans"
                    fontSize="16px"
                    fontWeight="400"
                    color="rgba(53,56,59,1)"
                    lineHeight="16px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    letterSpacing="0.65px"
                    width="96px"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="対象周波数"
                    {...getOverrideProps(overrides, "text529130778")}
                  ></Text>
                  <Text
                    fontFamily="Noto Sans"
                    fontSize="16px"
                    fontWeight="400"
                    color="rgba(53,56,59,1)"
                    lineHeight="16px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    letterSpacing="0.65px"
                    width="160px"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="監視項目"
                    {...getOverrideProps(overrides, "text529130779")}
                  ></Text>
                  <Text
                    fontFamily="Noto Sans"
                    fontSize="16px"
                    fontWeight="400"
                    color="rgba(53,56,59,1)"
                    lineHeight="16px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    letterSpacing="0.65px"
                    width="120px"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="有効／無効"
                    {...getOverrideProps(overrides, "text529130780")}
                  ></Text>
                  <Text
                    fontFamily="Noto Sans"
                    fontSize="16px"
                    fontWeight="400"
                    color="rgba(53,56,59,1)"
                    lineHeight="16px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    letterSpacing="0.65px"
                    width="120px"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="状態種別"
                    {...getOverrideProps(overrides, "text529130781")}
                  ></Text>
                  <Text
                    fontFamily="Noto Sans"
                    fontSize="16px"
                    fontWeight="400"
                    color="rgba(53,56,59,1)"
                    lineHeight="16px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    letterSpacing="0.65px"
                    width="120px"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="閾値"
                    {...getOverrideProps(overrides, "text529130782")}
                  ></Text>
                </Flex>
                <Flex
                  gap="0"
                  direction="column"
                  width="unset"
                  height="500px"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Frame529130783")}
                >
                  <Flex
                    gap="0"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    {...getOverrideProps(overrides, "3400-3440")}
                  >
                    <Flex
                      gap="0"
                      direction="row"
                      width="unset"
                      height="unset"
                      justifyContent="center"
                      alignItems="flex-start"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="34px 32px 34px 32px"
                      backgroundColor="rgba(255,255,255,1)"
                      {...getOverrideProps(overrides, "Taisyo529130785")}
                    >
                      <Text
                        fontFamily="Noto Sans"
                        fontSize="14px"
                        fontWeight="700"
                        color="rgba(53,56,59,1)"
                        lineHeight="22.399999618530273px"
                        textAlign="left"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        letterSpacing="0.55px"
                        width="88px"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children="3400-3440"
                        {...getOverrideProps(overrides, "text529130786")}
                      ></Text>
                    </Flex>
                    <Flex
                      gap="0"
                      direction="column"
                      width="unset"
                      height="unset"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      {...getOverrideProps(overrides, "Frame 880529130787")}
                    >
                      <SetteiItem
                        display="flex"
                        gap="0"
                        direction="column"
                        width="unset"
                        height="unset"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        {...getOverrideProps(overrides, "Settei/Item529130788")}
                      ></SetteiItem>
                      <SetteiItem
                        display="flex"
                        gap="0"
                        direction="column"
                        width="unset"
                        height="unset"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        {...getOverrideProps(overrides, "Settei/Item529130789")}
                      ></SetteiItem>
                      <SetteiItem
                        display="flex"
                        gap="0"
                        direction="column"
                        width="unset"
                        height="unset"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        {...getOverrideProps(overrides, "Settei/Item529130790")}
                      ></SetteiItem>
                    </Flex>
                  </Flex>
                  <CommonDivider
                    display="flex"
                    gap="0"
                    direction="row"
                    width="unset"
                    height="1px"
                    justifyContent="center"
                    alignItems="center"
                    overflow="hidden"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    {...getOverrideProps(overrides, "Common/Divider529130791")}
                  ></CommonDivider>
                  <Flex
                    gap="0"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    {...getOverrideProps(overrides, "3480-3520")}
                  >
                    <Flex
                      gap="0"
                      direction="row"
                      width="unset"
                      height="unset"
                      justifyContent="center"
                      alignItems="flex-start"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="34px 32px 34px 32px"
                      backgroundColor="rgba(255,255,255,1)"
                      {...getOverrideProps(overrides, "Taisyo529130793")}
                    >
                      <Text
                        fontFamily="Noto Sans"
                        fontSize="14px"
                        fontWeight="700"
                        color="rgba(53,56,59,1)"
                        lineHeight="22.399999618530273px"
                        textAlign="left"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        letterSpacing="0.55px"
                        width="88px"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children="3480-3520"
                        {...getOverrideProps(overrides, "text529130794")}
                      ></Text>
                    </Flex>
                    <Flex
                      gap="0"
                      direction="column"
                      width="unset"
                      height="unset"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      {...getOverrideProps(overrides, "Frame 880529130795")}
                    >
                      <SetteiItem
                        display="flex"
                        gap="0"
                        direction="column"
                        width="unset"
                        height="unset"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        {...getOverrideProps(overrides, "Settei/Item529130796")}
                      ></SetteiItem>
                      <SetteiItem
                        display="flex"
                        gap="0"
                        direction="column"
                        width="unset"
                        height="unset"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        {...getOverrideProps(overrides, "Settei/Item529130797")}
                      ></SetteiItem>
                      <SetteiItem
                        display="flex"
                        gap="0"
                        direction="column"
                        width="unset"
                        height="unset"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        {...getOverrideProps(overrides, "Settei/Item529130798")}
                      ></SetteiItem>
                    </Flex>
                  </Flex>
                  <CommonDivider
                    display="flex"
                    gap="0"
                    direction="row"
                    width="unset"
                    height="1px"
                    justifyContent="center"
                    alignItems="center"
                    overflow="hidden"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    {...getOverrideProps(overrides, "Common/Divider529130799")}
                  ></CommonDivider>
                  <Flex
                    gap="0"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    {...getOverrideProps(overrides, "3520-3560")}
                  >
                    <Flex
                      gap="0"
                      direction="row"
                      width="unset"
                      height="unset"
                      justifyContent="center"
                      alignItems="flex-start"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="34px 32px 34px 32px"
                      backgroundColor="rgba(255,255,255,1)"
                      {...getOverrideProps(overrides, "Taisyo529130801")}
                    >
                      <Text
                        fontFamily="Noto Sans"
                        fontSize="14px"
                        fontWeight="700"
                        color="rgba(53,56,59,1)"
                        lineHeight="22.399999618530273px"
                        textAlign="left"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        letterSpacing="0.55px"
                        width="88px"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children="3520-3560"
                        {...getOverrideProps(overrides, "text529130802")}
                      ></Text>
                    </Flex>
                    <Flex
                      gap="0"
                      direction="column"
                      width="unset"
                      height="unset"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      {...getOverrideProps(overrides, "Frame 880529130803")}
                    >
                      <SetteiItem
                        display="flex"
                        gap="0"
                        direction="column"
                        width="unset"
                        height="unset"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        {...getOverrideProps(overrides, "Settei/Item529130804")}
                      ></SetteiItem>
                      <SetteiItem
                        display="flex"
                        gap="0"
                        direction="column"
                        width="unset"
                        height="unset"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        {...getOverrideProps(overrides, "Settei/Item529130805")}
                      ></SetteiItem>
                      <SetteiItem
                        display="flex"
                        gap="0"
                        direction="column"
                        width="unset"
                        height="unset"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        {...getOverrideProps(overrides, "Settei/Item529130806")}
                      ></SetteiItem>
                    </Flex>
                  </Flex>
                  <CommonDivider
                    display="flex"
                    gap="0"
                    direction="row"
                    width="unset"
                    height="1px"
                    justifyContent="center"
                    alignItems="center"
                    overflow="hidden"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    {...getOverrideProps(overrides, "Common/Divider529130807")}
                  ></CommonDivider>
                  <Flex
                    gap="0"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    {...getOverrideProps(overrides, "3560-3600")}
                  >
                    <Flex
                      gap="0"
                      direction="row"
                      width="unset"
                      height="unset"
                      justifyContent="center"
                      alignItems="flex-start"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="34px 32px 34px 32px"
                      backgroundColor="rgba(255,255,255,1)"
                      {...getOverrideProps(overrides, "Taisyo529130809")}
                    >
                      <Text
                        fontFamily="Noto Sans"
                        fontSize="14px"
                        fontWeight="700"
                        color="rgba(53,56,59,1)"
                        lineHeight="22.399999618530273px"
                        textAlign="left"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        letterSpacing="0.55px"
                        width="88px"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children="3520-3560"
                        {...getOverrideProps(overrides, "text529130810")}
                      ></Text>
                    </Flex>
                    <Flex
                      gap="0"
                      direction="column"
                      width="unset"
                      height="unset"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      {...getOverrideProps(overrides, "Frame 880529130811")}
                    >
                      <SetteiItem
                        display="flex"
                        gap="0"
                        direction="column"
                        width="unset"
                        height="unset"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        {...getOverrideProps(overrides, "Settei/Item529130812")}
                      ></SetteiItem>
                      <SetteiItem
                        display="flex"
                        gap="0"
                        direction="column"
                        width="unset"
                        height="unset"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        {...getOverrideProps(overrides, "Settei/Item529130813")}
                      ></SetteiItem>
                      <SetteiItem
                        display="flex"
                        gap="0"
                        direction="column"
                        width="unset"
                        height="unset"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        {...getOverrideProps(overrides, "Settei/Item529130814")}
                      ></SetteiItem>
                    </Flex>
                  </Flex>
                  <CommonDivider
                    display="flex"
                    gap="0"
                    direction="row"
                    width="unset"
                    height="1px"
                    justifyContent="center"
                    alignItems="center"
                    overflow="hidden"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    {...getOverrideProps(overrides, "Common/Divider529130815")}
                  ></CommonDivider>
                  <Flex
                    gap="0"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    {...getOverrideProps(overrides, "3600-3700")}
                  >
                    <Flex
                      gap="0"
                      direction="row"
                      width="unset"
                      height="unset"
                      justifyContent="center"
                      alignItems="flex-start"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="34px 32px 34px 32px"
                      backgroundColor="rgba(255,255,255,1)"
                      {...getOverrideProps(overrides, "Taisyo529130817")}
                    >
                      <Text
                        fontFamily="Noto Sans"
                        fontSize="14px"
                        fontWeight="700"
                        color="rgba(53,56,59,1)"
                        lineHeight="22.399999618530273px"
                        textAlign="left"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        letterSpacing="0.55px"
                        width="88px"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children="3600-3700"
                        {...getOverrideProps(overrides, "text529130818")}
                      ></Text>
                    </Flex>
                    <Flex
                      gap="0"
                      direction="column"
                      width="unset"
                      height="unset"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      {...getOverrideProps(overrides, "Frame 880529130819")}
                    >
                      <SetteiItem
                        display="flex"
                        gap="0"
                        direction="column"
                        width="unset"
                        height="unset"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        {...getOverrideProps(overrides, "Settei/Item529130820")}
                      ></SetteiItem>
                      <SetteiItem
                        display="flex"
                        gap="0"
                        direction="column"
                        width="unset"
                        height="unset"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        {...getOverrideProps(overrides, "Settei/Item529130821")}
                      ></SetteiItem>
                      <SetteiItem
                        display="flex"
                        gap="0"
                        direction="column"
                        width="unset"
                        height="unset"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        {...getOverrideProps(overrides, "Settei/Item529130822")}
                      ></SetteiItem>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            gap="10px"
            direction="row"
            width="unset"
            height="604px"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            borderRadius="4px"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(245,245,245,1)"
            {...getOverrideProps(overrides, "ScrollBar")}
          >
            <View
              width="8px"
              height="80px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="4px"
              padding="0px 0px 0px 0px"
              backgroundColor="rgba(217,217,217,1)"
              {...getOverrideProps(overrides, "Rectangle 80")}
            ></View>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
