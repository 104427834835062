
import { SetteiInputBox } from '../ui-components';

export default function SetteiInputBoxWrapper(props) {

    return (
        <SetteiInputBox
            overrides={{
                // "SetteiInputBox": {
                //     attention:props.attention,
                //     label:props.label,
                //     unit:props.unit,
                // },
                TextField: {
                    placeholder: "",
                    label:props.labelValue,
                    // defaultValue:props.inputValue,
                    value:props.inputValue,
                    onChange:props.onChangeHandler,
                    onBlur:props.onBlurHandler,
                },
                Attention: {
                    children: props.attentiontext,
                },
            }}
            attention={props.attention}
            label={props.label}
            unit={props.unit}
        >
        </SetteiInputBox>
    );

}