// auth.js
import { UserManager, WebStorageStateStore } from 'oidc-client';
// import { CookieStorage } from 'cookie-storage';
import { Amplify, Auth } from 'aws-amplify';
// import { CookieStateStore } from './CookieStateStore';

// const cookieStorage = new CookieStorage({
//     // ...config.cookies,
//     expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
//   });

const oidcConfig = {
    authority: 'HTTPS://' + process.env.REACT_APP_OIDC_AUTHORITY_DOMAIN,
    client_id: process.env.REACT_APP_OIDC_CLIENT_ID,
    client_secret: process.env.REACT_APP_OIDC_CLIENT_SECRET,
    // redirect_uri: 'http://localhost:3000/callback',
    redirect_uri: process.env.REACT_APP_OIDC_REDIRECT_URL,
    response_type: 'code',
    scope: "openid profile email",
    // post_logout_redirect_uri: 'http://localhost:3000',
    post_logout_redirect_uri: process.env.REACT_APP_OIDC_POST_REDIRECT_URL,
    // userStore: new WebStorageStateStore({ store: cookieStorage }),
    // stateStore: new WebStorageStateStore({ store: cookieStorage }),
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    stateStore: new WebStorageStateStore({ store: window.localStorage }),
    // userStore: new CookieStateStore({ expirationInMinutes: 60 * 24 * 7 }),
    // stateStore: new CookieStateStore({ expirationInMinutes: 60 * 24 * 7 }),
  };

const userManager = new UserManager(oidcConfig);

// ログインチェック
async function isLoggedIn() {
  try {
    const user = await userManager.getUser();
    return user != null && !user.expired;
  } catch (error) {
    console.error('Error checking logged in status:', error);
    return false;
  }
}

// OIDCログインページへリダイレクト
function redirectToLogin() {
    // console.log('redirectToLogin',process.env);
  userManager.signinRedirect();
}

// ログアウト
async function logout() {
//   await userManager.signoutRedirect();
    const user = await userManager.getUser();
    if (user) {
        await userManager.removeUser();
    }
    await Auth.signOut();
    // Cognitoのログアウトエンドポイントにリダイレクト
    const cognitoLogoutUrl = `https://${process.env.REACT_APP_OIDC_LOGOUT_DOMAIN}/logout?client_id=${oidcConfig.client_id}&logout_uri=${encodeURIComponent(oidcConfig.post_logout_redirect_uri)}`;
    window.location.href = cognitoLogoutUrl;
}

// トークンの期限切れチェック
async function checkTokenExpiration() {
  try {
    const user = await userManager.getUser();
    if (user && user.expired) {
      await refreshToken();
    }
  } catch (error) {
    console.error('Error checking token expiration:', error);
  }
}

// リフレッシュトークン
async function refreshToken() {
  try {
    const user = await userManager.signinSilent();
    if (user) {
      await updateUserAwsCredentials(user);
    }
  } catch (error) {
    console.error('Error refreshing token:', error);
    redirectToLogin();
  }
}

// AWSのCredentialsを更新
async function updateUserAwsCredentials(user) {
//   console.log('updateUserAwsCredentials', user);
  const { id_token } = user;
  const base64Url = id_token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  const jsonParse = JSON.parse(window.atob(base64));
//   console.log("ID Token:", jsonParse);
  const credentials = {
    IdentityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    Logins: {
      ['https://'+ process.env.REACT_APP_OIDC_AUTHORITY_DOMAIN ]: id_token,
    },
  };

  Amplify.configure({
    Auth: {
      identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
      region: process.env.REACT_APP_COGNITO_REGION,
    },
  });

  await Auth.federatedSignIn(process.env.REACT_APP_COGNITO_IDENTITY_POOL_AUTH_DOMAIN, { token: id_token, expires_at: user.expires_at }, credentials);
  await Auth.currentCredentials().then((credentials) => {
        // console.log("currentCredentials", credentials);
    });
//   console.log('updateUserAwsCredentials Credentials', Auth.currentCredentials);
}

export {
  userManager,
  isLoggedIn,
  redirectToLogin,
  logout,
  checkTokenExpiration,
  refreshToken,
  updateUserAwsCredentials,
};
