import { Flex,Text } from "@aws-amplify/ui-react";
import {
    KanshiAntennaFrame,
    KanshiAntennaTotal
} from '../ui-components'

export default function KanshiKyotenAntennaStateWrapper(props) {
    return (
        <>
        <Flex
            gap="56px"
            direction="column"
            width="unset"
            height="648px"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 40px 0px 40px"
            backgroundColor="rgba(245,245,245,1)"
            // {...getOverrideProps(overrides, "Frame 894")}
        >
            <KanshiAntennaFrame
            width="992px"
            height="255px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            overrides={{
                "Kanshi/Antenna/indicator505131268": {
                    type:"Default"
                },
            }}
            // {...getOverrideProps(overrides, "Kanshi/Antenna/Frame")}
            ></KanshiAntennaFrame>
            <Flex
            gap="48px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            // {...getOverrideProps(overrides, "Total505135309")}
            >
                <Flex
                    gap="16px"
                    direction="column"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    // {...getOverrideProps(overrides, "Total505135310")}
                >
                    <Flex
                    gap="16px"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-end"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    // {...getOverrideProps(overrides, "Frame 855")}
                    >
                        <Text
                            fontFamily="Noto Sans"
                            fontSize="16px"
                            fontWeight="400"
                            color="rgba(53,56,59,1)"
                            lineHeight="16px"
                            textAlign="left"
                            display="block"
                            direction="column"
                            justifyContent="unset"
                            letterSpacing="0.65px"
                            width="40px"
                            height="unset"
                            gap="unset"
                            alignItems="unset"
                            shrink="0"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            whiteSpace="pre-wrap"
                            children="合計"
                            // {...getOverrideProps(overrides, "text505135312")}
                        ></Text>
                        <Text
                            fontFamily="Noto Sans"
                            fontSize="14px"
                            fontWeight="400"
                            color="rgba(100,100,100,1)"
                            lineHeight="14px"
                            textAlign="left"
                            display="block"
                            direction="column"
                            justifyContent="unset"
                            letterSpacing="0.55px"
                            width="unset"
                            height="unset"
                            gap="unset"
                            alignItems="unset"
                            shrink="0"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            whiteSpace="pre-wrap"
                            children="対象周波数：3400~4100"
                            // {...getOverrideProps(overrides, "text505135313")}
                        ></Text>
                    </Flex>
                    <KanshiAntennaTotal
                    display="flex"
                    gap="16px"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="center"
                    shrink="0"
                    position="relative"
                    padding="20px 16px 20px 16px"
                    backgroundColor="rgba(255,255,255,1)"
                    // {...getOverrideProps(overrides, "Kanshi/Antenna/Total")}
                    ></KanshiAntennaTotal>
                </Flex>
            </Flex>
        </Flex>
        </>
    )
}