/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Text, View } from "@aws-amplify/ui-react";
import KanshiAntennaindicator from "./KanshiAntennaindicator";
export default function KanshiAntennaTotal(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="16px"
      direction="row"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="20px 16px 20px 16px"
      backgroundColor="rgba(255,255,255,1)"
      {...rest}
      {...getOverrideProps(overrides, "KanshiAntennaTotal")}
    >
      <Text
        fontFamily="Noto Sans"
        fontSize="14px"
        fontWeight="400"
        color="rgba(100,100,100,1)"
        lineHeight="22.399999618530273px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        letterSpacing="0.55px"
        width="108px"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="C：合計値判定"
        {...getOverrideProps(overrides, "text")}
      ></Text>
      <View
        width="80px"
        height="16px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 742")}
      >
        <KanshiAntennaindicator
          width="80px"
          height="16px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0px"
          left="0px"
          padding="0px 0px 0px 0px"
          type="Default"
          {...getOverrideProps(overrides, "Kanshi/Antenna/indicator")}
        ></KanshiAntennaindicator>
      </View>
    </Flex>
  );
}
