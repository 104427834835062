import { useEffect, useState } from "react";
import { Flex, View, Text } from "@aws-amplify/ui-react";

import {
    CommonTitle,
    CommonBtnRound,
    CommonBtnText,
    CommonBtnBlue,
    CommonDivider,
    Spacer,
} from './ui-components'

// wrapper作成予定
import {
    SetteiInputBox,
    SetteiItem,
} from './ui-components'


import {
  HeaderWrapper,
  KanshiSetteiLabelWrapper,
  KanshiSetteiKyotenWrapper
} from './componentsWrapper'



const KanshiSettings = () => {
    const [displayModeKanshiSetting, setDisplayModeKanshiSetting] = useState(true);
    
    const onClickBtnDisplayModeKanshiSettingChange = (bool) => {
        setDisplayModeKanshiSetting(bool);
    }

    return(
        <div style={{ height: '100vh',　backgroundColor: 'rgba(245,245,245,1)' }}>
            <HeaderWrapper
            />
            { !displayModeKanshiSetting ? 
            <KanshiSetteiLabelWrapper
            onClickBackBtn={() => onClickBtnDisplayModeKanshiSettingChange(true)}
            >
            </KanshiSetteiLabelWrapper>
            :
            <KanshiSetteiKyotenWrapper
            onClickLabelBtn={() => onClickBtnDisplayModeKanshiSettingChange(false)}
            >
            </KanshiSetteiKyotenWrapper>
        }
        </div>
    );

}

export default KanshiSettings;