import React, { useState, useEffect } from "react";
import { Flex, Text, View } from "@aws-amplify/ui-react";

import { 
    SetteiItem,
    // SetteiInputBox,
    Spacer,
    CommonDivider
} from '../ui-components';

import {
    SetteiInputBoxWrapper,
    SetteiItemWrapper,
} from '../componentsWrapper';

import {
    machineKanshiSettingJson,
    SETTING_INPUTBOX_UNIT_NOTHING
} from '../lib/SettingUtil';


export default function KanshiSetteiMachineWrapper(props) {

    const handleChange = (event) => {
        // setDisplayValue(event.target.value);
    };
    
    const handleBlur = (event) => {
        // const input = event.target.value;
        // const byteCount = countBytes(input);
        // if (byteCount <= maxBytes) {
        //     setInputValue(input);
        // } else {
        //     // バイト数が最大を超えた場合、適切なバイト数に切り詰めます
        //     let truncated = "";
        //     let currentBytes = 0;
        //     for (let i = 0; i < input.length; i++) {
        //     const char = input.charCodeAt(i);
        //     const charBytes = char <= 0x7F ? 1 : 2;
        //     if (currentBytes + charBytes <= maxBytes) {
        //         currentBytes += charBytes;
        //         truncated += input[i];
        //     } else {
        //         break;
        //     }
        //     }
        //     setInputValue(truncated);
        //     setDisplayValue(truncated);
        // }
    };

    useEffect(() => {
        // // setInputValue(props.inputValue);
        // // setDisplayValue(props.inputValue);
        // Object.entries(machineKanshiSettingJson[props.location]).map((item, index) => (
        //     // console.log("item",item[1])
        //     // Object.entries(item[1]).map((itemDetail, index) => (
        //     //     // console.log("itemDetail",itemDetail)
        //     //     itemDetail[1].option1?.range !== undefined ? 
        //     //     console.log("itemDetail1 option1 range　min",itemDetail[0] + " :" +itemDetail[1].option1.range)
        //     //     :
        //     //     console.log("itemDetail1 option1 nothing", itemDetail[0])
        //     // ))
        //     Object.entries(item[1]).map((itemDetail, index) => {
        //         if (itemDetail[1].option1 && itemDetail[1].option1.range) {
        //           console.log("itemDetail1 option1 range min ", itemDetail[0] + " : " + itemDetail[1].option1.range.min);
        //         } else {
        //           console.log("itemDetail1 option1 nothing", itemDetail[0]);
        //         }
        //       })
              
        // ))

    }, []);
    

    return (
        <>
        <Flex
          gap="16px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 40px 0px 40px"
          backgroundColor="rgba(255,255,255,1)"
        //   {...getOverrideProps(overrides, "Frame 898")}
        >
          <Flex
            gap="0"
            direction="column"
            width="unset"
            // height="unset"
            height='calc(100vh - 340px)'  
            justifyContent="flex-start"
            alignItems="flex-start"
            overflow="scroll"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            // {...getOverrideProps(overrides, "Settei505156987")}
          >
            <Flex
              gap="48px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="16px 16px 16px 16px"
              backgroundColor="rgba(245,245,245,1)"
              // {...getOverrideProps(overrides, "Title")}
            >
              <Text
                fontFamily="Noto Sans"
                fontSize="16px"
                fontWeight="400"
                color="rgba(53,56,59,1)"
                lineHeight="16px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.65px"
                width="152px"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="対象機器"
                // {...getOverrideProps(overrides, "text505156989")}
              ></Text>
              <Text
                fontFamily="Noto Sans"
                fontSize="16px"
                fontWeight="400"
                color="rgba(53,56,59,1)"
                lineHeight="16px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.65px"
                width="160px"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="監視項目"
                // {...getOverrideProps(overrides, "text505156990")}
              ></Text>
              <Text
                fontFamily="Noto Sans"
                fontSize="16px"
                fontWeight="400"
                color="rgba(53,56,59,1)"
                lineHeight="16px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.65px"
                width="120px"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="有効／無効"
                // {...getOverrideProps(overrides, "text505156991")}
              ></Text>
              <Text
                fontFamily="Noto Sans"
                fontSize="16px"
                fontWeight="400"
                color="rgba(53,56,59,1)"
                lineHeight="16px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.65px"
                width="120px"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="状態種別"
                // {...getOverrideProps(overrides, "text505156992")}
              ></Text>
              <Text
                fontFamily="Noto Sans"
                fontSize="16px"
                fontWeight="400"
                color="rgba(53,56,59,1)"
                lineHeight="16px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.65px"
                width="120px"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="監視条件"
                // {...getOverrideProps(overrides, "text505156993")}
              ></Text>
            </Flex>
            <Flex
              gap="0"
              direction="column"
              width="unset"
            //   height="576px"
              justifyContent="flex-start"
              alignItems="flex-start"
              overflow="hidden"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              // {...getOverrideProps(overrides, "Frame 887")}
            >
            {
              Object.entries(machineKanshiSettingJson[props.location]).map((item, index) => (
              <>
              <Flex
                gap="0"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                // {...getOverrideProps(overrides, "01")}
              >
                <Flex
                  gap="10px"
                  direction="row"
                  width="192px"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="center"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 16px 0px 16px"
                  backgroundColor="rgba(255,255,255,1)"
                  // {...getOverrideProps(overrides, "Taisyo505156996")}
                >
                  <Text
                    fontFamily="Noto Sans"
                    fontSize="14px"
                    fontWeight="700"
                    color="rgba(53,56,59,1)"
                    lineHeight="25.19999885559082px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    letterSpacing="0.55px"
                    width="160px"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children={item[0]}
                    // {...getOverrideProps(overrides, "text505156997")}
                  ></Text>
                </Flex>
                <Flex
                            gap="0"
                            direction="column"
                            width="unset"
                            height="unset"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            shrink="0"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            // {...getOverrideProps(overrides, "Frame 880505156951")}
                        >
                {Object.entries(item[1]).map((itemDetail, index) => (
                    <>
                    <SetteiItemWrapper
                    onChange={handleChange}
                    key={index}
                    setteiname={itemDetail[1].labeltext}
                    isvalidoptions={itemDetail[1].isvalidoptions}
                    isvalidvalue={itemDetail[1].isvalidvalue}
                    statustypeoptions={itemDetail[1].statustypeoptions}
                    statustypevalue={itemDetail[1].statustypevalue}
                    display1={itemDetail[1].option1 ? "flex" : "none"}
                    // texttop1={"21px"}
                    label1={itemDetail[1].option1?.labeltext ? "true" : "false"}
                    labelValue1={itemDetail[1].option1?.labeltext ? itemDetail[1].option1.labeltext : ""}
                    attention1={itemDetail[1].option1?.range ? "true" : "false"}
                    attentiontext1={itemDetail[1].option1?.range ? "範囲：" + itemDetail[1].option1.range.min + "-" + itemDetail[1].option1.range.max : ""}
                    unit1={itemDetail[1].option1?.unit ? itemDetail[1].option1.unit : SETTING_INPUTBOX_UNIT_NOTHING}
                    inputValue1={itemDetail[1].option1?.inputvalue !== undefined ? itemDetail[1].option1.inputvalue : ""}
                    display2={itemDetail[1].option2 ? "flex" : "none"}
                    // texttop2={"21px"}
                    label2={itemDetail[1].option2?.labeltext ? "true" : "false"}
                    labelValue2={itemDetail[1].option2?.labeltext ? itemDetail[1].option2.labeltext : ""}
                    attention2={itemDetail[1].option2?.range ? "true" : "false"}
                    attentiontext2={itemDetail[1].option2?.range ? "範囲：" + itemDetail[1].option2.range.min + "-" + itemDetail[1].option2.range.max : ""}
                    unit2={itemDetail[1].option2?.unit ? itemDetail[1].option2.unit : SETTING_INPUTBOX_UNIT_NOTHING}
                    inputValue2={itemDetail[1].option2?.inputvalue !== undefined ? itemDetail[1].option2.inputvalue : ""}
                    display3={itemDetail[1].option3 ? "flex" : "none"}
                    // texttop3={"21px"}
                    label3={itemDetail[1].option3?.labeltext ? "true" : "false"}
                    labelValue3={itemDetail[1].option3?.labeltext ? itemDetail[1].option3.labeltext : ""}
                    attention3={itemDetail[1].option3?.range ? "true" : "false"}
                    attentiontext3={itemDetail[1].option3?.range ? "範囲：" + itemDetail[1].option3.range.min + "-" + itemDetail[1].option3.range.max : ""}
                    unit3={itemDetail[1].option3?.unit ? itemDetail[1].option3.unit : SETTING_INPUTBOX_UNIT_NOTHING}
                    inputValue3={itemDetail[1].option3?.inputvalue !== undefined ? itemDetail[1].option3.inputvalue : ""}
                    display4={itemDetail[1].option4 ? "flex" : "none"}
                    // texttop4={"21px"}
                    label4={itemDetail[1].option4?.labeltext ? "true" : "false"}
                    labelValue4={itemDetail[1].option4?.labeltext ? itemDetail[1].option4.labeltext : ""}
                    attention4={itemDetail[1].option4?.range ? "true" : "false"}
                    attentiontext4={itemDetail[1].option4?.range ? "範囲：" + itemDetail[1].option4.range.min + "-" + itemDetail[1].option4.range.max : ""}
                    unit4={itemDetail[1].option4?.unit ? itemDetail[1].option4.unit : SETTING_INPUTBOX_UNIT_NOTHING}
                    inputValue4={itemDetail[1].option4?.inputvalue !== undefined ? itemDetail[1].option4.inputvalue : ""}
                    // dispdivider={Object.entries(item[1]).length - 1 !== index ? true : false}
                    dispdivider={false}
                    // {...getOverrideProps(overrides, "Settei/Item505156952")}
                    ></SetteiItemWrapper>
                    {Object.entries(item[1]).length - 1 !== index ? 
                    <CommonDivider
                        display="flex"
                        gap="0"
                        direction="row"
                        width="unset"
                        height="1px"
                        justifyContent="center"
                        alignItems="center"
                        overflow="hidden"
                        shrink="0"
                        alignSelf="stretch"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        // {...getOverrideProps(overrides, "Common/Divider505156999")}
                    ></CommonDivider>
                    : null}
                    </>
                    
                ))}
                </Flex>
              </Flex>
              <CommonDivider
                display="flex"
                gap="0"
                direction="row"
                width="unset"
                height="1px"
                justifyContent="center"
                alignItems="center"
                overflow="hidden"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                // {...getOverrideProps(overrides, "Common/Divider505156999")}
              ></CommonDivider>
              </>
            ))}
            </Flex>
            </Flex>
        </Flex>
        </>
    );
}