import './App.css';
import { BrowserRouter as Router,Routes, Route } from 'react-router-dom';
import './buffer';
import React, { useContext } from "react";

import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import DataKanriMain from './DataKanriMain';
import KanshiMain from './KanshiMain';
import KanshiSettings from './KanshiSettings';
import AuthProvider from './AuthProvider';
import Callback from './callback';
import { UserProvider, UserContext } from './UserContext';
// import { userManager } from './auth';

import { LayoutSetteiDenpakeisoku } from './ui-components';

Amplify.configure(awsconfig);

Amplify.configure({
  // Auth: {
  //   region: "ap-northeast-1",
  //   userPoolId: "ap-northeast-1_Uh5lQxWiC",
  //   userPoolWebClientId: "7rgsldq5sh8bv7pbpgpoeeo11e",
  //   cookieStorage: {
  //     domain: " ", // Use a single space " " for host-only cookies
  //     expires: null, // null means session cookies
  //     path: "/",
  //     secure: true, // for developing on localhost over http: set to false
  //     sameSite: "lax",
  //   },
  //   oauth: {
  //     domain: "auth.5g-monitoring-dev.trafficsim.co.jp",
  //     scope: ["email", "openid", "profile"],
  //     redirectSignIn: "https://5g-monitoring-dev.trafficsim.co.jp/parseauth",
  //     redirectSignOut: "https://5g-monitoring-dev.trafficsim.co.jp/signout",
  //     responseType: "code",
  //   },
  // },
});


// function App({ signOut, user }) {
const App = () => {
  // const { organizationId } = useContext(UserContext);
  // const adminOrgId = [1,2];

  // useEffect(() => {
  // },[]);

  // const orgId = userManager.getUser.organizationId;
  // async function authSetup(){
  //   Auth.currentCredentials();
  // }


  return (
    // <Router>
    //   <UserProvider>
    //     <AuthProvider>
    //     <div className="App">
    //       <Routes>
    //         <Route path="/callback" Component={Callback} />
    //         { adminOrgId.includes(organizationId) ? (
    //             <>
    //             <Route path="/" Component={KanshiMain} />
    //             <Route path="/datakanri" Component={DataKanriMain} />
    //             <Route path="/kanshi" Component={KanshiMain} />
    //             <Route path="/settings" Component={KanshiSettings} />
    //             </>
    //           ) : (
    //             <Route path="/" Component={DataKanriMain} />
    //           )
    //         }
    //       </Routes>
    //     </div>
    //     </AuthProvider>
    //   </UserProvider>
    // </Router>
    <Router>
      <AuthProvider>
        <UserProvider>
        
          <div className="App">
          <RouteComponents />
          </div>
        </UserProvider>
      </AuthProvider>
    </Router>
  );
}

const RouteComponents = () => {
  const { userInfo } = useContext(UserContext);
  

  return (
    <Routes>
      <Route path="/callback" Component={Callback} />
      <Route path="/" Component={userInfo.isAdminOrg ? KanshiMain : DataKanriMain} />
      {userInfo.isAdminOrg && (
        <>
          <Route path="/datakanri" Component={DataKanriMain} />
          {/* <Route path="/kanshi" Component={KanshiMain} /> */}
          <Route path="/kanshi" Component={LayoutSetteiDenpakeisoku} />
          {userInfo.isAdminUser && (
          <Route path="/settings" Component={KanshiSettings} />
          )}
        </>
      )}
    </Routes> 
  );
};


export default App;
// export default withAuthenticator(App);
