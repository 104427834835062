import { useState } from "react";
import { Flex } from "@aws-amplify/ui-react";

import {
    CommonTitle,
    CommonBtnRound,
    CommonBtnWhite, 
} from './ui-components'

// wrapper作成予定
import {
    KanshiLocation,
    KanshiAlertCard,
    KanshiSearch,
    KanshiLog,
} from './ui-components'


import {
  HeaderWrapper,
  KanshiLocationWrapper,
  KanshiLogAreaWrapper
} from './componentsWrapper'



const KanshiMain = () => {

    const [reportBtnColor, setReportBtnColor] = useState("Gray");

    function onMouseEventBtnRoundEnter(){
        setReportBtnColor("Hover");
    }

    function onMouseEventBtnRoundLeave(){
        setReportBtnColor("Gray");
    }

    return(
        <div >
            <HeaderWrapper
            />
            <Flex
            gap="40px"
            direction="row"
            width="1872px"
            marginLeft={"24px"}
            marginRight={"24px"}
            height="unset"
            justifyContent="space-between"
            alignItems="flex-start"
            position="relative"
            padding="0px 0px 0px 0px"
            // {...rest}
            // // {...getOverrideProps(overrides, "LayoutTougoukanshiAll")}
            >
                <Flex
                    gap="24px"
                    direction="column"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    // // {...getOverrideProps(overrides, "Frame 866")}
                >
                    <CommonTitle
                    width="240px"
                    height="18px"
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    size="large"
                    overrides={{
                        text: {
                          children: "統合監視",
                        }
                    }}
                    // // {...getOverrideProps(overrides, "Common/Title500220679")}
                    ></CommonTitle>
                    <Flex
                    gap="0"
                    direction="column"
                    width="unset"
                    height="798px"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    // // {...getOverrideProps(overrides, "Kyoten")}
                    >
                        <KanshiLocationWrapper
                            display="flex"
                            gap="8px"
                            direction="row"
                            width="unset"
                            height="unset"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            shrink="0"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            // situation="全拠点"
                            // // {...getOverrideProps(overrides, "Kanshi/Location")}
                        ></KanshiLocationWrapper>
                    </Flex>
                </Flex>
                <Flex
                    gap="12px"
                    direction="column"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    // {...getOverrideProps(overrides, "Frame 865")}
                >
                    <Flex
                    gap="371px"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    // {...getOverrideProps(overrides, "Frame 864")}
                    >
                    <CommonTitle
                        width="240px"
                        height="18px"
                        display="block"
                        gap="unset"
                        alignItems="unset"
                        justifyContent="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        size="large"
                        overrides={{
                            text: {
                              children: "監視ログ",
                            }
                        }}
                        // {...getOverrideProps(overrides, "Common/Title500220741")}
                    ></CommonTitle>
                    <CommonBtnRound
                        display="flex"
                        gap="10px"
                        direction="row"
                        width="unset"
                        height="unset"
                        justifyContent="center"
                        alignItems="center"
                        shrink="0"
                        position="relative"
                        borderRadius="15px"
                        padding="8px 16px 8px 16px"
                        backgroundColor="rgba(245,245,245,1)"
                        color={reportBtnColor}
                        onMouseEnter={onMouseEventBtnRoundEnter}
                        onMouseLeave={onMouseEventBtnRoundLeave}
                        overrides={{
                            text: {
                              children: "定期レポート出力",
                            }
                        }}
                        // {...getOverrideProps(overrides, "Common/Btn/Round")}
                    ></CommonBtnRound>
                    </Flex>
                    <KanshiLogAreaWrapper>

                    </KanshiLogAreaWrapper>
                </Flex>
            </Flex>
        </div>
        );

}

export default KanshiMain;