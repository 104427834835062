import { useState } from 'react';

import {
    KanshiSearch,
} from '../ui-components'


export default function KanshiSearchWrapper(props) {
    const [selectTab, setSelectTab] = useState("全て");

    function onClickCommonBtnText(tabName){
        setSelectTab(tabName);
    }

    return (
        <KanshiSearch 
            alignSelf="stretch"
            type={selectTab !== "絞込み" ? "Default": "Select"}
            overrides={{
                "Kanshi/Btn/Search": { 
                    situation: selectTab,
                    overrides: {
                        "Common/Btn/Text39255568": {
                            onClick: () => onClickCommonBtnText("全て"),
                            overrides:{
                                text: {
                                    children: "すべて",
                                }
                            }
                        },
                        "Common/Btn/Text39256946": {
                            onClick: () => onClickCommonBtnText("発生中"),
                            overrides:{
                                text: {
                                    children: "発生中",
                                }
                            }
                        },
                        "Common/Btn/Text39257144": {
                            onClick: () => onClickCommonBtnText("未確認"),
                            overrides:{
                                text: {
                                    children: "未確認",
                                }
                            }
                        },
                        "Common/Btn/Text443017947": {
                            onClick: () => onClickCommonBtnText("絞込み"),
                            overrides:{
                                text: {
                                    children: "絞込み",
                                }
                            }
                        }
                    }
                },
            }}
            
        />
    );
}