import { useEffect, useState, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../UserContext';

import {
    Header
} from '../ui-components'

import { logout } from '../auth';
  
  
export default function HeaderWrapper(props) {
    const { userInfo, logoutUserContext } = useContext(UserContext);
    const [dateText, setDateText] = useState("");
    const [accountDisplay, setAccountDisplay] = useState("none");

    const navigate = useNavigate();

    useEffect(() => {
        const dateTextTimer = setInterval(() => {
          setNowDateText();
        }, 1000);        
    
        return () => clearInterval(dateTextTimer);
      },[]);

    function setNowDateText() {
        var week = ["日", "月", "火", "水", "木", "金", "土"];
        var date = new Date();         // 現在日時を生成
        var year = date.getFullYear(); // 西暦を取得
        var month = ('0' + (date.getMonth() + 1)).slice(-2);  // 月を取得（返り値は実際の月-1なので、+1する）
        var day = ('0' + date.getDate()).slice(-2); // 日を取得
        var hour = ('0' + date.getHours()).slice(-2);
        var minite = ('0' + date.getMinutes()).slice(-2);
        var second = ('0' + date.getSeconds()).slice(-2);
        var weekday = date.getDay();   // 曜日を取得（数値）
    
        var nowDateText = year + "/" + month + "/" + day + " (" + week[weekday] + ")  " + hour + ":" + minite + ":" + second;
        
        setDateText(nowDateText);
    }

    function onClickAccount() {
        if (accountDisplay === "none") {
            setAccountDisplay("flex");
        } else {
            setAccountDisplay("none");
        }
    }

    function onClickLogout() {
        logoutUserContext();
        logout();
    }

    function onClickTop() {
        navigate('/');
    }

    function onclickDataKanri() {
        navigate('/datakanri');
    }

    function onClickSetting() {
        navigate('/settings');
    }

    return (
        <Header 
        marginBottom={"32px"}
        width={"100%"}
        minWidth={"1355px"}
        // onClick={props.onClick}
        // onClick={()=>clickTest("test")}
        overrides={{
            //   Myicons36793624: {},
            text: {
                onClick: () => onClickTop(),
                // children:"test"
            },
            time: {
                children:dateText
            },
            "logo": {
                src:"/images/logo.png"
            },
            "Common/Header/Icon482214385": {
                display: userInfo.isAdminOrg ? "block" : "none",
                situation:"Off",
                overrides: {
                }
            },
            "Common/Header/Icon482214386": {
                display: userInfo.isAdminOrg ? "block" : "none",
                situation:"Off"
            },
            "Rectangle482214387": {
                display: userInfo.isAdminOrg ? "block" : "none",
            },
            "Common/Badge/Header482214388": {
                display: userInfo.isAdminOrg ? "block" : "none",
                onClick: () => onclickDataKanri(),
                overrides: {
                }
            },
            "Common/Badge/Header482214389": {
                display: userInfo.isAdminOrg && userInfo.isAdminUser ? "block" : "none",
                onClick: () => onClickSetting(),
                overrides: {
                }
            },
            "Common/Badge/Header482214391": {
                display: userInfo.isAdminOrg ? "block" : "none",
                onClick: () => onClickTop(),
                overrides: {
                }
            },
            "Rectangle482214390" : {
                display: userInfo.isAdminOrg ? "block" : "none",
            },
            "Common/Badge/Header/Account": {
                overrides: {
                    Myicons: {onClick: () => onClickAccount()},
                    Menu: {
                        display: accountDisplay,
                        style: {
                            zIndex: 10, // 任意のz-indexの値を指定します
                            // 他のスタイルプロパティも追加できます
                        },
                        left : "",
                        right : "0px",
                        alignItems: "flex-start",
                    },
                    text478914266: { 
                        children: userInfo.name,
                        minWidth: "200px",
                        width: "fit-content",
                        whiteSpace: "nowrap",
                    },
                    text478914268: { 
                        children: userInfo.email,
                        minWidth: "200px",
                        width: "fit-content",
                        whiteSpace: "nowrap", 
                    },
                    Logout: { onClick: () => onClickLogout() },
                }
            },
            Header: {
                gap: "0px"
            },
        }}
        ></Header>
    );
}