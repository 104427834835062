import {
    DatakanriListFile
  } from '../ui-components'
  

export default function DatakanriListTitleWrapper(props) {
return (
    <DatakanriListFile
        width={"100%"}
        minWidth={"1355px"}
        overrides={{ 
            // CheckboxField: {},
            // Spacer480814277: {},
            // Myicons: {},
            // text480814280: {
            //     // fontWeight: "500",
            //     children: props.item.name
            // },
            // FileName: {},
            // Spacer480814281: {},
            // text480814283: {
            //     // fontWeight: "500",
            //     children: "\u30FC" },
            // Date: {},
            // Spacer480814284: {},
            // text480814286: {
            //     fontWeight: "500",
            //     top: "1.92%",
            //     bottom: "-1.92%",
            //     children: "\u30FC",
            // },
            // FileType: {},
            // Spacer480814287: {},
            // text480814289: {
            //     fontWeight: "500",
            //     children: "\u30FC"
            // },
            // Spacer480814290: {},
            // FileSize: {},
            // Spacer480814291: {},
            // "Common/Badge/Icon480814293": {},
            // "Rectangle 25": {},
            // "Common/Badge/Icon480814295": {},
            // icons: {},
            // Contents: { backgroundColor: "rgba(245,245,245,1)" },
            // "Common/Divider": {},
            // DatakanriListFile: {},
        }}
        type="Title"
        check="off"
        // onClick={props.onClick}
    ></DatakanriListFile>
);
}