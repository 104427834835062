import { Flex, Text } from "@aws-amplify/ui-react";

import {
    CommonBadgeHeader,
    CommonTitle,
    CommonDivider,
    Spacer,
} from "../ui-components";

import {
    CommonBtnBlueWrapper,
    SetteiInputBoxWrapper,
} from '../componentsWrapper'
// import { useEffect } from "react";

export default function KanshiSetteiLabelWrapper(props) {

    const onClickBtnSettingSave = () =>{
        // console.log("onClickBtnBlueWrapper")
    }

    const handleChange = (event) => {
        // setDisplayValue(event.target.value);
    };
    
    const handleBlur = (event) => {
        // const input = event.target.value;
        // const byteCount = countBytes(input);
        // if (byteCount <= maxBytes) {
        //     setInputValue(input);
        // } else {
        //     // バイト数が最大を超えた場合、適切なバイト数に切り詰めます
        //     let truncated = "";
        //     let currentBytes = 0;
        //     for (let i = 0; i < input.length; i++) {
        //     const char = input.charCodeAt(i);
        //     const charBytes = char <= 0x7F ? 1 : 2;
        //     if (currentBytes + charBytes <= maxBytes) {
        //         currentBytes += charBytes;
        //         truncated += input[i];
        //     } else {
        //         break;
        //     }
        //     }
        //     setInputValue(truncated);
        //     setDisplayValue(truncated);
        // }
    };

    const labelSettingJson = {
        "kyotenLabel": {
            "YSCC": "YSCC",
            "SPE": "SPE",
            "SPW": "SPW",
            "小夜戸": "小夜戸",
        },
        "bandLabel": {
            "3400-3440": "S-1",
            "3440-3480": "D-1",
            "3480-3520": "D-2",
            "3520-3560": "K-1",
            "3560-3600": "S-2",
            "3600-3700": "D-3",
            "3700-3800": "K-2",
            "3800-3900": "R-1",
            "3900-4000": "S-3",
            "4000-4100": "K-3",
        }
    }

    // useEffect(() => {
    //     console.log("kyotenLabel.length",labelSettingJson.kyotenLabel.length )
    //     console.log("bandLabel.length",Object.entries(labelSettingJson.bandLabel).length )

       
    // },[]);
    
    return (
      <Flex
        gap="48px"
        direction="column"
        marginLeft={"24px"}
        marginRight={"24px"}
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        position="relative"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(245,245,245,1)"
        // {...getOverrideProps(overrides, "LayoutSetteiHyojiLabel")}
      >
        <Flex
          gap="1368px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          // {...getOverrideProps(overrides, "Frame 903")}
        >
          <Flex
            gap="16px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            // {...getOverrideProps(overrides, "Frame 897")}
          >
            <CommonBadgeHeader
              onClick={() => props.onClickBackBtn()}
              width="40px"
              height="40px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="20px"
              padding="0px 0px 0px 0px"
              situation="default"
              type="Back"
              // {...getOverrideProps(overrides, "Common/Badge/Header")}
            ></CommonBadgeHeader>
            <CommonTitle
              width="240px"
              height="18px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              size="large"
              overrides={{
                text: {
                  children: "表示ラベル設定",
                }
            }}
              // {...getOverrideProps(overrides, "Common/Title")}
            ></CommonTitle>
          </Flex>
          <CommonBtnBlueWrapper
            onClick={() => onClickBtnSettingSave()}
            children="設定を変更"
            // {...getOverrideProps(overrides, "Common/Btn/Blue")}
          ></CommonBtnBlueWrapper>
        </Flex>
        <Flex
          gap="40px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          // {...getOverrideProps(overrides, "Frame 902")}
        >
          <Flex
            gap="16px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            // {...getOverrideProps(overrides, "Frame 898")}
          >
            <Text
              fontFamily="Noto Sans"
              fontSize="16px"
              fontWeight="400"
              color="rgba(53,56,59,1)"
              lineHeight="16px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.65px"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="拠点ラベル表示名"
              // {...getOverrideProps(overrides, "text485918374")}
            ></Text>
            <CommonDivider
              display="flex"
              gap="0"
              direction="row"
              width="unset"
              height="1px"
              justifyContent="center"
              alignItems="center"
              overflow="hidden"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              // {...getOverrideProps(overrides, "Common/Divider485918375")}
            ></CommonDivider>
            <Flex
              gap="0"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              // {...getOverrideProps(overrides, "Frame 800485918376")}
            >
                {
                    Object.entries(labelSettingJson.kyotenLabel).map(([inputLabel, inputValue], index) => (
                        <>
                        <SetteiInputBoxWrapper
                            key={"kyotenInput" + index}
                            height="90px"
                            attention="false"
                            label="true"
                            unit="Off"
                            labelValue={inputLabel}
                            inputValue={inputValue}
                            onChangeHandler={handleChange}
                            onBlurHandler={handleBlur}
                        />
                        {Object.entries(labelSettingJson.kyotenLabel).length - 1 !== index ?
                        <Spacer
                            key={"kyotenSpacer"+index}
                            display="flex"
                            gap="10px"
                            direction="column"
                            width="40px"
                            height="unset"
                            justifyContent="center"
                            alignItems="center"
                            overflow="hidden"
                            shrink="0"
                            alignSelf="stretch"
                            position="relative"
                            padding="10px 10px 10px 10px"
                        ></Spacer>
                        : null
                        }
                        </>
                    ))
                }
            </Flex>
          </Flex>
          <Flex
            gap="16px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            // {...getOverrideProps(overrides, "Frame 900")}
          >
            <Text
              fontFamily="Noto Sans"
              fontSize="16px"
              fontWeight="400"
              color="rgba(53,56,59,1)"
              lineHeight="16px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.65px"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="周波数帯別ラベル表示名設定"
              // {...getOverrideProps(overrides, "text506027348")}
            ></Text>
            <CommonDivider
              display="flex"
              gap="0"
              direction="row"
              width="unset"
              height="1px"
              justifyContent="center"
              alignItems="center"
              overflow="hidden"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              // {...getOverrideProps(overrides, "Common/Divider506027349")}
            ></CommonDivider>
            <Flex
              gap="0"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              // {...getOverrideProps(overrides, "Frame 800507330106")}
            >
                {
                    Object.entries(labelSettingJson.bandLabel).map(([inputLabel, inputValue], index) => (
                        <>
                        <SetteiInputBoxWrapper
                            key={"bandInput" + index}
                            height="88px"
                            attention="false"
                            label="true"
                            unit="Off"
                            labelValue={inputLabel}
                            inputValue={inputValue}
                        />
                        {Object.entries(labelSettingJson.bandLabel).length - 1 !== index ?
                        <Spacer
                            key={"bandSpacer" + index}
                            display="flex"
                            gap="10px"
                            direction="column"
                            width="40px"
                            height="unset"
                            justifyContent="center"
                            alignItems="center"
                            overflow="hidden"
                            shrink="0"
                            alignSelf="stretch"
                            position="relative"
                            padding="10px 10px 10px 10px"
                        ></Spacer>
                        : null
                        }
                        </>
                    ))
                }
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    );
  }