import { useState } from "react";
import { Flex } from "@aws-amplify/ui-react";
import {
    KanshiBtnMenu,
} from '../ui-components'
import {
    KanshiKyotenAntennaStateWrapper,
    KanshiKyotenMachineStateWrapper,
} from '../componentsWrapper'
  
export default function KanshiKyotenStateWrapper(props) {
    // const [selectType, setSelectType] = useState("antenna");
    const [antennaMenuSituation, setAntennaMenuSituation] = useState("Select");
    const [machineMenuSituation, setMachineMenuSituation] = useState("Default");
    const [antennaMenuType] = useState("None");
    const [machineMenuType] = useState("None");

    function onClickBtnMenu(selectType) {
        // console.log(selectType);
        if (selectType === "antenna") {
            setAntennaMenuSituation("Select");
            setMachineMenuSituation("Default");
        } else {
            setAntennaMenuSituation("Default");
            setMachineMenuSituation("Select");
        }
    }

    return (
        <>
        <Flex
        gap="24px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="32px 40px 32px 40px"
        backgroundColor="rgba(245,245,245,1)"
        // {...getOverrideProps(overrides, "Menu")}
        >
            <KanshiBtnMenu
            display="flex"
            gap="0"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            situation={antennaMenuSituation}
            type={antennaMenuType}
            overrides={{
                text: { children: "干渉波監視" },
            }}
            onClick={() => onClickBtnMenu("antenna")}
            // {...getOverrideProps(overrides, "Kanshi/Btn/Menu505137042")}
            ></KanshiBtnMenu>
            <KanshiBtnMenu
            display="flex"
            gap="0"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            situation={machineMenuSituation}
            type={machineMenuType}
            overrides={{
                text: { children: "機器監視" },
            }}
            onClick={() => onClickBtnMenu("machine")}
            // {...getOverrideProps(overrides, "Kanshi/Btn/Menu505137043")}
            ></KanshiBtnMenu>
        </Flex>
        {antennaMenuSituation === "Select" ? 
        <KanshiKyotenAntennaStateWrapper>

        </KanshiKyotenAntennaStateWrapper>
        :
        <KanshiKyotenMachineStateWrapper>

        </KanshiKyotenMachineStateWrapper>            
        }
        </>
    )
}