import { useEffect, useState, useContext } from "react";
import { Flex } from "@aws-amplify/ui-react";
import { UserContext } from "./UserContext";

import {
    CommonTitle,
    CommonBtnBlue,
    Myicons,
    DatakanriBtn
} from './ui-components'

import {
  HeaderWrapper,
  DatakanriListTitleWrapper,
  DatakanriListFileWrapper,
  DatakanriListFolderWrapper,
  DatakanriBreadcrumbsWrapper,
  DatakanriSearchWrapper
} from './componentsWrapper'

import {
  downloadFile,
  getFileList,
} from './lib/StorageUtil'


const DataKanriMain = () => {
    const { userInfo } = useContext(UserContext);

    const [s3Data, setS3Data] = useState([]);
    const [breadcrumbs, setBreadcrumbs] = useState([]);

    const topDirectory = userInfo.isAdminOrg ? "" : "公開データ/"

    useEffect(() => {
        // authSetup();
        setTimeout(() => {
            getFileList(topDirectory).then((fileList) => {
                // console.log("s3Data: " + fileList);
                setS3Data(fileList);
            })
        }, 1000);
        // getFileList("").then((fileList) => {
        //     console.log("s3Data: " + fileList);
        //     setS3Data(fileList);
        // })
    },[]);

    function convertBreadcrumbs(key) {
        var pathArray = key.split("/");
        var breadcrumbsList = []
        var searchText = ""
        for (var i = 0; i < pathArray.length; i++) {
          if(pathArray[i] !== "") {
            searchText += pathArray[i] + "/"
            if (pathArray[i] + "/" !== topDirectory) {
                var breadcrumb = {
                name:pathArray[i],
                searchKey: searchText
                }
                breadcrumbsList.push(breadcrumb)
            }
          }
        }
        
        return breadcrumbsList
    }

    function onClickData(item) {
        if (!item.isFile) {
            getFileList(item.searchKey).then((fileList) => {
                // console.log("s3Data: " + fileList);
                setS3Data(fileList);
            });
            setBreadcrumbs(convertBreadcrumbs(item.searchKey));
        }
    }

    function onClickBreadcrumbs(searchKey) {
        getFileList(searchKey).then((fileList) => {
            // console.log("s3Data: " + fileList);
            setS3Data(fileList);
        });
        setBreadcrumbs(convertBreadcrumbs(searchKey));
    }

    return(
        <div >
            <HeaderWrapper
            />
            <Flex
                gap="32px"
                direction="column"
                // width="1864px"
                marginLeft={"28px"}
                marginRight={"28px"}
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                
                >
                <CommonTitle
                    size="large"
                    overrides={{
                        text: {
                          children: "データ管理",
                        }
                    }}
                    // {...getOverrideProps(overrides, "Common/Title")}
                ></CommonTitle>
                <Flex
                    gap="983px"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="space-between"
                    alignItems="center"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    display="flex"
                    // {...getOverrideProps(overrides, "01")}
                >
                    <DatakanriSearchWrapper />
                    <CommonBtnBlue
                        display={userInfo.isAdminOrg ? "flex" : "none"}
                        overrides={{
                            text: {
                              children: "時系列データ作成",
                            }
                        }}
                        situation="default"
                    ></CommonBtnBlue>
                </Flex>
                <Flex
                    gap="1128px"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="center"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    display="flex"
                    // {...getOverrideProps(overrides, "02")}
                >
                    <Flex
                    gap="24px"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="center"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    display="flex"
                    // {...getOverrideProps(overrides, "Bread493018437")}
                    >
                    {/* <View
                        width="360px"
                        height="40px"
                        display="flex"
                        gap="unset"
                        alignItems="center"
                        justifyContent="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                    > */}
                        <DatakanriBreadcrumbsWrapper
                            folderName="/"
                            onClick={() => onClickBreadcrumbs(topDirectory)}
                            situation="default"
                            />
                        {
                            breadcrumbs.map((item, index) => { 
                                return (
                                    <><Myicons
                                        // marginRight="24px"
                                        type="angle-right"
                                    ></Myicons>
                                    <DatakanriBreadcrumbsWrapper
                                            key={index}
                                            // folderName={item.name + "/"}
                                            folderName={item.name}
                                            onClick={() => onClickBreadcrumbs(item.searchKey)}
                                            situation={(breadcrumbs.length - 1 === index) ? "select" : "default"} />
                                    </> 
                                );
                            })
                        }
                    {/* </View> */}
                    </Flex>
                    <Flex
                        gap="16px"
                        direction="row"
                        width="unset"
                        height="unset"
                        justifyContent="flex-start"
                        alignItems="center"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        display="none"
                    >
                        <DatakanriBtn
                            situation="default"
                            // {...getOverrideProps(overrides, "Datakanri/Btn493018446")}
                        ></DatakanriBtn>
                        <DatakanriBtn
                            situation="default"
                            // {...getOverrideProps(overrides, "Datakanri/Btn493018447")}
                        ></DatakanriBtn>
                    </Flex>
                </Flex>
                <Flex
                    gap="0"
                    direction="column"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    display="flex"
                    // {...getOverrideProps(overrides, "03")}
                >
                    <DatakanriListTitleWrapper />
                    
                    <Flex
                        gap="0"
                        direction="column"
                        width="unset"
                        height='calc(100vh - 340px)'
                        // height="200px"
                        // height={`calc(100% - 260px)`}
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        overflow="scroll"
                        shrink="0"
                        alignSelf="stretch"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        // {...getOverrideProps(overrides, "Denpakansyo")}
                        >
                    {
                        s3Data.map((item, index) => (
                        item.isFile
                            ?
                            <DatakanriListFileWrapper
                            key={index}
                            item={item}
                            // item={item.isFile} 
                            // name={item.name} 
                            // lastModified={item.lastModified}
                            // fileType={item.fileType}
                            // filesize={item.filesize}
                            // onClick={() => onClickData(item)}
                            onDownloadClick={() => downloadFile(item.key, item.name,item.fileType)}
                            />
                            : 
                            <DatakanriListFolderWrapper
                            key={index}
                            item={item}
                            // item={item.isFile} 
                            // name={item.name} 
                            // lastModified={item.lastModified}
                            // fileType={item.fileType}
                            // filesize={item.filesize}
                            onClick={() => onClickData(item)}
                            />
                        ))
                    }
                    </Flex>
                </Flex>
            </Flex>
            </div>
            

            );

}

export default DataKanriMain;