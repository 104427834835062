import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { userManager } from './auth';

function Callback() {
  const navigate = useNavigate();

  useEffect(() => {
    async function handleCallback() {
        await userManager.signinRedirectCallback();
        
        setTimeout(() => {
            navigate('/', { replace: true });
        }, 500);
    }
    handleCallback();
  }, [navigate]);

  return <div></div>;
}

export default Callback;